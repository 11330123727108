<div id="token-info" class="token-area bg-140c2dr ptb-120">
    <div class="container">
        <div class="section-title">
            <span class="top-title">TOKEN INFO</span>
            <h2>The Financial Infrastructure</h2>
        </div>
        <div class="row">
            <div class="col-xl-6 col-lg-12 col-md-12">
                <div class="token-image">
                    <img src="assets-app/images/token-img.png" alt="token-info">
                </div>
            </div>
            <div class="col-xl-6 col-lg-12 col-md-12">
                <div class="single-token-list">
                    <ul class="d-flex justify-content-between">
                        <li>
                            <h4>15% <sub>Team</sub></h4>
                            <p>0% TGE 5% unlocked each month after</p>
                        </li>
                        <li class="text-end">
                            <h4>15,000,000,000</h4>
                            <p>Tokens</p>
                        </li>
                    </ul>
                </div>
                <div class="single-token-list">
                    <ul class="d-flex justify-content-between">
                        <li>
                            <h4>12% <sub>Strategic Partners & Advisors</sub></h4>
                            <p>0% TGE 5% unlocked each month after</p>
                        </li>
                        <li class="text-end">
                            <h4>21,500,000,000</h4>
                            <p>Tokens</p>
                        </li>
                    </ul>
                </div>
                <div class="single-token-list">
                    <ul class="d-flex justify-content-between">
                        <li>
                            <h4>21.5% <sub>Exchange Listing & Liquidify</sub></h4>
                            <p>20% TGE 10% unlocked each month after</p>
                        </li>
                        <li class="text-end">
                            <h4>12,000,000,000</h4>
                            <p>Tokens</p>
                        </li>
                    </ul>
                </div>
                <div class="single-token-list">
                    <ul class="d-flex justify-content-between">
                        <li>
                            <h4>24.5% <sub>Staking, In-game Rewards & Development</sub></h4>
                            <p>20% TGE 10% unlocked each month after</p>
                        </li>
                        <li class="text-end">
                            <h4>24,500,000,000</h4>
                            <p>Tokens</p>
                        </li>
                    </ul>
                </div>
                <div class="single-token-list">
                    <ul class="d-flex justify-content-between">
                        <li>
                            <h4>16% <sub>Company Reserves</sub></h4>
                            <p>10% TGE 5% unlocked each month after</p>
                        </li>
                        <li class="text-end">
                            <h4>500,000,000</h4>
                            <p>Tokens</p>
                        </li>
                    </ul>
                </div>
                <div class="single-token-list">
                    <ul class="d-flex justify-content-between">
                        <li>
                            <h4>11% <sub>Marketing</sub></h4>
                            <p>0% TGE 5% unlocked each month after</p>
                        </li>
                        <li class="text-end">
                            <h4>3,000,000,000</h4>
                            <p>Tokens</p>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <p class="token-content">Mave's Financial Infrastructure Will Allow For Everyone Who Will Be Settling Mars With Us The Ability To Buy And Sell Around All Things Related And Needed For Settlement.</p>
    </div>
    <img src="assets-app/images/features-shape-1.png" class="shape shape-1" alt="shape">
    <img src="assets-app/images/features-shape-2.png" class="shape shape-2" alt="shape">
</div>