import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root',
})
export class ProvablyFairService {
    constructor(private httpClient: HttpClient) {}

    async hashData(data: string): Promise<string> {
        const encoder = new TextEncoder();
        const encodedData = encoder.encode(data);
        const hashBuffer = await crypto.subtle.digest('SHA-256', encodedData);
        const hashArray = Array.from(new Uint8Array(hashBuffer));
        const hexString = hashArray
            .map((byte) => byte.toString(16).padStart(2, '0'))
            .join('');

        return hexString;
    }

    async verifyCrashRNG(
        serverSeed: string,
        clientSeed: string,
        nonce: number
    ) {
        const combinedSeedData = serverSeed + clientSeed + nonce.toString();
        const combinedHash = await this.hashData(combinedSeedData);
        const hashValue = parseInt(combinedHash.substring(0, 8), 16);
        const crashPoint = Math.max(
            1,
            (Math.pow(2, 32) / (hashValue + 1)) * (1 - 0.01)
        );
        const roundedCrashPoint = Math.round(crashPoint * 100) / 100;

        return roundedCrashPoint;
    }
}
