<!-- Default Projects Section -->
<div
    id="projects"
    class="projects-area pt-120 pb-90"
    [ngClass]="{'d-none': router.url === '/index-3' || router.url === '/index-4' || router.url === '/gaming-metaverse' || router.url === '/nft-centric-metaverse'}"
>
    <div class="container-fluid">
        <div class="row">
            <div class="col-xl-5 col-lg-12 col-md-12">
                <div class="projects-content">
                    <span class="top-title">OUR PROJECTS</span>
                    <h2>Create The Exciting Projects With Us</h2>
                    <p>A Gamified Marketplace Dedicated To The Metaverse And Gaming Assets.</p>
                </div>
            </div>
            <div class="col-xl-7 col-lg-12 col-md-12">
                <div class="projects-slides">
                    <owl-carousel-o [options]="projectsSlides">
                        <ng-template carouselSlide>
                            <div class="single-project">
                                <img src="assets-app/images/projects/project-1.jpg" alt="project-image">
                                <h3 class="main">
                                    Wizardia
                                </h3>
                                <h3 class="hover">
                                    <a routerLink="/project-details">
                                        Wizardia
                                    </a>
                                </h3>
                            </div>
                        </ng-template>
                        <ng-template carouselSlide>
                            <div class="single-project">
                                <img src="assets-app/images/projects/project-2.jpg" alt="project-image">
                                <h3 class="main">
                                    Legion Network
                                </h3>
                                <h3 class="hover">
                                    <a routerLink="/project-details">
                                        Legion Network
                                    </a>
                                </h3>
                            </div>
                        </ng-template>
                        <ng-template carouselSlide>
                            <div class="single-project">
                                <img src="assets-app/images/projects/project-3.jpg" alt="project-image">
                                <h3 class="main">
                                    Animal Concerts
                                </h3>
                                <h3 class="hover">
                                    <a routerLink="/project-details">
                                        Animal Concerts
                                    </a>
                                </h3>
                            </div>
                        </ng-template>
                        <ng-template carouselSlide>
                            <div class="single-project">
                                <img src="assets-app/images/projects/project-4.jpg" alt="project-image">
                                <h3 class="main">
                                    Sign In Network
                                </h3>
                                <h3 class="hover">
                                    <a routerLink="/project-details">
                                        Sign In Network
                                    </a>
                                </h3>
                            </div>
                        </ng-template>
                    </owl-carousel-o>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- Projects Section for Gaming Metaverse Demo -->
<div
    class="d-none"
    [ngClass]="{'d-block': router.url === '/index-3' || router.url === '/gaming-metaverse'}"
>

    <!-- Next Projects -->
    <div id="projects" class="next-projects-area pt-120 pb-90">
        <div class="container">
            <div class="section-title text-start">
                <span class="top-title">NEXT PROJECTS</span>
                <h2>Upcoming IGO</h2>
                <a routerLink="/projects" class="default-btn">View All</a>
            </div>
            <div class="row justify-content-center">
                <div class="col-lg-4 col-md-6 col-sm-6">
                    <div class="single-next-project">
                        <img src="assets-app/images/next-project/next-project-1.jpg" alt="project-image">
                        <div class="next-project-content">
                            <h3>
                                <a routerLink="/project-details">
                                    Tech Arrow Fighter
                                </a>
                            </h3>
                            <p>The Metaverse is the next generation of the internet. As one of its earliest.</p>
                            <ul>
                                <li>
                                    Targeted Raise
                                    <span>300,000 <sub>BUSD</sub></span>
                                </li>
                                <li>
                                    Token price
                                    <span>0.30000 <sub>BUSD</sub></span>
                                </li>
                                <li>
                                    Projects Start on
                                    <span>17 <sub>March, 2024</sub></span>
                                </li>
                            </ul>
                        </div>
                        <a routerLink="/project-details" class="read-more-btn">READ MORE</a>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 col-sm-6">
                    <div class="single-next-project">
                        <img src="assets-app/images/next-project/next-project-2.jpg" alt="project-image">
                        <div class="next-project-content">
                            <h3>
                                <a routerLink="/project-details">Do Or Die Game</a>
                            </h3>
                            <p>The Metaverse is the next generation of the internet. As one of its earliest.</p>
                            <ul>
                                <li>
                                    Targeted Raise
                                    <span>300,000 <sub>BUSD</sub></span>
                                </li>
                                <li>
                                    Token price
                                    <span>0.30000 <sub>BUSD</sub></span>
                                </li>
                                <li>
                                    Projects Start on
                                    <span>17 <sub>March, 2024</sub></span>
                                </li>
                            </ul>
                        </div>
                        <a routerLink="/project-details" class="read-more-btn">READ MORE</a>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 col-sm-6">
                    <div class="single-next-project">
                        <img src="assets-app/images/next-project/next-project-3.jpg" alt="project-image">
                        <div class="next-project-content">
                            <h3>
                                <a routerLink="/project-details">Space War 01</a>
                            </h3>
                            <p>The Metaverse is the next generation of the internet. As one of its earliest.</p>
                            <ul>
                                <li>
                                    Targeted Raise
                                    <span>300,000 <sub>BUSD</sub></span>
                                </li>
                                <li>
                                    Token price
                                    <span>0.30000 <sub>BUSD</sub></span>
                                </li>
                                <li>
                                    Projects Start on
                                    <span>17 <sub>March, 2024</sub></span>
                                </li>
                            </ul>
                        </div>
                        <a routerLink="/project-details" class="read-more-btn">READ MORE</a>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 col-sm-6">
                    <div class="single-next-project">
                        <img src="assets-app/images/next-project/next-project-4.jpg" alt="project-image">
                        <div class="next-project-content">
                            <h3>
                                <a routerLink="/project-details">Tech Arrow Fighter</a>
                            </h3>
                            <p>The Metaverse is the next generation of the internet. As one of its earliest.</p>
                            <ul>
                                <li>
                                    Targeted Raise
                                    <span>300,000 <sub>BUSD</sub></span>
                                </li>
                                <li>
                                    Token price
                                    <span>0.30000 <sub>BUSD</sub></span>
                                </li>
                                <li>
                                    Projects Start on
                                    <span>17 <sub>March, 2024</sub></span>
                                </li>
                            </ul>
                        </div>
                        <a routerLink="/project-details" class="read-more-btn">READ MORE</a>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 col-sm-6">
                    <div class="single-next-project">
                        <img src="assets-app/images/next-project/next-project-5.jpg" alt="project-image">
                        <div class="next-project-content">
                            <h3>
                                <a routerLink="/project-details">Do Or Die Game</a>
                            </h3>
                            <p>The Metaverse is the next generation of the internet. As one of its earliest.</p>
                            <ul>
                                <li>
                                    Targeted Raise
                                    <span>300,000 <sub>BUSD</sub></span>
                                </li>
                                <li>
                                    Token price
                                    <span>0.30000 <sub>BUSD</sub></span>
                                </li>
                                <li>
                                    Projects Start on
                                    <span>17 <sub>March, 2024</sub></span>
                                </li>
                            </ul>
                        </div>
                        <a routerLink="/project-details" class="read-more-btn">READ MORE</a>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 col-sm-6">
                    <div class="single-next-project">
                        <img src="assets-app/images/next-project/next-project-6.jpg" alt="project-image">
                        <div class="next-project-content">
                            <h3>
                                <a routerLink="/project-details">Space War 02</a>
                            </h3>
                            <p>The Metaverse is the next generation of the internet. As one of its earliest.</p>
                            <ul>
                                <li>
                                    Targeted Raise
                                    <span>300,000 <sub>BUSD</sub></span>
                                </li>
                                <li>
                                    Token price
                                    <span>0.30000 <sub>BUSD</sub></span>
                                </li>
                                <li>
                                    Projects Start on
                                    <span>17 <sub>March, 2024</sub></span>
                                </li>
                            </ul>
                        </div>
                        <a routerLink="/project-details" class="read-more-btn">READ MORE</a>
                    </div>
                </div>
            </div>
        </div>
        <img src="assets-app/images/features-shape-1.png" class="shape shape-1" alt="shape">
        <img src="assets-app/images/features-shape-2.png" class="shape shape-2" alt="shape">
        <img src="assets-app/images/features-shape-1.png" class="shape shape-3" alt="shape">
    </div>

    <!-- Completed Projects -->
    <div class="completed-projects-area pb-90">
        <div class="container">
            <div class="section-title text-start">
                <span class="top-title">COMPLETED PROJECTS</span>
                <h2>See What We Have Done</h2>
                <a routerLink="/projects" class="default-btn">View All</a>
            </div>
            <div class="completed-projects-slides">
                <owl-carousel-o [options]="completedSlides">
                    <ng-template carouselSlide>
                        <div class="single-completed-project">
                            <img src="assets-app/images/completed-project/completed-project-1.jpg" alt="project-image">
                            <div class="completed-project-content">
                                <h3>
                                    <a routerLink="/project-details">
                                        Ex Sports
                                    </a>
                                </h3>
                                <p>The Metaverse is the next generation of the internet. As one of its earliest.</p>
                                <ul>
                                    <li class="progress-wrap">
                                        <div class="prog d-flex justify-content-between">
                                            <span class="color">Progress</span>
                                            <span>30%</span>
                                        </div>
                                        <div class="progress">
                                            <div class="progress-bar" role="progressbar" style="width: 30%;" aria-valuenow="30" aria-valuemin="0" aria-valuemax="100"></div>
                                        </div>
                                    </li>
                                    <li>
                                        Targeted Raise
                                        <span>300,000 <sub>BUSD</sub></span>
                                    </li>
                                    <li>
                                        Token price
                                        <span>0.30000 <sub>BUSD</sub></span>
                                    </li>
                                </ul>
                            </div>
                            <a routerLink="/project-details" class="read-more-btn">READ MORE</a>
                        </div>
                    </ng-template>
                    <ng-template carouselSlide>
                        <div class="single-completed-project">
                            <img src="assets-app/images/completed-project/completed-project-2.jpg" alt="project-image">
                            <div class="completed-project-content">
                                <h3>
                                    <a routerLink="/project-details">
                                        Time Riders
                                    </a>
                                </h3>
                                <p>The Metaverse is the next generation of the internet. As one of its earliest.</p>
                                <ul>
                                    <li class="progress-wrap">
                                        <div class="prog d-flex justify-content-between">
                                            <span class="color">Progress</span>
                                            <span>80%</span>
                                        </div>
                                        <div class="progress">
                                            <div class="progress-bar" role="progressbar" style="width: 80%;" aria-valuenow="80" aria-valuemin="0" aria-valuemax="100"></div>
                                        </div>
                                    </li>
                                    <li>
                                        Targeted Raise
                                        <span>300,000 <sub>BUSD</sub></span>
                                    </li>
                                    <li>
                                        Token price
                                        <span>0.30000 <sub>BUSD</sub></span>
                                    </li>
                                </ul>
                            </div>
                            <a routerLink="/project-details" class="read-more-btn">READ MORE</a>
                        </div>
                    </ng-template>
                    <ng-template carouselSlide>
                        <div class="single-completed-project">
                            <img src="assets-app/images/completed-project/completed-project-3.jpg" alt="project-image">
                            <div class="completed-project-content">
                                <h3>
                                    <a routerLink="/project-details">
                                        Nano Verse
                                    </a>
                                </h3>
                                <p>The Metaverse is the next generation of the internet. As one of its earliest.</p>
                                <ul>
                                    <li class="progress-wrap">
                                        <div class="prog d-flex justify-content-between">
                                            <span class="color">Progress</span>
                                            <span>100%</span>
                                        </div>
                                        <div class="progress">
                                            <div class="progress-bar" role="progressbar" style="width: 100%;" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100"></div>
                                        </div>
                                    </li>
                                    <li>
                                        Targeted Raise
                                        <span>300,000 <sub>BUSD</sub></span>
                                    </li>
                                    <li>
                                        Token price
                                        <span>0.30000 <sub>BUSD</sub></span>
                                    </li>
                                </ul>
                            </div>
                            <a routerLink="/project-details" class="read-more-btn">READ MORE</a>
                        </div>
                    </ng-template>
                    <ng-template carouselSlide>
                        <div class="single-completed-project">
                            <img src="assets-app/images/completed-project/completed-project-1.jpg" alt="project-image">
                            <div class="completed-project-content">
                                <h3>
                                    <a routerLink="/project-details">
                                        Ex Sports
                                    </a>
                                </h3>
                                <p>The Metaverse is the next generation of the internet. As one of its earliest.</p>
                                <ul>
                                    <li class="progress-wrap">
                                        <div class="prog d-flex justify-content-between">
                                            <span class="color">Progress</span>
                                            <span>30%</span>
                                        </div>
                                        <div class="progress">
                                            <div class="progress-bar" role="progressbar" style="width: 30%;" aria-valuenow="30" aria-valuemin="0" aria-valuemax="100"></div>
                                        </div>
                                    </li>
                                    <li>
                                        Targeted Raise
                                        <span>300,000 <sub>BUSD</sub></span>
                                    </li>
                                    <li>
                                        Token price
                                        <span>0.30000 <sub>BUSD</sub></span>
                                    </li>
                                </ul>
                            </div>
                            <a routerLink="/project-details" class="read-more-btn">READ MORE</a>
                        </div>
                    </ng-template>
                    <ng-template carouselSlide>
                        <div class="single-completed-project">
                            <img src="assets-app/images/completed-project/completed-project-2.jpg" alt="project-image">
                            <div class="completed-project-content">
                                <h3>
                                    <a routerLink="/project-details">
                                        Time Riders
                                    </a>
                                </h3>
                                <p>The Metaverse is the next generation of the internet. As one of its earliest.</p>
                                <ul>
                                    <li class="progress-wrap">
                                        <div class="prog d-flex justify-content-between">
                                            <span class="color">Progress</span>
                                            <span>80%</span>
                                        </div>
                                        <div class="progress">
                                            <div class="progress-bar" role="progressbar" style="width: 80%;" aria-valuenow="80" aria-valuemin="0" aria-valuemax="100"></div>
                                        </div>
                                    </li>
                                    <li>
                                        Targeted Raise
                                        <span>300,000 <sub>BUSD</sub></span>
                                    </li>
                                    <li>
                                        Token price
                                        <span>0.30000 <sub>BUSD</sub></span>
                                    </li>
                                </ul>
                            </div>
                            <a routerLink="/project-details" class="read-more-btn">READ MORE</a>
                        </div>
                    </ng-template>
                </owl-carousel-o>
            </div>
        </div>
    </div>

</div>

<!-- Projects Section for NFT Centric Metaverse Demo -->
<div
    id="projects"
    class="projects-area bg-140c2d ptb-120 d-none"
    [ngClass]="{'d-block': router.url === '/index-4' || router.url === '/nft-centric-metaverse'}"
>
    <div class="container">
        <div class="section-title">
            <span class="top-title">PROJECTS</span>
            <h2>Our Latest Projects To Show</h2>
        </div>
    </div>
    <div class="container-fluid p-0">
        <div class="projects-slider">
            <owl-carousel-o [options]="projectsSlider">
                <ng-template carouselSlide>
                    <div class="single-project-box">
                        <img src="assets-app/images/projects/project-5.jpg" alt="project-image">
                        <div class="project-contents d-flex justify-content-between align-items-center">
                            <a routerLink="/project-details">
                                <h3>AVATAR ARTS</h3>
                            </a>
                            <a routerLink="/project-details" class="read-btn">
                                <i class="ri-arrow-right-s-line"></i>
                            </a>
                        </div>
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="single-project-box">
                        <img src="assets-app/images/projects/project-6.jpg" alt="project-image">
                        <div class="project-contents d-flex justify-content-between align-items-center">
                            <a routerLink="/project-details">
                                <h3>PRODUCT ARTS</h3>
                            </a>
                            <a routerLink="/project-details" class="read-btn">
                                <i class="ri-arrow-right-s-line"></i>
                            </a>
                        </div>
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="single-project-box">
                        <img src="assets-app/images/projects/project-7.jpg" alt="project-image">
                        <div class="project-contents d-flex justify-content-between align-items-center">
                            <a routerLink="/project-details">
                                <h3>INCUBATION ARTS</h3>
                            </a>
                            <a routerLink="/project-details" class="read-btn">
                                <i class="ri-arrow-right-s-line"></i>
                            </a>
                        </div>
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="single-project-box">
                        <img src="assets-app/images/projects/project-8.jpg" alt="project-image">
                        <div class="project-contents d-flex justify-content-between align-items-center">
                            <a routerLink="/project-details">
                                <h3>PRODUCT ARTS</h3>
                            </a>
                            <a routerLink="/project-details" class="read-btn">
                                <i class="ri-arrow-right-s-line"></i>
                            </a>
                        </div>
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="single-project-box">
                        <img src="assets-app/images/projects/project-5.jpg" alt="project-image">
                        <div class="project-contents d-flex justify-content-between align-items-center">
                            <a routerLink="/project-details">
                                <h3>INCUBATION ARTS</h3>
                            </a>
                            <a routerLink="/project-details" class="read-btn">
                                <i class="ri-arrow-right-s-line"></i>
                            </a>
                        </div>
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="single-project-box">
                        <img src="assets-app/images/projects/project-6.jpg" alt="project-image">
                        <div class="project-contents d-flex justify-content-between align-items-center">
                            <a routerLink="/project-details">
                                <h3>NFT ARTS</h3>
                            </a>
                            <a routerLink="/project-details" class="read-btn">
                                <i class="ri-arrow-right-s-line"></i>
                            </a>
                        </div>
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="single-project-box">
                        <img src="assets-app/images/projects/project-7.jpg" alt="project-image">
                        <div class="project-contents d-flex justify-content-between align-items-center">
                            <a routerLink="/project-details">
                                <h3>NFT ARTS</h3>
                            </a>
                            <a routerLink="/project-details" class="read-btn">
                                <i class="ri-arrow-right-s-line"></i>
                            </a>
                        </div>
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="single-project-box">
                        <img src="assets-app/images/projects/project-8.jpg" alt="project-image">
                        <div class="project-contents d-flex justify-content-between align-items-center">
                            <a routerLink="/project-details">
                                <h3>NFT ARTS</h3>
                            </a>
                            <a routerLink="/project-details" class="read-btn">
                                <i class="ri-arrow-right-s-line"></i>
                            </a>
                        </div>
                    </div>
                </ng-template>
            </owl-carousel-o>
        </div>
    </div>
</div>