<!-- Navbar -->
<div
    class="navbar-area"
    [ngClass]="{'sticky': isSticky}"
>

    <!-- Default Navbar -->
    <div
        class="container-fluid"
        [ngClass]="{'d-none': router.url === '/index-2' || router.url === '/index-3'}"
    >
        <nav class="navbar navbar-expand-lg navbar-light bg-light" [class.active]="classApplied">
            <a class="navbar-brand" routerLink="/">
                <img src="assets-app/images/logo.png" alt="logo">
            </a>
            <button class="navbar-toggler" type="button" (click)="toggleClass()">
                <span class="burger-menu">
                    <span class="top-bar"></span>
                    <span class="middle-bar"></span>
                    <span class="bottom-bar"></span>
                </span>
            </button>
            <div class="collapse navbar-collapse">
                <ul class="navbar-nav">
                    <li class="nav-item"><a href="javascript:void(0)" class="dropdown-toggle nav-link">Home</a>
                        <ul class="dropdown-menu">
                            <li class="nav-item">
                                <a routerLink="/" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Metavarse Launchpad</a>
                            </li>
                            <li class="nav-item">
                                <a routerLink="/index-2" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Dex Offering</a>
                            </li>
                            <li class="nav-item">
                                <a routerLink="/index-3" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Gaming Metaverse</a>
                            </li>
                            <li class="nav-item">
                                <a routerLink="/index-4" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">NFT Centric Metaverse</a>
                            </li>
                            <li class="nav-item">
                                <a routerLink="/index-5" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">3D Land Selling Platform</a>
                            </li>
                        </ul>
                    </li>
                    <li class="nav-item">
                        <a routerLink="/about-us" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">About Us</a>
                    </li>
                    <li class="nav-item"><a href="javascript:void(0)" class="dropdown-toggle nav-link">Features</a>
                        <ul class="dropdown-menu">
                            <li class="nav-item">
                                <a routerLink="/features" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Features</a>
                            </li>
                            <li class="nav-item">
                                <a routerLink="/feature-details" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Feature Details</a>
                            </li>
                        </ul>
                    </li>
                    <li class="nav-item"><a href="javascript:void(0)" class="dropdown-toggle nav-link">Projects</a>
                        <ul class="dropdown-menu">
                            <li class="nav-item">
                                <a routerLink="/projects" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Projects</a>
                            </li>
                            <li class="nav-item">
                                <a routerLink="/project-details" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Project Details</a>
                            </li>
                        </ul>
                    </li>
                    <li class="nav-item"><a href="javascript:void(0)" class="dropdown-toggle nav-link">Pages</a>
                        <ul class="dropdown-menu">
                            <li class="nav-item">
                                <a routerLink="/about-us" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">About Us</a>
                            </li>
                            <li class="nav-item">
                                <a routerLink="/team" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Team</a>
                            </li>
                            <li class="nav-item">
                                <a routerLink="/privacy-policy" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Privacy Policy</a>
                            </li>
                            <li class="nav-item">
                                <a routerLink="/terms-conditions" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Terms & Conditions</a>
                            </li>
                            <li class="nav-item">
                                <a routerLink="/not-found" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">404 Error Page</a>
                            </li>
                        </ul>
                    </li>
                    <li class="nav-item"><a href="javascript:void(0)" class="dropdown-toggle nav-link">Blog</a>
                        <ul class="dropdown-menu">
                            <li class="nav-item">
                                <a routerLink="/blog" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Blog Grid</a>
                            </li>
                            <li class="nav-item">
                                <a routerLink="/blog-details" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Blog Details</a>
                            </li>
                        </ul>
                    </li>
                    <li class="nav-item">
                        <a routerLink="/contact-us" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Contact Us</a>
                    </li>
                </ul>
                <div class="others-option d-flex align-items-center">
                    <div class="option-item">
                        <a routerLink="/contact-us" class="default-btn">Apply For IDO</a>
                    </div>
                    <div class="option-item">
                        <span class="burger-menu" (click)="switcherToggleClass()">
                            <i class="ri-menu-4-line"></i>
                        </span>
                    </div>
                </div>
            </div>
        </nav>
    </div>

    <!-- Navbar For Dex Offering -->
    <div
        class="container style-two d-none"
        [ngClass]="{'d-block': router.url === '/index-2'}"
    >
        <nav class="navbar navbar-expand-lg navbar-light bg-light" [class.active]="classApplied">
            <a class="navbar-brand" routerLink="/">
                <img src="assets-app/images/logo.png" alt="logo">
            </a>
            <button class="navbar-toggler" type="button" (click)="toggleClass()">
                <span class="burger-menu">
                    <span class="top-bar"></span>
                    <span class="middle-bar"></span>
                    <span class="bottom-bar"></span>
                </span>
            </button>
            <div class="collapse navbar-collapse">
                <ul class="navbar-nav me-auto">
                    <li class="nav-item"><a href="javascript:void(0)" class="dropdown-toggle nav-link">Home</a>
                        <ul class="dropdown-menu">
                            <li class="nav-item">
                                <a routerLink="/" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Metavarse Launchpad</a>
                            </li>
                            <li class="nav-item">
                                <a routerLink="/index-2" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Dex Offering</a>
                            </li>
                            <li class="nav-item">
                                <a routerLink="/index-3" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Gaming Metaverse</a>
                            </li>
                            <li class="nav-item">
                                <a routerLink="/index-4" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">NFT Centric Metaverse</a>
                            </li>
                            <li class="nav-item">
                                <a routerLink="/index-5" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">3D Land Selling Platform</a>
                            </li>
                        </ul>
                    </li>
                    <li class="nav-item">
                        <a routerLink="/about-us" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">About Us</a>
                    </li>
                    <li class="nav-item"><a href="javascript:void(0)" class="dropdown-toggle nav-link">Features</a>
                        <ul class="dropdown-menu">
                            <li class="nav-item">
                                <a routerLink="/features" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Features</a>
                            </li>
                            <li class="nav-item">
                                <a routerLink="/feature-details" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Feature Details</a>
                            </li>
                        </ul>
                    </li>
                    <li class="nav-item"><a href="javascript:void(0)" class="dropdown-toggle nav-link">Projects</a>
                        <ul class="dropdown-menu">
                            <li class="nav-item">
                                <a routerLink="/projects" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Projects</a>
                            </li>
                            <li class="nav-item">
                                <a routerLink="/project-details" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Project Details</a>
                            </li>
                        </ul>
                    </li>
                </ul>
                <a class="logo-center" routerLink="/">
                    <img src="assets-app/images/logo-icon.png" alt="logo">
                </a>
                <ul class="navbar-nav ms-auto">
                    <li class="nav-item"><a href="javascript:void(0)" class="dropdown-toggle nav-link">Pages</a>
                        <ul class="dropdown-menu">
                            <li class="nav-item">
                                <a routerLink="/about-us" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">About Us</a>
                            </li>
                            <li class="nav-item">
                                <a routerLink="/team" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Team</a>
                            </li>
                            <li class="nav-item">
                                <a routerLink="/privacy-policy" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Privacy Policy</a>
                            </li>
                            <li class="nav-item">
                                <a routerLink="/terms-conditions" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Terms & Conditions</a>
                            </li>
                            <li class="nav-item">
                                <a routerLink="/not-found" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">404 Error Page</a>
                            </li>
                        </ul>
                    </li>
                    <li class="nav-item"><a href="javascript:void(0)" class="dropdown-toggle nav-link">Blog</a>
                        <ul class="dropdown-menu">
                            <li class="nav-item">
                                <a routerLink="/blog" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Blog Grid</a>
                            </li>
                            <li class="nav-item">
                                <a routerLink="/blog-details" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Blog Details</a>
                            </li>
                        </ul>
                    </li>
                    <li class="nav-item">
                        <a routerLink="/contact-us" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Contact Us</a>
                    </li>
                </ul>
                <ul class="navbar-nav for-responsive">
                    <li class="nav-item"><a href="javascript:void(0)" class="dropdown-toggle nav-link">Home</a>
                        <ul class="dropdown-menu">
                            <li class="nav-item">
                                <a routerLink="/" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Metavarse Launchpad</a>
                            </li>
                            <li class="nav-item">
                                <a routerLink="/index-2" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Dex Offering</a>
                            </li>
                            <li class="nav-item">
                                <a routerLink="/index-3" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Gaming Metaverse</a>
                            </li>
                            <li class="nav-item">
                                <a routerLink="/index-4" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">NFT Centric Metaverse</a>
                            </li>
                            <li class="nav-item">
                                <a routerLink="/index-5" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">3D Land Selling Platform</a>
                            </li>
                        </ul>
                    </li>
                    <li class="nav-item">
                        <a routerLink="/about-us" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">About Us</a>
                    </li>
                    <li class="nav-item"><a href="javascript:void(0)" class="dropdown-toggle nav-link">Features</a>
                        <ul class="dropdown-menu">
                            <li class="nav-item">
                                <a routerLink="/features" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Features</a>
                            </li>
                            <li class="nav-item">
                                <a routerLink="/feature-details" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Feature Details</a>
                            </li>
                        </ul>
                    </li>
                    <li class="nav-item"><a href="javascript:void(0)" class="dropdown-toggle nav-link">Projects</a>
                        <ul class="dropdown-menu">
                            <li class="nav-item">
                                <a routerLink="/projects" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Projects</a>
                            </li>
                            <li class="nav-item">
                                <a routerLink="/project-details" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Project Details</a>
                            </li>
                        </ul>
                    </li>
                    <li class="nav-item"><a href="javascript:void(0)" class="dropdown-toggle nav-link">Pages</a>
                        <ul class="dropdown-menu">
                            <li class="nav-item">
                                <a routerLink="/about-us" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">About Us</a>
                            </li>
                            <li class="nav-item">
                                <a routerLink="/team" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Team</a>
                            </li>
                            <li class="nav-item">
                                <a routerLink="/privacy-policy" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Privacy Policy</a>
                            </li>
                            <li class="nav-item">
                                <a routerLink="/terms-conditions" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Terms & Conditions</a>
                            </li>
                            <li class="nav-item">
                                <a routerLink="/not-found" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">404 Error Page</a>
                            </li>
                        </ul>
                    </li>
                    <li class="nav-item"><a href="javascript:void(0)" class="dropdown-toggle nav-link">Blog</a>
                        <ul class="dropdown-menu">
                            <li class="nav-item">
                                <a routerLink="/blog" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Blog Grid</a>
                            </li>
                            <li class="nav-item">
                                <a routerLink="/blog-details" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Blog Details</a>
                            </li>
                        </ul>
                    </li>
                    <li class="nav-item">
                        <a routerLink="/contact-us" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Contact Us</a>
                    </li>
                </ul>
                <div class="others-option d-flex align-items-center">
                    <div class="option-item">
                        <a routerLink="/contact-us" class="default-btn">Apply For IDO</a>
                    </div>
                    <div class="option-item">
                        <span class="burger-menu" (click)="switcherToggleClass()">
                            <i class="ri-menu-4-line"></i>
                        </span>
                    </div>
                </div>
            </div>
        </nav>
    </div>
    
    <!-- Navbar For Gaming Metaverse -->
    <div
        class="container style-three d-none"
        [ngClass]="{'d-block': router.url === '/index-3'}"
    >
        <nav class="navbar navbar-expand-lg navbar-light bg-light" [class.active]="classApplied">
            <a class="navbar-brand" routerLink="/">
                <img src="assets-app/images/logo-icon.png" alt="logo">
            </a>
            <button class="navbar-toggler" type="button" (click)="toggleClass()">
                <span class="burger-menu">
                    <span class="top-bar"></span>
                    <span class="middle-bar"></span>
                    <span class="bottom-bar"></span>
                </span>
            </button>
            <div class="collapse navbar-collapse">
                <ul class="navbar-nav">
                    <li class="nav-item"><a href="javascript:void(0)" class="dropdown-toggle nav-link">Home</a>
                        <ul class="dropdown-menu">
                            <li class="nav-item">
                                <a routerLink="/" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Metavarse Launchpad</a>
                            </li>
                            <li class="nav-item">
                                <a routerLink="/index-2" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Dex Offering</a>
                            </li>
                            <li class="nav-item">
                                <a routerLink="/index-3" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Gaming Metaverse</a>
                            </li>
                            <li class="nav-item">
                                <a routerLink="/index-4" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">NFT Centric Metaverse</a>
                            </li>
                            <li class="nav-item">
                                <a routerLink="/index-5" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">3D Land Selling Platform</a>
                            </li>
                        </ul>
                    </li>
                    <li class="nav-item">
                        <a routerLink="/about-us" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">About Us</a>
                    </li>
                    <li class="nav-item"><a href="javascript:void(0)" class="dropdown-toggle nav-link">Features</a>
                        <ul class="dropdown-menu">
                            <li class="nav-item">
                                <a routerLink="/features" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Features</a>
                            </li>
                            <li class="nav-item">
                                <a routerLink="/feature-details" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Feature Details</a>
                            </li>
                        </ul>
                    </li>
                    <li class="nav-item"><a href="javascript:void(0)" class="dropdown-toggle nav-link">Projects</a>
                        <ul class="dropdown-menu">
                            <li class="nav-item">
                                <a routerLink="/projects" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Projects</a>
                            </li>
                            <li class="nav-item">
                                <a routerLink="/project-details" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Project Details</a>
                            </li>
                        </ul>
                    </li>
                    <li class="nav-item"><a href="javascript:void(0)" class="dropdown-toggle nav-link">Pages</a>
                        <ul class="dropdown-menu">
                            <li class="nav-item">
                                <a routerLink="/about-us" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">About Us</a>
                            </li>
                            <li class="nav-item">
                                <a routerLink="/team" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Team</a>
                            </li>
                            <li class="nav-item">
                                <a routerLink="/privacy-policy" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Privacy Policy</a>
                            </li>
                            <li class="nav-item">
                                <a routerLink="/terms-conditions" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Terms & Conditions</a>
                            </li>
                            <li class="nav-item">
                                <a routerLink="/not-found" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">404 Error Page</a>
                            </li>
                        </ul>
                    </li>
                    <li class="nav-item"><a href="javascript:void(0)" class="dropdown-toggle nav-link">Blog</a>
                        <ul class="dropdown-menu">
                            <li class="nav-item">
                                <a routerLink="/blog" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Blog Grid</a>
                            </li>
                            <li class="nav-item">
                                <a routerLink="/blog-details" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Blog Details</a>
                            </li>
                        </ul>
                    </li>
                    <li class="nav-item">
                        <a routerLink="/contact-us" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Contact Us</a>
                    </li>
                </ul>
                <div class="others-option d-flex align-items-center">
                    <div class="option-item">
                        <a routerLink="/contact-us" class="default-btn">Apply For IDO</a>
                    </div>
                    <div class="option-item">
                        <span class="burger-menu" (click)="switcherToggleClass()">
                            <i class="ri-menu-4-line"></i>
                        </span>
                    </div>
                </div>
            </div>
        </nav>
    </div>

</div>

<!-- Sidebar Modal -->
<div class="sidebarModal" [class.active]="switcherClassApplied">
    <div class="modal-dialog">
        <div class="modal-content">
            <button type="button" class="close" (click)="switcherToggleClass()">
                <i class="ri-close-fill"></i>
            </button>
            <div class="modal-body">
                <div class="logo">
                    <a routerLink="/" class="d-inline-block">
                        <img src="assets-app/images/logo.png" alt="image">
                    </a>
                </div>
                <div class="instagram-list">
                    <div class="row">
                        <div class="col-lg-4 col-md-4 col-sm-4 col-6">
                            <div class="box">
                                <img src="assets-app/images/blog/blog-1.jpg" alt="image">
                                <i class="ri-instagram-line"></i>
                                <a href="#" target="_blank" class="link-btn"></a>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-4 col-sm-4 col-6">
                            <div class="box">
                                <img src="assets-app/images/blog/blog-2.jpg" alt="image">
                                <i class="ri-instagram-line"></i>
                                <a href="#" target="_blank" class="link-btn"></a>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-4 col-sm-4 col-6">
                            <div class="box">
                                <img src="assets-app/images/blog/blog-3.jpg" alt="image">
                                <i class="ri-instagram-line"></i>
                                <a href="#" target="_blank" class="link-btn"></a>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-4 col-sm-4 col-6">
                            <div class="box">
                                <img src="assets-app/images/blog/blog-4.jpg" alt="image">
                                <i class="ri-instagram-line"></i>
                                <a href="#" target="_blank" class="link-btn"></a>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-4 col-sm-4 col-6">
                            <div class="box">
                                <img src="assets-app/images/blog/blog-5.jpg" alt="image">
                                <i class="ri-instagram-line"></i>
                                <a href="#" target="_blank" class="link-btn"></a>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-4 col-sm-4 col-6">
                            <div class="box">
                                <img src="assets-app/images/blog/blog-6.jpg" alt="image">
                                <i class="ri-instagram-line"></i>
                                <a href="#" target="_blank" class="link-btn"></a>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="sidebar-contact-info">
                    <h2>
                        <a href="tel:+11234567890">+1 (123) 456 7890</a>
                        <span>OR</span>
                        <a href="mailto:hello&#64;mave.com">hello&#64;mave.com</a>
                    </h2>
                </div>
                <ul class="social-list">
                    <li><a href="#" target="_blank">
                        <i class="ri-facebook-fill"></i>
                    </a></li>
                    <li><a href="#" target="_blank">
                        <i class="ri-linkedin-fill"></i>
                    </a></li>
                    <li><a href="#" target="_blank">
                        <i class="ri-twitter-fill"></i>
                    </a></li>
                    <li><a href="#" target="_blank">
                        <i class="ri-instagram-line"></i>
                    </a></li>
                </ul>
            </div>
        </div>
    </div>
</div>