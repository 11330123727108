import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpHeaders
} from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {

  constructor() {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    console.debug(`Enter JWT interceptor...`)

    let headers = new HttpHeaders()

    if (localStorage.getItem('btfd-jwt')) {
      headers = headers.set(`Authorization`, `Bearer ${localStorage.getItem('btfd-jwt')}`)
    }

    request = request.clone({ headers })

    return next.handle(request)
  }
}
