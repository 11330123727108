<div class="banner-area" style="background-image: url(assets-app/images/banner/banner-bg-1.jpg);">
    <div class="container-fluid">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="banner-content">
                    <h1>Building The Metaverse For Next Gaming World</h1>
                    <p>The Metaverse Is The Next Generation Of The Internet. As One Of Its Earliest Explorers, You Will Help Fuel Its Expansion And Share In The Benefits Of This Growth.</p>
                    <div class="banner-btn">
                        <a routerLink="/project-details" class="default-btn btn-active">Open App</a>
                        <a routerLink="/projects" class="default-btn">Buy MWP Tokens</a>
                    </div>
                    <img src="assets-app/images/banner/banner-shape-4.png" class="shape banner-shape-4" alt="shape">
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="banner-image">
                    <img src="assets-app/images/banner/banner-img-1.png" alt="banner-img">
                </div>
            </div>
        </div>
    </div>
    <img src="assets-app/images/banner/banner-shape-1.png" class="shape shape-1" alt="shape">
    <img src="assets-app/images/banner/banner-shape-2.png" class="shape shape-2" alt="shape">
    <img src="assets-app/images/banner/banner-shape-5.png" class="shape shape-5" alt="shape">
</div>