<div class="featured-auction-area bg-color-140c2dr">
    <div class="container">
        <div class="featured-auction-bg">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-5">
                    <div class="featured-auction-info-wrap d-flex align-items-center">
                        <img src="assets-app/images/featured-auction-img.jpg" alt="featured-auction-img">
                        <div class="featured-auction-info">
                            <h3>Reign Of Terror</h3>
                            <span>Price (DDO) = 0.05 BUSD</span>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 col-md-7">
                    <div class="timer-wrap d-flex align-items-center justify-content-end">
                        <div>
                            <span>Sale End In:</span>
                            <div class="timer flex-wrap d-flex justify-content-center">
                                <div class="align-items-center flex-column d-flex justify-content-center">
                                    <span class="days">{{days}}</span>
                                    Days
                                </div>
                                <div class="align-items-center flex-column d-flex justify-content-center">
                                    <span class="hours">{{hours}}</span>
                                    Hours
                                </div>
                                <div class="align-items-center flex-column d-flex justify-content-center">
                                    <span class="minutes">{{minutes}}</span>
                                    Mins
                                </div>
                                <div class="align-items-center flex-column d-flex justify-content-center">
                                    <span class="seconds">{{seconds}}</span>
                                    Secs
                                </div>
                            </div>
                        </div>
                        <img src="assets-app/images/icon/icon-7.svg" alt="icon">
                    </div>
                </div>
            </div>
            <div class="featured-auction-bar">
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span class="bg-color"></span>
                <span class="bg-color"></span>
                <span class="bg-color"></span>
                <span class="bg-color"></span>
                <span class="bg-color"></span>
                <span class="bg-color"></span>
                <span class="bg-color"></span>
                <span class="bg-color"></span>
                <span class="bg-color"></span>
                <span class="bg-color"></span>
                <span class="bg-color"></span>
                <span class="bg-color"></span>
                <span class="bg-color"></span>
                <span class="bg-color"></span>
                <span class="bg-color"></span>
                <span class="bg-color"></span>
                <span class="bg-color"></span>
                <span class="bg-color"></span>
            </div>
            <ul class="total-raised d-flex justify-content-between">
                <li>Total raised: 42,300,000 BUSD (74%)</li>
                <li>Allocations: 1000 BUSD Max</li>
                <li>Targeted raise: 320,000,000 BUSD</li>
            </ul>
        </div>
    </div>
</div>