<app-multipage-navbar></app-multipage-navbar>

<app-gaming-metaverse-banner></app-gaming-metaverse-banner>

<app-featured-auction></app-featured-auction>

<app-how-it-works></app-how-it-works>

<app-projects></app-projects>

<app-token-info></app-token-info>

<app-roadmap></app-roadmap>

<app-earn-rewards></app-earn-rewards>

<app-team></app-team>

<app-partner></app-partner>