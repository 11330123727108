import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-nft-centric-metaverse-banner',
  templateUrl: './nft-centric-metaverse-banner.component.html',
  styleUrls: ['./nft-centric-metaverse-banner.component.scss']
})
export class NftCentricMetaverseBannerComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
