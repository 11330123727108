<!-- Page Title -->
<div class="page-banner-area" style="background-image: url(assets-app/images/page-banner-bg.jpg);">
    <div class="container">
        <div class="page-banner-content">
            <ul>
                <li>
                    <a routerLink="/">HOME</a>
                </li>
                <li><span>WAGER HISTORY</span></li>
            </ul>
            <h2>Wager History</h2>
            <img src="assets-app/images/page-star.png" class="star" alt="star">
        </div>
    </div>
    <img src="assets-app/images/banner/banner-shape-1.png" class="shape shape-1" alt="shape">
    <img src="assets-app/images/banner/banner-shape-2.png" class="shape shape-2" alt="shape">
</div>

<!-- Bet History Table -->
<div class="bet-history-container">
    <div class="bet-history-header">
      <div class="header-item">Date</div>
      <div class="header-item">Game Code</div>
      <div class="header-item">Bet ID</div>
      <div class="header-item">Wager Amount</div>
      <div class="header-item">Multiplier</div>
      <div class="header-item">Payout</div>
    </div>
    <div *ngFor="let bet of betPosition" class="bet-history-entry">
      <div class="entry-item">{{bet.date}}</div>
      <div class="entry-item">{{bet.gameCode}}</div>
      <div class="entry-item-click" (click)="handleBetUuidClick(bet.betUuid)">{{bet.betUuid}}</div>
      <div class="entry-item">{{bet.betAmount}}</div>
      <div class="entry-item">{{bet.multiplier}}</div>
      <div class="entry-item">{{bet.payout}}</div>
    </div>
    <div class="col-lg-12 col-md-12">
        <div class="read-more-btn">
            <a href="javascript:void(0)" (click)="loadMore()" class="read-more" >Load More</a>
        </div>
    </div>
  </div>
  