<!-- this is important to get width after scrollbar appear -->
<div #loadingDiv style="width: 100%; height: 100vh;" [style.display]="loading() ? 'block' : 'none'">

    Getting the game... Please wait...

</div>

<div #gameDiv id="GameDiv" cc_exact_fit_screen="false" [style.width]="gameDivWidth + 'px'" [style.height]="gameDivHeight + 'px'" [style.margin-left]="gameDivMargin + 'px'">
    <div id="Cocos3dGameContainer">
        <canvas id="GameCanvas" tabindex="99" [style.width]="'100%'"></canvas>
    </div>
</div>