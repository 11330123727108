import { Injectable, computed, signal } from '@angular/core';
import { createWeb3Modal, defaultConfig } from '@web3modal/ethers';
import { EthersStoreUtilState } from "@web3modal/scaffold-utils/ethers";
import { Web3Modal } from '@web3modal/ethers/dist/types/src/client';
import { BrowserProvider, Contract, ContractTransactionResponse, ethers, getBigInt, JsonRpcProvider, parseEther, TransactionRequest } from 'ethers';
import { Subject, firstValueFrom, lastValueFrom } from 'rxjs';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { GameService } from '../service/game.service';
import { CheckinContractAbi } from './contract-abi/checkin-contract-abi';
import { DailyCheckinDTO } from '../components/pages/inner-pages/check-in/check-in.component';
import { SocketService } from '../socket/socket.service';

export interface ISign {
  r: string
  s: string
  v: number
  expiration: number
  messageHash: string
}
@Injectable({
  providedIn: 'root'
})
export class Web3Service {

  /**
   * https://docs.zksync.io/build/quick-start/add-zksync-to-metamask.html
   */
  zkSyncEraTestnet = {
    chainId: 300,
    name: 'zkSync Sepolia Testnet',
    currency: 'ETH',
    explorerUrl: 'https://sepolia.explorer.zksync.io',
    rpcUrl: 'https://sepolia.era.zksync.dev',
  }
  zkCandyTestnet = {
    chainId: 302,
    name: 'zkCandy Sepolia Testnet',
    currency: 'ETH',
    explorerUrl: 'https://sepolia.explorer.zkcandy.io',
    rpcUrl: 'https://sepolia.rpc.zkcandy.io',
  }
  defaultNetwork = this.zkCandyTestnet
  selectedNetwork = this.defaultNetwork
  private web3Modal

  private isConnectedSig = signal(false)
  isConnected = computed(() => this.isConnectedSig())
  isConnectedObs = new Subject<boolean>()

  private walletUriSig = signal('')
  walletUri = computed(() => this.walletUriSig())

  isLoggedIn = signal(false)
  isLoggedInState = new Subject<boolean>()

  private ethersProviderReadOnly = new JsonRpcProvider(this.selectedNetwork.rpcUrl)

  constructor(
    private http: HttpClient,
    private gameService: GameService,
  ) {
    // setup web3modal
    const metadata = {
      name: environment.wcProjectName,
      description: environment.wcProjectName,
      url: environment.appUrl,
      icons: [environment.appUrl + '/favicon.ico'],
    }
    this.web3Modal = createWeb3Modal({
      ethersConfig: defaultConfig({ metadata }),
      chains: [this.defaultNetwork],
      allowUnsupportedChain: true,
      projectId: environment.wcProjectId,
      featuredWalletIds: [
        'c57ca95b47569778a828d19178114f4db188b89b763c899ba0be274e97267d96', // MetaMask
        '4622a2b2d6af1c9844944291e5e7351a6aa24cd7b23099efac1b2fd875da31a0', // Trust Wallet
      ],
    })
    this.web3Modal.subscribeProvider(this.handleStateChange.bind(this))
  }

  private async handleStateChange(state: EthersStoreUtilState) {
    console.debug('Wallet state change:', state)

    const chainId = state.chainId
    const isCorrectChainId = chainId ? chainId === this.defaultNetwork.chainId : false
    const isIncorrectChainId = !isCorrectChainId

    if (state.isConnected && isIncorrectChainId) {
      /**
       * Fix bug where modal is not showing
       */
      setTimeout(() => { this.askSwitchNetwork() }, 1_000)
      return 
    }
    if (state.isConnected && state.address && !this.walletUri()) {
      this.onConnected(state.address)
      return
    }

    this.onDisconnect()
  }

  private async onConnected(address: string) {
    this.walletUriSig.set(address)
    this.isConnectedSig.set(true)
    this.isConnectedObs.next(this.isConnected())

    await this.performLogin(address)
  }

  private async performLogin(address: string) {
    const {
      message,
    } = await firstValueFrom(this.requestChallenge())
    const provider = this.web3Modal.getWalletProvider()

    if (!provider) throw Error('WALLET_DISCONNECTED')

    if (!localStorage.getItem('btfd-jwt')) {
      const ethersProvider = new BrowserProvider(provider)
      const signer = await ethersProvider.getSigner()
      const signature = await signer.signMessage(message)
      const {
        id_token,
        refresh_token,
      } = await firstValueFrom(this.http.post<{ id_token: string, refresh_token: string }>(environment.apiUrl + `/authenticate`, { wallet: address, signature }))
      
      localStorage.setItem('btfd-jwt', id_token)
      localStorage.setItem('btfd-jwt-refresh', refresh_token)
    }

    this.onLoggedIn()
  }

  private async onLoggedIn() {
    this.isLoggedIn.set(true)
    this.isLoggedInState.next(this.isLoggedIn())

    this.gameService.getAvailableChip()
  }

  requestChallenge() {
    return this.http.get<{ message: string }>(environment.apiUrl + `/challenge/${this.walletUri()}/${this.selectedNetwork.chainId}`)
  }

  private onDisconnect() {
    this.gameService.setChip('0')
    this.walletUriSig.set('')
    this.logout()

    this.isConnectedSig.set(false)
    this.isConnectedObs.next(this.isConnected())
  }

  logout() {
    localStorage.removeItem('btfd-jwt')
    localStorage.removeItem('btfd-jwt-refresh')

    this.isLoggedIn.set(false)
    this.isLoggedInState.next(this.isLoggedIn())
  }

  async login() {
    if (this.isLoggedIn()) {
      await this.openModal()
      return
    }
    
    await this.disconnect()

    this.connect()
  }

  async connect() {
    if (this.isConnected()) {
      throw new Error('CONNECTED')
    }
    await this.openModal()
  }

  async disconnect() {
    await this.web3Modal.disconnect()
  }

  async openModal() {
    await this.web3Modal.open()
  }

  async readContract({ address, abi, fnName, fnParams }: {
    address: string, 
    abi: string[], 
    fnName: string, 
    fnParams?: any[],
  }) {
    const contract = new ethers.Contract(address, abi, this.ethersProviderReadOnly)

    let result = null

    if (fnParams) {
      result = await contract[fnName](...fnParams)
    } else {
      result = await contract[fnName]()
    }

    console.debug('readContract:')
    console.debug(result)

    return result
  }

  async writeContract({ address, abi, fnName, fnParams }: {
    address: string, 
    abi: string[], 
    fnName: string, 
    fnParams?: any[],
  }) {
    const chainId = await this.getChainId()

    if (chainId != ethers.getBigInt(this.defaultNetwork.chainId)) {
      this.askSwitchNetwork()
      return
    }

    const ethersProvider = this.getWriteProvider()
    const ethersSigner = await ethersProvider.getSigner()
    const contract = new ethers.Contract(address, abi, ethersSigner)

    let result = null

    if (fnParams) {
      result = await contract[fnName](...fnParams)
    } else {
      result = await contract[fnName]()
    }

    console.debug('writeContract:')
    console.debug(result)

    await result.wait()

    return result
  }

  async getChainId() {
    const provider = this.getWriteProvider()
    const network = await provider.getNetwork()

    return network.chainId
  }

  getWriteProvider() {
    const web3ModalProvider = this.web3Modal.getWalletProvider()

    if (!web3ModalProvider) {
      throw 'DISCONNECTED'
    }

    return new BrowserProvider(web3ModalProvider)
  }

  askSwitchNetwork() {
    this.web3Modal.open({ view: 'Networks' })
  }

  async handlleCheckin() {
    try {
      // const checkinDTO: CheckinDTO = await lastValueFrom(this.gameService.getCheckinSign());
      // return await this.contractService.checkin(checkinDTO);
      return await this.getCheckinSign()
    } catch (e) {
      throw e
    }
  }

  async checkAndHandleNetworkSwitch() {
    const chainId = await this.getChainId()
    if (chainId != ethers.getBigInt(this.defaultNetwork.chainId)) {
      this.askSwitchNetwork()
      return false
    }
    return true
  }

  async getCheckinSign() {
    const signMessage: ISign = await firstValueFrom(this.gameService.getCheckinSign())

    const provider = this.web3Modal.getWalletProvider()
    if (!provider) throw Error('WALLET_DISCONNECTED')

    const ethersProvider = new BrowserProvider(provider)
    const signer = await ethersProvider.getSigner()
    // await signer.signMessage('checkin')
    const abi = [
      'function checkIn(bytes32 hashMessage, uint8 v, bytes32 r, bytes32 s, uint256 checkInExp, address userWalletUrl)',
    ]
    const contract = new ethers.Contract(environment.checkinContractAddress, abi, signer)
    console.log(signMessage)

    const overrides = {
      gasLimit: 300000,
      // gasPrice: '200000'
    };
    
    try {
      const txnResponse: ContractTransactionResponse = await contract['checkIn'](
        `0x${signMessage.messageHash}`,
        signMessage.v,
        `0x${signMessage.r}`,
        `0x${signMessage.s}`,
        BigInt(signMessage.expiration),
        this.walletUri(),
      )

      const txnReceipt = await ethersProvider.waitForTransaction(txnResponse.hash)

      console.log(txnReceipt)
      return txnReceipt?.hash

    } catch (e: any) {
      console.error(e)
      if (e.reason === 'rejected') {
        console.log('user reject checkin')
        throw Error('user rejected checkin')
      }
      throw Error('error')
    }
  }

  async purchaseShopItemPackage(signAuth: any, amount: number) {
    const provider = this.web3Modal.getWalletProvider()
    if (!provider) throw Error('WALLET_DISCONNECTED')

    const ethersProvider = new BrowserProvider(provider)
    const signer = await ethersProvider.getSigner()
    // await signer.signMessage('checkin')
    const abi = [
      'function purchaseShopItem(bytes32 hashMessage, uint8 v, bytes32 r, bytes32 s, uint256 selectionNo, uint256 exp, address userWalletUrl) payable',
    ]
    const contract = new ethers.Contract(environment.shopContractAddress, abi, signer)
    const valueToSend = parseEther(`${amount}`)
    console.log(valueToSend)
    try {
      const wrote = await this.writeContract({
        address: environment.shopContractAddress,
        abi,
        fnName: 'purchaseShopItem',
        fnParams: [
          `0x${signAuth.messageHash}`,
          signAuth.v,
          `0x${signAuth.r}`,
          `0x${signAuth.s}`,
          BigInt(signAuth.selectionNo),
          BigInt(signAuth.expiration),
          this.walletUri(),
          { value: valueToSend },
        ],
      })

      console.debug('purchaseShopItem:')
      console.debug(wrote)

      return wrote.hash as string

    } catch (e: any) {
      console.error(e)
      if (e.reason === 'rejected') {
        console.log('user reject checkin')
        throw Error('user rejected checkin')
      }
      throw Error('error')
    }
  }

  //Write contract example
  // async mint() {
  //   const provider = this.web3modal.getWalletProvider()
  //   if (!provider) throw Error('WALLET_DISCONNECTED')

  //   const ethersProvider = new BrowserProvider(provider)
  //   const signer = await ethersProvider.getSigner()
  //   const abi = [
  //     'function mint()',
  //     'function lastMintTimestamp() external view returns (uint256)',
  //   ]
  //   const contract = new ethers.Contract(environment.sugarRushContractUri, abi, signer)

  //   await this.printBlockTimestamp()

  //   console.debug(⁠ Got lastMintTimestamp ${await contract['lastMintTimestamp']()} ⁠)
  //   console.debug(⁠ Minting... ⁠)

  //   const txnResponse: ContractTransactionResponse = await contract['mint']()
  //   const txnReceipt = await ethersProvider.waitForTransaction(txnResponse.hash)

  //   console.debug(⁠ Minted ⁠)

  //   await this.printBlockTimestamp()

  //   console.debug(⁠ Got lastMintTimestamp ${await contract['lastMintTimestamp']()} ⁠)
  // }

  //Read contract example
  // async getCurrentRound() {
  //   const provider = new JsonRpcProvider(this.defaultNetwork.rpcUrl)
  //   const abi = [
  //     'function currentRound() view returns (uint256)',
  //   ]
  //   const contract = new ethers.Contract(environment.sugarRushContractUri, abi, provider)
  //   const currentRound: BigNumberish = await contract['currentRound']()
  //   const seconds = parseInt(currentRound.toString())

  //   console.debug(⁠ Got currentRound. currentRound=${currentRound} ⁠)

  //   return seconds
  // }
}
