import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-dex-offering-banner',
    templateUrl: './dex-offering-banner.component.html',
    styleUrls: ['./dex-offering-banner.component.scss']
})
export class DexOfferingBannerComponent implements OnInit {

    constructor() { }

    ngOnInit(): void {}

}