import { Component, Input, OnInit, inject } from '@angular/core';
import { PageBetHistoryService } from './page-bet-history.service';
import { CommonModule } from '@angular/common';
import { Router } from '@angular/router';

@Component({
    selector: 'app-page-bet-history',
    standalone: true,
    imports: [CommonModule],
    templateUrl: './page-bet-history.component.html',
    styleUrl: './page-bet-history.component.scss',
})
export class PageBetHistoryComponent implements OnInit {
    constructor(
        private pageBetHistoryService: PageBetHistoryService,
        private router: Router
    ) {}

    page = 0;
    maxPage = 0;
    betPosition: any[] = [];

    ngOnInit(): void {
        this.loadData();
    }

    loadData() {
        this.pageBetHistoryService.fetchPlayerId().subscribe({
            next: (playerId) => {
                if (!playerId) return;
                this.pageBetHistoryService
                    .getBetHistory(playerId.playerId, this.page)
                    .subscribe({
                        next: (response) => {
                            const maxSize =
                                response.headers.get('X-Total-Count');
                            if (maxSize) {
                                const _maxSize = parseInt(maxSize);
                                const size = 10;

                                this.maxPage = Math.floor(_maxSize / size);
                            }

                            this.betPosition.push(...response.body);
                        },
                        error: (error) => {},
                        complete: () => {},
                    });
            },
            error: (error) => {},
            complete: () => {},
        });
    }

    loadMore() {
        if (this.page < this.maxPage) {
            this.page++;
            this.loadData();
        }
    }

    handleBetUuidClick(betUuid: string) {
        this.router.navigate(['/btfd-provablyfair'], {
            queryParams: { betUuid: betUuid },
        });
    }
}
