import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { ProvablyFairService } from './page-provably-fair.service';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { N } from 'ethers';
import { Observable } from 'rxjs';

@Component({
    selector: 'app-page-provably-fair',
    standalone: true,
    imports: [NgbDropdownModule],
    templateUrl: './page-provably-fair.component.html',
    styleUrl: './page-provably-fair.component.scss',
})
export class PageProvablyFairComponent implements OnInit {
    betUuid!: string;
    betData: any;
    serverSeed!: String;
    crashPoint: number | undefined;

    constructor(
        private route: ActivatedRoute,
        private provablyFairService: ProvablyFairService,
        private httpClient: HttpClient
    ) {}

    ngOnInit(): void {
        this.route.queryParams.subscribe((params) => {
            this.betUuid = params['betUuid'];

            if (this.betUuid) {
                this.fetchBetData(this.betUuid);
            } else {
                this.betData = {
                    serverSeed: '',
                    hashedServerSeed: '',
                    clientSeed: '',
                    nonce: 0, // Default nonce value
                    // Add default values for other fields as needed
                };
            }
        });
    }

    async verifyCrashRNG(): Promise<void> {
        if (this.betData) {
            const serverSeed = this.betData.serverSeed;
            const clientSeed = this.betData.clientSeed;
            const nonce = this.betData.nonce;

            console.log(nonce);
            this.crashPoint = await this.provablyFairService.verifyCrashRNG(
                serverSeed,
                clientSeed,
                nonce
            );
            console.log('Result:', this.crashPoint);
        }
    }

    fetchBetData(betUuid: string) {
        this.httpClient
            .get<any>(
                environment.apiUrl + `/provably-fair/player-bet-seed-data`,
                {
                    params: { betUuid },
                }
            )
            .subscribe({
                next: (response) => {
                    this.betData = response;
                    console.log('Bet data:', this.betData);
                    if (this.betData?.serverSeed) {
                        this.verifyCrashRNG();
                    }
                },
                error: (error) => {
                    console.error('Error fetching bet data:', error);
                },
            });
    }

    revealResultAndRotateSeeds(playerId: number, hashedServerSeed: string) {
        const params = {
            playerId: playerId,
            hashedServerSeed: hashedServerSeed,
        };

        this.httpClient
            .get<any>(environment.apiUrl + `/provably-fair/rotate-seed-pair`, {
                params: params,
            })
            .subscribe({
                next: (response) => {
                    // Update the serverSeed field with the response data
                    this.serverSeed = response;
                    console.log('ServerSeed:', this.serverSeed);
                    this.reloadPage();
                },
                error: (error) => {
                    console.error('Error fetching bet data:', error);
                },
            });
    }
    reloadPage() {
        // Reload the page
        location.reload();
    }

    handleClick(hashedServerSeed: string) {
        console.log('handleCLick peressed');
        this.fetchPlayerId().subscribe({
            next: (playerId) => {
                if (!playerId) return;
                this.revealResultAndRotateSeeds(
                    playerId.playerId,
                    hashedServerSeed
                );
            },
            error: (error) => {},
            complete: () => {},
        });
    }

    updateResult(event: any) {
        this.betData.nonce = event.target.value;
        this.verifyCrashRNG();
    }

    fetchPlayerId(): Observable<any> {
        return this.httpClient.get<any>(environment.apiUrl + `/player/id`);
    }
}
