import { CommonModule } from '@angular/common';
import { Component, HostListener, OnInit } from '@angular/core';
import { RouterModule } from '@angular/router';
import { Web3Service } from '../web3/web3.service';
import { GameService } from '../service/game.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PageCheckinComponent } from '../page-checkin/page-checkin.component';
import { LocalStorageService } from 'ngx-webstorage';

@Component({
    selector: 'app-common-navbar',
    standalone: true,
    imports: [CommonModule, RouterModule],
    templateUrl: './common-navbar.component.html',
    styleUrl: './common-navbar.component.scss',
})
export class CommonNavbarComponent implements OnInit {
    _gameService: GameService;

    constructor(
        public web3Service: Web3Service,
        public gameService: GameService,
        private modalService: NgbModal,
        private $localStorage: LocalStorageService
    ) {
        this._gameService = gameService;
    }

    ngOnInit(): void {
        this.web3Service.isConnectedObs.subscribe((connected) => {
            if (connected) {
                //Get chip from backend, first initiated
                this.gameService.getAvailableChip();
            }
        });
    }

    classApplied = false;
    toggleClass() {
        this.classApplied = !this.classApplied;
    }

    isSticky = false;

    @HostListener('window:scroll', ['$event'])
    checkScroll() {
        const scrollPosition =
            window.pageYOffset ||
            document.documentElement.scrollTop ||
            document.body.scrollTop ||
            0;
        if (scrollPosition >= 50) {
            this.isSticky = true;
        } else {
            this.isSticky = false;
        }
    }

    login() {
        this.web3Service.login()
    }

    openCheckinReward() {
        this.setCheckinViewState(true);
        this.modalService.open(PageCheckinComponent);
    }

    setCheckinViewState(isTodayView: boolean) {
        this.$localStorage.store('checkinViewState', isTodayView);
    }

    getCheckinViewState() {
        return this.$localStorage.retrieve('checkinViewState');
    }
}
