<!-- Key Features for Metavarse Launchpad -->
<div
    id="features"
    class="key-features-area pt-120 pb-90 bg-140c2dr"
    [ngClass]="{'d-none': router.url === '/index-2' || router.url === '/dex-offering'}"
>
    <div class="container">
        <div class="section-title">
            <span class="top-title">KEY FEATURES</span>
            <h2>Access The Future</h2>
        </div>
        <div class="row">
            <div class="col-lg-4 col-sm-6">
                <div class="single-features">
                    <img src="assets-app/images/icon/icon-1.svg" alt="icon">
                    <h3>
                        <a routerLink="/feature-details">Fueling The Metaverse</a>
                    </h3>
                    <p>The Metaverse Is The Next Generation Of The Internet. As One Of Its Earliest Explorers, You Will Help Fuel Its Expansion.</p>
                    <a routerLink="/feature-details" class="read-more">READ MORE</a>
                </div>
            </div>
            <div class="col-lg-4 col-sm-6">
                <div class="single-features">
                    <img src="assets-app/images/icon/icon-2.svg" alt="icon">
                    <h3>
                        <a routerLink="/feature-details">Interconnected Economies</a>
                    </h3>
                    <p>The Metaverse Is The Next Generation Of The Internet. As One Of Its Earliest Explorers, You Will Help Fuel Its Expansion.</p>
                    <a routerLink="/feature-details" class="read-more">READ MORE</a>
                </div>
            </div>
            <div class="col-lg-4 col-sm-6">
                <div class="single-features">
                    <img src="assets-app/images/icon/icon-3.svg" alt="icon">
                    <h3>
                        <a routerLink="/feature-details">Non-fungible assets</a>
                    </h3>
                    <p>The Metaverse Is The Next Generation Of The Internet. As One Of Its Earliest Explorers, You Will Help Fuel Its Expansion.</p>
                    <a routerLink="/feature-details" class="read-more">READ MORE</a>
                </div>
            </div>
            <div class="col-lg-4 col-sm-6">
                <div class="single-features">
                    <img src="assets-app/images/icon/icon-4.svg" alt="icon">
                    <h3>
                        <a routerLink="/feature-details">Incubation</a>
                    </h3>
                    <p>The Metaverse Is The Next Generation Of The Internet. As One Of Its Earliest Explorers, You Will Help Fuel Its Expansion.</p>
                    <a routerLink="/feature-details" class="read-more">READ MORE</a>
                </div>
            </div>
            <div class="col-lg-4 col-sm-6">
                <div class="single-features">
                    <img src="assets-app/images/icon/icon-5.svg" alt="icon">
                    <h3>
                        <a routerLink="/feature-details">Initial Metaverse Offering </a>
                    </h3>
                    <p>The Metaverse Is The Next Generation Of The Internet. As One Of Its Earliest Explorers, You Will Help Fuel Its Expansion.</p>
                    <a routerLink="/feature-details" class="read-more">READ MORE</a>
                </div>
            </div>
            <div class="col-lg-4 col-sm-6">
                <div class="single-features">
                    <img src="assets-app/images/icon/icon-6.svg" alt="icon">
                    <h3>
                        <a routerLink="/feature-details">Next Tier Metaverse Projects</a>
                    </h3>
                    <p>The Metaverse Is The Next Generation Of The Internet. As One Of Its Earliest Explorers, You Will Help Fuel Its Expansion.</p>
                    <a routerLink="/feature-details" class="read-more">READ MORE</a>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- Key Features for Dex Offering -->
<div
    id="features"
    class="key-features-area pt-120 pb-90 d-none"
    [ngClass]="{'d-block': router.url === '/index-2' || router.url === '/dex-offering'}"
>
    <div class="container">
        <div class="section-title">
            <span class="top-title">KEY FEATURES</span>
            <h2>Access The Future</h2>
        </div>
        <div class="row">
            <div class="col-lg-4 col-sm-6">
                <div class="single-features style-two">
                    <div class="d-flex align-items-center">
                        <img src="assets-app/images/icon/icon-1.svg" alt="icon">
                        <h3 class="ms-3">
                            <a routerLink="/feature-details">Fueling The Metaverse</a>
                        </h3>
                    </div>
                    <p>The Metaverse Is The Next Generation Of The Internet. As One Of Its Earliest Explorers.</p>
                    <a routerLink="/feature-details" class="read-more">READ MORE</a>
                </div>
            </div>
            <div class="col-lg-4 col-sm-6">
                <div class="single-features style-two">
                    <div class="d-flex align-items-center">
                        <img src="assets-app/images/icon/icon-2.svg" alt="icon">
                        <h3 class="ms-3">
                            <a routerLink="/feature-details">Interconnected Eco</a>
                        </h3>
                    </div>
                    <p>The Metaverse Is The Next Generation Of The Internet. As One Of Its Earliest Explorers.</p>
                    <a routerLink="/feature-details" class="read-more">READ MORE</a>
                </div>
            </div>
            <div class="col-lg-4 col-sm-6">
                <div class="single-features style-two">
                    <div class="d-flex align-items-center">
                        <img src="assets-app/images/icon/icon-3.svg" alt="icon">
                        <h3 class="ms-3">
                            <a routerLink="/feature-details">Non-fungible assets</a>
                        </h3>
                    </div>
                    <p>The Metaverse Is The Next Generation Of The Internet. As One Of Its Earliest Explorers.</p>
                    <a routerLink="/feature-details" class="read-more">READ MORE</a>
                </div>
            </div>
            <div class="col-lg-4 col-sm-6">
                <div class="single-features style-two">
                    <div class="d-flex align-items-center">
                        <img src="assets-app/images/icon/icon-4.svg" alt="icon">
                        <h3 class="ms-3">
                            <a routerLink="/feature-details">Incubation</a>
                        </h3>
                    </div>
                    <p>The Metaverse Is The Next Generation Of The Internet. As One Of Its Earliest Explorers.</p>
                    <a routerLink="/feature-details" class="read-more">READ MORE</a>
                </div>
            </div>
            <div class="col-lg-4 col-sm-6">
                <div class="single-features style-two">
                    <div class="d-flex align-items-center">
                        <img src="assets-app/images/icon/icon-5.svg" alt="icon">
                        <h3 class="ms-3">
                            <a routerLink="/feature-details">Initial Metaverse Offer</a>
                        </h3>
                    </div>
                    <p>The Metaverse Is The Next Generation Of The Internet. As One Of Its Earliest Explorers.</p>
                    <a routerLink="/feature-details" class="read-more">READ MORE</a>
                </div>
            </div>
            <div class="col-lg-4 col-sm-6">
                <div class="single-features style-two">
                    <div class="d-flex align-items-center">
                        <img src="assets-app/images/icon/icon-6.svg" alt="icon">
                        <h3 class="ms-3">
                            <a routerLink="/feature-details">Next Tier Projects</a>
                        </h3>
                    </div>
                    <p>The Metaverse Is The Next Generation Of The Internet. As One Of Its Earliest Explorers.</p>
                    <a routerLink="/feature-details" class="read-more">READ MORE</a>
                </div>
            </div>
        </div>
    </div>
    <img src="assets-app/images/features-shape-1.png" class="shape shape-1" alt="shape">
	<img src="assets-app/images/features-shape-2.png" class="shape shape-2" alt="shape">
</div>