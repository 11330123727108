<div class="slider-container">
    <input type="range" min="2" max="98" [(ngModel)]="sliderValue"
        class="slider" (input)="updateSliderBackground()" />
    <div class="slider-values">        
        <span>0</span>
        <span>25</span>
        <span>50</span>
        <span>75</span>
        <span>100</span>
    </div>
</div>
<div class="bet-info">
    <div class="bet-info-item">
        <label>Multiplier</label>
        <div>{{ multiplier.toFixed(4) }}</div>
        <button (click)="increaseMultiplierValue()">▲</button>
        <button (click)="decreaseMultiplierValue()">▼</button>
    </div>
    <div class="bet-info-item">
        <label>{{ rollOver ? 'Roll Over' : ' Roll Under' }}</label>
        <div>{{ sliderValue.toFixed(2) }}</div>
        <button class="roll-over-button" (click)="toggleRollOver()">↺</button>
    </div>

    <div class="bet-info-item">
        <label>Win Chance</label>
        <div>{{ winChance.toFixed(4) }} %</div>
        <button (click)="increaseWinChanceValue()">▲</button>
        <button (click)="decreaseWinChanceValue()">▼</button>
    </div>


</div>
<div class =bet-info-item>
    <label>Wager Amount</label>
    <input type="number" [(ngModel)]="betAmount" (change)="updateBetAmount()" min="10" step="1"/>
    <button class="roll-over-button" (click)="onBet()">Wager</button>

</div>