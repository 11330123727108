<app-onepage-navbar></app-onepage-navbar>

<app-nft-centric-metaverse-banner></app-nft-centric-metaverse-banner>

<app-ecosystem></app-ecosystem>

<app-projects></app-projects>

<app-team></app-team>

<app-partner></app-partner>

<app-roadmap></app-roadmap>

<app-earn-rewards></app-earn-rewards>

<app-news-articles></app-news-articles>