import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { Web3Service } from '../web3/web3.service';

export const authGuard: CanActivateFn = (route, state) => {
  const web3Service = inject(Web3Service)
  const router = inject(Router)

  const jwt = localStorage.getItem('btfd-jwt')

  if (!jwt) {
    console.debug('authGuard: Missing JWT')
    web3Service.login()
    router.navigate(['/'])
    return false
  }

  return true
}
