<div
    id="about"
    class="about-area bg-170f32 pt-120"
    [ngClass]="{'style-two pb-120': router.url === '/index-2' || router.url === '/index-5' || router.url === '/dex-offering' || router.url === '/land-selling-platform'}"
>
    <div class="container">
        <div class="row">
            <div class="col-lg-6 col-md-12">
                <div class="about-image style-bottom">
                    <img src="assets-app/images/about-img.png" alt="abotu-image">
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="about-content">
                    <span class="top-title">ABOUT US</span>
                    <h2>Mave Will Be The Best Choice For Your Next Project</h2>
                    <p>A Gamified Marketplace Dedicated To The Metaverse And Gaming Assets. The Metaverse Marketplace Allows Users To Trade, Auction, And Rent Virtual Ownership Assets From Various Metaverse And Gaming Projects Gamified Marketplace Dedicated To The.</p>
                    <p>A Gamified Marketplace Dedicated To The Metaverse And Gaming Assets. The Metaverse Marketplace Allows Users To Trade, Auction, And Rent Virtual Ownership.</p>
                    <ul>
                        <li>
                            <a href="https://www.facebook.com/" target="_blank">
                                <img src="assets-app/images/icon/icon-8.svg" alt="facebook">
                            </a>
                        </li>
                        <li>
                            <a href="https://www.facebook.com/" target="_blank">
                                <img src="assets-app/images/icon/icon-9.svg" alt="twitter">
                            </a>
                        </li>
                        <li>
                            <a href="https://www.facebook.com/" target="_blank">
                                <img src="assets-app/images/icon/icon-10.svg" alt="youtube">
                            </a>
                        </li>
                        <li>
                            <a href="https://www.facebook.com/" target="_blank">
                                <img src="assets-app/images/icon/icon-11.svg" alt="linkedin">
                            </a>
                        </li>
                        <li>
                            <a href="https://www.facebook.com/" target="_blank">
                                <img src="assets-app/images/icon/instagram.svg" alt="instagram">
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>