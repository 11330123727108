<div id="statistics" class="statistics-area ptb-120">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="statistics-bar">
                    <div class="bg-1 mb-20 d-flex justify-content-between align-items-center">
                        <span>Land NFT Minted</span>
                        <h3>486,822</h3>
                    </div>
                    <div class="bg-2 mb-20 d-flex justify-content-between align-items-center">
                        <span><i class="ri-information-line"></i> Commission Pool</span>
                        <span>486,822</span>
                    </div>
                    <div class="bg-2 mb-20 d-flex justify-content-between align-items-center">
                        <span><i class="ri-information-line"></i> Charity Pool</span>
                        <span>2,066,343 USDT</span>
                    </div>
                    <div class="all-flags bg-2 mb-20 d-flex justify-content-between align-items-center">
                        <div class="expensive">
                            <span>Most Expensive Countries</span>
                        </div>
                        <div class="flag">
                            <img src="assets-app/images/flag/flag-1.png" alt="flag">
                            <p>45.54 USDT/Tile</p>
                        </div>
                        <div class="flag">
                            <img src="assets-app/images/flag/flag-2.png" alt="flag">
                            <p>45.54 USDT/Tile</p>
                        </div>
                        <div class="flag">
                            <img src="assets-app/images/flag/flag-3.png" alt="flag">
                            <p>45.54 USDT/Tile</p>
                        </div>
                    </div>
                    <div class="all-flags bg-2 mb-20 d-flex justify-content-between align-items-center">
                        <div class="expensive">
                            <span>Most Popular Countries</span>
                        </div>
                        <div class="flag">
                            <img src="assets-app/images/flag/flag-4.png" alt="flag">
                            <p>45.54 USDT/Tile</p>
                        </div>
                        <div class="flag">
                            <img src="assets-app/images/flag/flag-5.png" alt="flag">
                            <p>45.54 USDT/Tile</p>
                        </div>
                        <div class="flag">
                            <img src="assets-app/images/flag/flag-6.png" alt="flag">
                            <p>45.54 USDT/Tile</p>
                        </div>
                    </div>
                    <p class="total">Total Land Sold</p>
                    <div class="reign-bar text-center">
                        <span class="bg-color"></span>
                        <span class="bg-color"></span>
                        <span class="bg-color"></span>
                        <span class="bg-color"></span>
                        <span class="bg-color"></span>
                        <span class="bg-color"></span>
                        <span class="bg-color"></span>
                        <span class="bg-color"></span>
                        <span class="bg-color"></span>
                        <span class="bg-color"></span>
                        <span class="bg-color"></span>
                        <span class="bg-color"></span>
                        <span class="bg-color"></span>
                        <span class="bg-color"></span>
                        <span class="bg-color"></span>
                        <span class="bg-color"></span>
                        <span class="bg-color"></span>
                        <span class="bg-color"></span>
                        <span class="bg-color"></span>
                        <span class="bg-color"></span>
                        <span class="bg-color"></span>
                        <span class="bg-color"></span>
                        <span class="bg-color"></span>
                        <span class="bg-color"></span>
                        <span class="bg-color"></span>
                        <span class="bg-color"></span>
                        <span class="bg-color"></span>
                        <span class="bg-color"></span>
                        <span class="bg-color"></span>
                    </div>
                    <ul class="milion-list d-flex justify-content-between">
                        <li>0M</li>
                        <li>3M</li>
                        <li>6M</li>
                        <li>9M</li>
                        <li>12M</li>
                        <li>15M</li>
                        <li>18M</li>
                        <li>21M</li>
                        <li>24M</li>
                        <li><span>Moon</span></li>
                    </ul>
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="statistics-content">
                    <span class="top-title">STATISTICS</span>
                    <h2>Mave Metaverse Stats At A Glance</h2>
                    <p>You can buy land tiles with Matic coin, so you have to set-up the Polygon (MATIC) network can buy land tiles with Matic coin, so you have to set-up in your wallet. If you have any problems, ask the admin (Knowix) or the community on <a routerLink="/project-details">Discord,</a> they are happy to help or search details for help: <a routerLink="/project-details">click here</a></p>
                    <p>NFT based, transparent digital land purchasing platform without a middle-man. Instantly buying and selling land, directly from your wallet.</p>
                    <a routerLink="/projects" class="default-btn btn-active">BUY LAND IMMEDIATELY</a>
                </div>
            </div>
        </div>
    </div>
    <img src="assets-app/images/statistics-shape.png" class="shape shape-1" alt="shape">
    <img src="assets-app/images/banner/banner-shape-13.png" class="shape shape-2" alt="shape">
</div>