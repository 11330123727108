<app-multipage-navbar></app-multipage-navbar>

<!-- Page Title -->
<div class="page-banner-area" style="background-image: url(assets-app/images/page-banner-bg.jpg);">
    <div class="container">
        <div class="page-banner-content">
            <ul>
                <li>
                    <a routerLink="/">
                        HOME
                    </a>
                </li>
                <li>
                    <span>FEATURES</span>
                </li>
            </ul>
            <h2>Features</h2>
            <img src="assets-app/images/page-star.png" class="star" alt="star">
        </div>
    </div>
    <img src="assets-app/images/banner/banner-shape-1.png" class="shape shape-1" alt="shape">
    <img src="assets-app/images/banner/banner-shape-2.png" class="shape shape-2" alt="shape">
</div>

<!-- Key Features Area -->
<div class="key-features-area pt-120">
    <div class="container">
        <div class="section-title">
            <span class="top-title">KEY FEATURES</span>
            <h2>Access The Future</h2>
        </div>
        <div class="row">
            <div class="col-lg-4 col-sm-6">
                <div class="single-features style-two">
                    <img src="assets-app/images/icon/icon-1.svg" alt="icon">
                    <h3>
                        <a routerLink="/feature-details">Fueling The Metaverse</a>
                    </h3>
                    <p>The Metaverse Is The Next Generation Of The Internet. As One Of Its Earliest Explorers, You Will Help Fuel Its Expansion.</p>
                    <a routerLink="/feature-details" class="read-more">READ MORE</a>
                </div>
            </div>
            <div class="col-lg-4 col-sm-6">
                <div class="single-features style-two">
                    <img src="assets-app/images/icon/icon-2.svg" alt="icon">
                    <h3>
                        <a routerLink="/feature-details">Interconnected Economies</a>
                    </h3>
                    <p>The Metaverse Is The Next Generation Of The Internet. As One Of Its Earliest Explorers, You Will Help Fuel Its Expansion.</p>
                    <a routerLink="/feature-details" class="read-more">READ MORE</a>
                </div>
            </div>
            <div class="col-lg-4 col-sm-6">
                <div class="single-features style-two">
                    <img src="assets-app/images/icon/icon-3.svg" alt="icon">
                    <h3>
                        <a routerLink="/feature-details">Non-fungible assets</a>
                    </h3>
                    <p>The Metaverse Is The Next Generation Of The Internet. As One Of Its Earliest Explorers, You Will Help Fuel Its Expansion.</p>
                    <a routerLink="/feature-details" class="read-more">READ MORE</a>
                </div>
            </div>
            <div class="col-lg-4 col-sm-6">
                <div class="single-features style-two">
                    <img src="assets-app/images/icon/icon-4.svg" alt="icon">
                    <h3>
                        <a routerLink="/feature-details">Incubation</a>
                    </h3>
                    <p>The Metaverse Is The Next Generation Of The Internet. As One Of Its Earliest Explorers, You Will Help Fuel Its Expansion.</p>
                    <a routerLink="/feature-details" class="read-more">READ MORE</a>
                </div>
            </div>
            <div class="col-lg-4 col-sm-6">
                <div class="single-features style-two">
                    <img src="assets-app/images/icon/icon-5.svg" alt="icon">
                    <h3>
                        <a routerLink="/feature-details">Initial Metaverse Offering </a>
                    </h3>
                    <p>The Metaverse Is The Next Generation Of The Internet. As One Of Its Earliest Explorers, You Will Help Fuel Its Expansion.</p>
                    <a routerLink="/feature-details" class="read-more">READ MORE</a>
                </div>
            </div>
            <div class="col-lg-4 col-sm-6">
                <div class="single-features style-two">
                    <img src="assets-app/images/icon/icon-6.svg" alt="icon">
                    <h3>
                        <a routerLink="/feature-details">Next Tier Metaverse Projects</a>
                    </h3>
                    <p>The Metaverse Is The Next Generation Of The Internet. As One Of Its Earliest Explorers, You Will Help Fuel Its Expansion.</p>
                    <a routerLink="/feature-details" class="read-more">READ MORE</a>
                </div>
            </div>
            <div class="col-lg-12 col-md-12">
                <div class="read-more-btn">
                    <a routerLink="/features" class="read-more">Load More Features</a>
                </div>
            </div>
        </div>
    </div>
    <img src="assets-app/images/features-shape-1.png" class="shape shape-1" alt="shape">
    <img src="assets-app/images/features-shape-2.png" class="shape shape-2" alt="shape">
</div>

<app-earn-rewards></app-earn-rewards>