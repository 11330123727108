<app-multipage-navbar></app-multipage-navbar>

<!-- Page Title -->
<div class="page-banner-area" style="background-image: url(assets-app/images/page-banner-bg.jpg);">
    <div class="container">
        <div class="page-banner-content">
            <ul>
                <li>
                    <a routerLink="/">
                        HOME
                    </a>
                </li>
                <li>
                    <span>BLOG DETAILS</span>
                </li>
            </ul>
            <h2>Blog Details</h2>
            <img src="assets-app/images/page-star.png" class="star" alt="star">
        </div>
    </div>
    <img src="assets-app/images/banner/banner-shape-1.png" class="shape shape-1" alt="shape">
    <img src="assets-app/images/banner/banner-shape-2.png" class="shape shape-2" alt="shape">
</div>

<!-- Blog Details -->
<div class="blog-details-area ptb-120">
    <div class="container">
        <div class="blog-details-content">
            <h2>Next Earth’s Agenda and Other Updates In A Whole For Nex Era</h2>
            <ul>
                <li>
                    <span>16 Aug, 2024</span>
                </li>
                <li>
                    Written By: <a routerLink="/blog">Anda Smith</a>
                </li>
            </ul>
            <p><span>A</span> gamified marketplace dedicated to the metaverse and gaming assets. The Metaverse marketplace allows users to trade, auction, and rent virtual ownership assets from various metaverse and gaming projects.</p>
            <p>A gamified marketplace dedicated to the metaverse and gaming assets. The Metaverse marketplace allows users to trade, auction, and rent virtual ownership.</p>
            <div class="blog-details-img">
                <img src="assets-app/images/blog/blog-9.jpg" alt="blog-image">
            </div>
            <p>A gamified marketplace dedicated to the metaverse and gaming assets. The Metaverse marketplace allows users to trade, auction, and rent virtual ownership assets from various metaverse and gaming projects.</p>
            <p>A gamified marketplace dedicated to the metaverse and gaming assets. The Metaverse marketplace allows users to trade, auction, and rent virtual ownership.</p>
            <blockquote>
                <i class="ri-double-quotes-l"></i>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Scelerisque interdum nunc donec sed. Molestie pretium por dictum id eu, erat massa. Ut vulputate fermentum, int lorem Iiaculis lobortis consectetur.
            </blockquote>
            <p>A gamified marketplace dedicated to the metaverse and gaming assets. The Metaverse marketplace allows users to trade, auction, and rent virtual ownership assets from various metaverse and gaming projects.</p>
            <p>A gamified marketplace dedicated to the metaverse and gaming assets. The Metaverse marketplace allows users to trade, auction, and rent virtual ownership.</p>
            <div class="read-more-btn">
                <a routerLink="/blog" class="read-more">View All Blogs</a>
            </div>
        </div>
    </div>
    <img src="assets-app/images/blog-shape.png" class="blog-shape" alt="shape">
</div>