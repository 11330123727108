<div class="banner-area" style="background-image: url(assets-app/images/banner/banner-bg-2.jpg);">
    <div class="container">
        <div class="banner-content">
            <h1>Metaverse Ecosystem For Growing New Projects</h1>
            <div class="banner-btn">
                <a routerLink="/projects" class="default-btn btn-active">Open App</a>
                <a routerLink="/projects" class="default-btn">Buy MWP Tokens</a>
                <a routerLink="/projects" class="default-btn connect">Connect Wallet</a>
            </div>
        </div>
    </div>
</div>