<!-- Page Title -->
<div class="page-banner-area" style="background-image: url(assets-app/images/page-banner-bg.jpg);">
    <div class="container">
        <div class="page-banner-content">
            <ul>
                <li>
                    <a routerLink="/">
                        HOME
                    </a>
                </li>
                <li>
                    <span>LEADERBOARD</span>
                </li>
            </ul>
            <h2>Leaderboard</h2>
            <img src="assets-app/images/page-star.png" class="star" alt="star">
        </div>
    </div>
    <img src="assets-app/images/banner/banner-shape-1.png" class="shape shape-1" alt="shape">
    <img src="assets-app/images/banner/banner-shape-2.png" class="shape shape-2" alt="shape">
</div>

<!-- Projects Details -->
<div class="projects-details-area ptb-120">
    <div class="container">
        <div class="projects-wrap-tabs">
            <ul class="nav-tabset">
                <li class="nav-tab" [ngClass]="{'active': true}">
                    <span>
                        Season 1
                    </span>
                </li>
            </ul>
            <div class="tabs-container">
                <div class="pane" id="tab4">
                    <div class="row align-items-center">
                        <div class="col-12">
                            <!-- Check if selfPosition is defined -->
                            <div *ngIf="selfPosition">
                                <div class="single-token-list">
                                    <ul class="d-flex justify-content-between">
                                        <li>
                                            <h4><sub>Rank</sub> {{selfPosition.position}}</h4>
                                            <p>You</p>
                                        </li>
                                        <li class="text-end">
                                            <h4>{{selfPosition.totalPoint}}</h4>
                                            <p>Points</p>
                                        </li>
                                    </ul>
                                </div>
                            </div>
            <hr class="divider">
            <div class="tabs-container">
                <div class="pane" id="tab4">
                    <div class="row align-items-center">
                        <div class="col-12">
                            @for (placement of positions; track $index) {
                                <div class="single-token-list">
                                    <ul class="d-flex justify-content-between">
                                        <li>
                                            <h4><sub>Rank</sub> {{placement.position}} </h4>
                                            <p>{{placement.playerWallet}}</p>
                                        </li>
                                        <li class="text-end">
                                            <h4>{{placement.totalPoint}}</h4>
                                            <p>Points</p>
                                        </li>
                                    </ul>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-12 col-md-12">
                <div class="read-more-btn" *ngIf="hasMoreItems()">
                    <a href="javascript:void(0)" (click)="loadMore()" class="read-more" >Load More</a>
                </div>
            </div>
        </div>
    </div>
</div>