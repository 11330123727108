import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-land-selling-platform',
  templateUrl: './land-selling-platform.component.html',
  styleUrls: ['./land-selling-platform.component.scss']
})
export class LandSellingPlatformComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
