<div class="banner-area bg-140c2d ptb-120">
    <div class="container-fluid">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="banner-image">
                    <img src="assets-app/images/banner/banner-img-6.jpg" alt="banner-image">
                    <img src="assets-app/images/banner/banner-img-7.jpg" class="banner-img-7" alt="banner-image">
                    <img src="assets-app/images/banner/banner-img-8.jpg" class="banner-img-8" alt="banner-image">
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="banner-content">
                    <h1>Find NFT-Centric Metaver. Incubator In Mave World</h1>
                    <p>The Metaverse Is The Next Generation Of The Internet. As One Of Its Earliest Explorers, You Will Help Fuel Its Expansion And Share In The Benefits Of This Growth.</p>
                    <a routerLink="/features" class="default-btn btn-active">
                        Get Notified
                        <img class="ms-2" src="assets-app/images/icon/arrow-right.svg" alt="icon">
                    </a>
                    <img src="assets-app/images/banner/banner-shape-4.png" class="shape banner-shape-4" alt="shape">
                    <img src="assets-app/images/banner/banner-shape-9.png" class="shape banner-shape-9" alt="shape">
                    <img src="assets-app/images/banner/banner-shape-10.png" class="shape banner-shape-10" alt="shape">
                </div>
            </div>
        </div>
    </div>
    <img src="assets-app/images/banner/banner-shape-1.png" class="shape shape-1" alt="shape">
    <img src="assets-app/images/banner/banner-shape-8.png" class="shape shape-6" alt="shape">
    <img src="assets-app/images/banner/banner-shape-14.png" class="shape shape-7" alt="shape">
</div>