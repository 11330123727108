<app-multipage-navbar></app-multipage-navbar>

<!-- Page Title -->
<div class="page-banner-area" style="background-image: url(assets-app/images/page-banner-bg.jpg);">
    <div class="container">
        <div class="page-banner-content">
            <ul>
                <li>
                    <a routerLink="/">
                        HOME
                    </a>
                </li>
                <li>
                    <span>FEATURE DETAILS</span>
                </li>
            </ul>
            <h2>Feature Details</h2>
            <img src="assets-app/images/page-star.png" class="star" alt="star">
        </div>
    </div>
    <img src="assets-app/images/banner/banner-shape-1.png" class="shape shape-1" alt="shape">
    <img src="assets-app/images/banner/banner-shape-2.png" class="shape shape-2" alt="shape">
</div>

<!-- Features Details -->
<div class="feature-details-area ptb-120">
    <div class="container">
        <div class="feature-details-content">
            <h3>Fueling The Metaverse</h3>
            <p>A gamified marketplace dedicated to the metaverse and gaming assets. The Metaverse marketplace allows users to trade, auction, and rent virtual ownership assets from various metaverse and gaming projects.</p>
            <p>A gamified marketplace dedicated to the metaverse and gaming assets. The Metaverse marketplace allows users to trade, auction, and rent virtual ownership.</p>
            <img src="assets-app/images/feature-details-img.jpg" alt="feature-image">
            <p>A gamified marketplace dedicated to the metaverse and gaming assets. The Metaverse marketplace allows users to trade, auction, and rent virtual ownership assets from various metaverse and gaming projects.</p>
            <p>A gamified marketplace dedicated to the metaverse and gaming assets. The Metaverse marketplace allows users to trade, auction, and rent virtual ownership.</p>
            <div class="read-more-btn">
                <a routerLink="/features" class="read-more">See Other Features</a>
            </div>
        </div>
    </div>
</div>