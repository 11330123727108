import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { PageGameDiceRollService } from './page-game-dice-roll.service';

@Component({
    selector: 'app-page-game-dice-roll',
    standalone: true,
    imports: [CommonModule, FormsModule],
    templateUrl: './page-game-dice-roll.component.html',
    styleUrl: './page-game-dice-roll.component.scss',
})
export class PageGameDiceRollComponent implements OnInit {
    constructor(private pageGameDiceRollSerivce: PageGameDiceRollService) {}

    sliderValue: number = 50; // initial win chance
    multiplier: number = 0;
    winChance: number = 0;
    rollOver: boolean = true;
    betAmount: number = 10;

    ngOnInit(): void {
        this.updateUI();
    }

    updateUI(): void {
        this.updateSliderBackground();
        this.updateMultiplier();
        this.updateWinChance();
    }

    updateSliderBackground(): void {
        const slider = document.querySelector('.slider') as HTMLInputElement;
        const value =
            ((this.sliderValue - Number(slider.min)) /
                (Number(slider.max) - Number(slider.min))) *
            100;
        if (this.rollOver) {
            slider.style.background = `linear-gradient(to right, red ${value}%, green ${value}%)`;
        } else {
            slider.style.background = `linear-gradient(to right, green ${value}%, red ${value}%)`;
        }
        this.updateMultiplier();
        this.updateWinChance();
    }

    updateWinChance(): void {
        this.winChance = this.rollOver
            ? 100 - this.sliderValue
            : this.sliderValue;
    }

    updateMultiplier(): void {
        const multiplier = this.rollOver
            ? 100 - this.sliderValue
            : this.sliderValue;
        this.multiplier = this.diceRollPayoutMultiplier(multiplier / 100);
    }

    diceRollPayoutMultiplier(targetChance: number): number {
        const houseEdge = 1.0 - 0.01;
        const payoutMultiplier = 1 / targetChance;
        return payoutMultiplier * houseEdge;
    }

    toggleRollOver(): void {
        this.rollOver = !this.rollOver;
        this.sliderValue = 100 - this.sliderValue;
        this.updateUI();
    }

    increaseMultiplierValue(): void {
        if (this.sliderValue < 98 && this.rollOver) {
            this.sliderValue += 1;
            this.updateUI();
        }

        if (this.sliderValue < 98 && !this.rollOver) {
            this.sliderValue -= 1;
            this.updateUI();
        }
    }

    decreaseMultiplierValue(): void {
        if (this.sliderValue > 2 && this.rollOver) {
            this.sliderValue -= 1;
            this.updateUI();
        }

        if (this.sliderValue > 2 && !this.rollOver) {
            this.sliderValue += 1;
            this.updateUI();
        }
    }

    increaseWinChanceValue(): void {
        if (this.sliderValue > 2 && this.rollOver) {
            this.sliderValue -= 1;
            this.updateUI();
        }

        if (this.sliderValue > 2 && !this.rollOver) {
            this.sliderValue += 1;
            this.updateUI();
        }
    }

    decreaseWinChanceValue(): void {
        if (this.sliderValue < 98 && this.rollOver) {
            this.sliderValue += 1;
            this.updateUI();
        }

        if (this.sliderValue < 98 && !this.rollOver) {
            this.sliderValue -= 1;
            this.updateUI();
        }
    }

    updateBetAmount(): void {
        this.betAmount;
    }

    onBet(): void {
        const condition = this.rollOver ? 'BELOW' : 'ABOVE';
        this.pageGameDiceRollSerivce.fetchPlayerId().subscribe({
            next: (playerId) => {
                if (!playerId) {
                    console.error('No player ID found');
                    return;
                }
                const betData = {
                    betAmount: this.betAmount,
                    targetChance: this.winChance,
                    condition: condition,
                };

                this.pageGameDiceRollSerivce.placeBet(playerId.playerId, betData).subscribe({
                    next: (response) =>
                        console.log('Bet placed successfully', response),
                    error: (error) => console.error('Error placing bet', error),
                    complete:() => {}
                });
            },
            error: (error) => console.error('Error fetching player ID', error),
            complete:() => {},
        });
    }
}
