import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-land-selling-platform-banner',
  templateUrl: './land-selling-platform-banner.component.html',
  styleUrls: ['./land-selling-platform-banner.component.scss']
})
export class LandSellingPlatformBannerComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
