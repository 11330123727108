<app-multipage-navbar></app-multipage-navbar>

<!-- Page Title -->
<div class="page-banner-area" style="background-image: url(assets-app/images/page-banner-bg.jpg);">
    <div class="container">
        <div class="page-banner-content">
            <ul>
                <li>
                    <a routerLink="/">
                        HOME
                    </a>
                </li>
                <li>
                    <span>ABOUT US</span>
                </li>
            </ul>
            <h2>About Us</h2>
            <img src="assets-app/images/page-star.png" class="star" alt="star">
        </div>
    </div>
    <img src="assets-app/images/banner/banner-shape-1.png" class="shape shape-1" alt="shape">
    <img src="assets-app/images/banner/banner-shape-2.png" class="shape shape-2" alt="shape">
</div>

<app-about-us></app-about-us>

<app-how-it-works></app-how-it-works>

<app-partner></app-partner>

<app-what-will-get></app-what-will-get>

<app-earn-rewards></app-earn-rewards>

<app-projects></app-projects>