<!-- Partner for Metavarse Launchpad -->
<div
    class="partner-area ptb-120 bg-140c2dr"
    [ngClass]="{'d-none': router.url === '/index-2' || router.url === '/index-3' || router.url === '/index-4' || router.url === '/index-5' || router.url === '/dex-offering' || router.url === '/gaming-metaverse' || router.url === '/nft-centric-metaverse' || router.url === '/land-selling-platform'}"
>
    <div class="container">
        <div class="partner-slides">
            <owl-carousel-o [options]="partnerSlides">
                <ng-template carouselSlide>
                    <div class="partner-item">
                        <img src="assets-app/images/partners/partner-1.png" alt="partner-image">
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="partner-item">
                        <img src="assets-app/images/partners/partner-2.png" alt="partner-image">
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="partner-item">
                        <img src="assets-app/images/partners/partner-3.png" alt="partner-image">
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="partner-item">
                        <img src="assets-app/images/partners/partner-4.png" alt="partner-image">
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="partner-item">
                        <img src="assets-app/images/partners/partner-5.png" alt="partner-image">
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="partner-item">
                        <img src="assets-app/images/partners/partner-2.png" alt="partner-image">
                    </div>
                </ng-template>
            </owl-carousel-o>
        </div>
    </div>
</div>

<!-- Partner for Dex Offering & Gaming Metaverse -->
<div
    class="partner-area pt-0 bg-140c2dr d-none"
    [ngClass]="{'d-block': router.url === '/index-2' || router.url === '/index-3' || router.url === '/dex-offering' || router.url === '/gaming-metaverse'}"
>
    <div class="container">
        <div
            class="partner-wrap"
            [ngClass]="{'mt-0': router.url === '/index-3' || router.url === '/gaming-metaverse'}"
        >
            <div class="partner-slides">
                <owl-carousel-o [options]="partnerSlides">
                    <ng-template carouselSlide>
                        <div class="partner-item">
                            <img src="assets-app/images/partners/partner-7.png" alt="partner-image">
                        </div>
                    </ng-template>
                    <ng-template carouselSlide>
                        <div class="partner-item">
                            <img src="assets-app/images/partners/partner-8.png" alt="partner-image">
                        </div>
                    </ng-template>
                    <ng-template carouselSlide>
                        <div class="partner-item">
                            <img src="assets-app/images/partners/partner-9.png" alt="partner-image">
                        </div>
                    </ng-template>
                    <ng-template carouselSlide>
                        <div class="partner-item">
                            <img src="assets-app/images/partners/partner-10.png" alt="partner-image">
                        </div>
                    </ng-template>
                    <ng-template carouselSlide>
                        <div class="partner-item">
                            <img src="assets-app/images/partners/partner-11.png" alt="partner-image">
                        </div>
                    </ng-template>
                    <ng-template carouselSlide>
                        <div class="partner-item">
                            <img src="assets-app/images/partners/partner-12.png" alt="partner-image">
                        </div>
                    </ng-template>
                </owl-carousel-o>
            </div>
        </div>
    </div>
</div>

<!-- Partner for NFT Centric Metaverse & 3D Land Selling Platform -->
<div
    id="partner"
    class="partner-area pt-120 d-none"
    [ngClass]="{'d-block': router.url === '/index-4' || router.url === '/index-5' || router.url === '/nft-centric-metaverse' || router.url === '/land-selling-platform'}"
>
    <div class="container">
        <div class="section-title">
            <span class="top-title">OUR PARTNERS</span>
            <h2>Our Partners & Investors</h2>
        </div>
        <div class="partner-inner">
            <div class="row justify-content-center">
                <div class="col-lg-2 col-6 col-sm-4 col-md-3">
                    <div class="partner-item">
                        <img src="assets-app/images/partners/partner-7.png" alt="partner-image">
                    </div>
                </div>
                <div class="col-lg-2 col-6 col-sm-4 col-md-3">
                    <div class="partner-item">
                        <img src="assets-app/images/partners/partner-8.png" alt="partner-image">
                    </div>
                </div>
                <div class="col-lg-2 col-6 col-sm-4 col-md-3">
                    <div class="partner-item">
                        <img src="assets-app/images/partners/partner-9.png" alt="partner-image">
                    </div>
                </div>
                <div class="col-lg-2 col-6 col-sm-4 col-md-3">
                    <div class="partner-item">
                        <img src="assets-app/images/partners/partner-10.png" alt="partner-image">
                    </div>
                </div>
                <div class="col-lg-2 col-6 col-sm-4 col-md-3">
                    <div class="partner-item">
                        <img src="assets-app/images/partners/partner-11.png" alt="partner-image">
                    </div>
                </div>
                <div class="col-lg-2 col-6 col-sm-4 col-md-3">
                    <div class="partner-item">
                        <img src="assets-app/images/partners/partner-12.png" alt="partner-image">
                    </div>
                </div>
                <div class="col-lg-2 col-6 col-sm-4 col-md-3">
                    <div class="partner-item">
                        <img src="assets-app/images/partners/partner-13.png" alt="partner-image">
                    </div>
                </div>
                <div class="col-lg-2 col-6 col-sm-4 col-md-3">
                    <div class="partner-item">
                        <img src="assets-app/images/partners/partner-14.png" alt="partner-image">
                    </div>
                </div>
                <div class="col-lg-2 col-6 col-sm-4 col-md-3">
                    <div class="partner-item">
                        <img src="assets-app/images/partners/partner-15.png" alt="partner-image">
                    </div>
                </div>
                <div class="col-lg-2 col-6 col-sm-4 col-md-3">
                    <div class="partner-item">
                        <img src="assets-app/images/partners/partner-16.png" alt="partner-image">
                    </div>
                </div>
                <div class="col-lg-2 col-6 col-sm-4 col-md-3">
                    <div class="partner-item">
                        <img src="assets-app/images/partners/partner-17.png" alt="partner-image">
                    </div>
                </div>
                <div class="col-lg-2 col-6 col-sm-4 col-md-3">
                    <div class="partner-item">
                        <img src="assets-app/images/partners/partner-18.png" alt="partner-image">
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>