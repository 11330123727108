import { computed, Injectable, signal } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Observable, Subject } from 'rxjs';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class GameService {

  private availableChipSig = signal('0.00')
  availableChip = computed(() => this.availableChipSig())
  
  constructor(
    private http: HttpClient,
    ) {}

  getCheckinData(): Observable<any> {
    const jwt = localStorage.getItem('btfd-authenticationtoken')
    const headers = new HttpHeaders().set('Authorization', 'Bearer ' + jwt)
    return this.http.get(environment.apiUrl + '/checkin', { headers })
  }

  postSignToday(transactionHash: string): Observable<any> {
    return this.http.post(environment.apiUrl + '/checkin', { 'transactionHash': transactionHash });
  }

  getCheckinSign(): Observable<any> {
    return this.http.get(environment.apiUrl + '/checkin/sign');
  }
  
  getPlayerCurrentEnergy(): Observable<any> {
    return this.http.get(environment.apiUrl + `/player-current-energy`);
  }

  getPlayerChip() {
    return this.http.get(environment.apiUrl + `/player-chip`);
  }

  async getAvailableChip() {
    this.getPlayerChip().subscribe((response) => {
      console.log(response)
      this.setChip(Number(response).toFixed(2))
    }, (error) => {
      console.log(error);
    });
  }

  setChip(value: string) {
    this.availableChipSig.set(value)
  }

  get availableChipValue(): string {
    return this.availableChip();
  }
}