import { CommonModule } from '@angular/common';
import { AfterViewInit, Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { NgxLoadingModule } from 'ngx-loading';
import { lastValueFrom } from 'rxjs';
import { GameService } from 'src/app/service/game.service';
import { Web3Service } from 'src/app/web3/web3.service';

export interface DailyCheckinDTO {
  date: Date
  day: number
  isDateToday: boolean
  isClaimed: boolean
  rewards: DailyCheckinRewardDTO[]
}

export interface DailyCheckinRewardDTO {
  itemId: number
  itemCode: string
  itemAmount: number
}

@Component({
  selector: 'app-page-checkin',
  standalone: true,
  imports: [CommonModule, NgxLoadingModule],
  templateUrl: './page-checkin.component.html',
  styleUrl: './page-checkin.component.scss',
  providers: [NgbModalConfig, NgbModal],
})
export class PageCheckinComponent {
  @ViewChild('modalCheckinReward') modalCheckinReward!: TemplateRef<any>;
  dailyCheckin: DailyCheckinDTO[] | undefined

  loading: boolean = false

  constructor(
    private gameService: GameService,
    private web3Service: Web3Service,
    private modalService: NgbModal,
  ) {}

  ngAfterViewInit(): void {
    this.retrieveCheckinData()
    this.openModal()
  }

  openModal() {
    this.modalService.dismissAll()
    this.modalService.open(this.modalCheckinReward, { centered: true, size: 'lg', windowClass: 'modal-checkin-reward' })
  }

  async retrieveCheckinData() {
    if (!this.web3Service.isLoggedIn()) return
    const dailyCheckinDTO: DailyCheckinDTO[] = await lastValueFrom(this.gameService.getCheckinData())
    this.dailyCheckin = dailyCheckinDTO
    console.log(dailyCheckinDTO)
  }

  async claimReward(reward: number) {
    const isValidNetwork = await this.web3Service.checkAndHandleNetworkSwitch()
    if (!isValidNetwork) {
      this.modalService.dismissAll()
      return
    }

    this.loading = true
    // document.querySelector('body')?.classList.add('loading');
    let transactionHash;
    
    try {
      const txnHash = await this.web3Service.getCheckinSign()
      //TODO: increase chip amount
      if (!txnHash || !this.dailyCheckin) {
        this.loading = false
        return
      }

      this.dailyCheckin[reward].isClaimed = true
    } catch (e) {
      //TODO: Handle error/user cancel
    }

    this.loading = false
  }
}
