import { Component, inject } from '@angular/core';
import { CommonHomeBannerComponent } from '../common-home-banner/common-home-banner.component';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { PageHomeService } from './page-home.service';
import { CommonModule } from '@angular/common';

@Component({
    selector: 'app-page-home',
    standalone: true,
    imports: [CommonHomeBannerComponent, CommonModule],
    templateUrl: './page-home.component.html',
    styleUrl: './page-home.component.scss',
})
export class PageHomeComponent {
    protected httpClient = inject(HttpClient);
    protected pageHomeService = inject(PageHomeService);

    constructor(private router: Router) {}

    navigateToCT() {
        this.router.navigate(['/btfd-game/ct']);
    }

    navigateToDinosaur() {
        this.router.navigate(['/btfd-game/dinosaur']);
    }


    page = 0;
    maxPage = 0;
    selfPosition: any;
    positions: any[] = [];
    showLoadMore: boolean = false;

    ngOnInit(): void {
        this.loadData();
    }

    loadData() {
        this.pageHomeService.fetchSelfPosition().subscribe({
            next: (response) => {
                this.selfPosition = response.body;
            },
            error: (error) => {
                //
            },
            complete: () => {
                //
            },
        });

        this.pageHomeService.fetchLeaderboard(this.page).subscribe({
            next: (response) => {
                const maxSize = response.headers.get('X-Total-Count');
                if (maxSize) {
                    const _maxSize = parseInt(maxSize);
                    const size = 10;
                    if (_maxSize > 10) {
                        this.showLoadMore = true;
                    }

                    this.maxPage = Math.floor(_maxSize / size);
                }

                this.positions.push(...response.body);
            },
            error: (error) => {
                //
            },
            complete: () => {
                //
            },
        });
    }

    loadMore() {
        if (this.page < this.maxPage) {
            this.page++;
            this.loadData();
        }
    }

    hasMoreItems(): boolean {
        return this.showLoadMore;
    }

    fetchLeaderboard(page = 0) {
        const params = {
            'seasonId.equals': 1,
            page,
        };

        return this.httpClient.get<any>(environment.apiUrl + `/season-rank`, {
            params,
            observe: 'response',
        });
    }

    fetchSelfPosition() {
        const params = {
            'seasonId.equals': 1,
        };

        return this.httpClient.get<any>(
            environment.apiUrl + `/season-rank/player`,
            { params, observe: 'response' }
        );
    }
}
