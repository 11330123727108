<app-multipage-navbar></app-multipage-navbar>

<!-- Page Title -->
<div class="page-banner-area" style="background-image: url(assets-app/images/page-banner-bg.jpg);">
    <div class="container">
        <div class="page-banner-content">
            <ul>
                <li>
                    <a routerLink="/">
                        HOME
                    </a>
                </li>
                <li>
                    <span>PROJECT DETAILS</span>
                </li>
            </ul>
            <h2>Project Details</h2>
            <img src="assets-app/images/page-star.png" class="star" alt="star">
        </div>
    </div>
    <img src="assets-app/images/banner/banner-shape-1.png" class="shape shape-1" alt="shape">
    <img src="assets-app/images/banner/banner-shape-2.png" class="shape shape-2" alt="shape">
</div>

<!-- Projects Details -->
<div class="projects-details-area ptb-120">
    <div class="container">
        <div class="featured-auction-bg">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-5">
                    <div class="featured-auction-info-wrap d-flex align-items-center">
                        <img src="assets-app/images/featured-auction-img.jpg" alt="featured-auction-img">
                        <div class="featured-auction-info">
                            <h3>Reign Of Terror</h3>
                            <span>Price (DDO) = 0.05 BUSD</span>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 col-md-7">
                    <div class="timer-wrap d-flex align-items-center justify-content-end">
                        <div>
                            <span>Sale End In:</span>
                            <div class="timer flex-wrap d-flex justify-content-center">
                                <div class="align-items-center flex-column d-flex justify-content-center">
                                    <span class="days">{{days}}</span>
                                    Days
                                </div>
                                <div class="align-items-center flex-column d-flex justify-content-center">
                                    <span class="hours">{{hours}}</span>
                                    Hours
                                </div>
                                <div class="align-items-center flex-column d-flex justify-content-center">
                                    <span class="minutes">{{minutes}}</span>
                                    Mins
                                </div>
                                <div class="align-items-center flex-column d-flex justify-content-center">
                                    <span class="seconds">{{seconds}}</span>
                                    Secs
                                </div>
                            </div>
                        </div>
                        <img src="assets-app/images/icon/icon-7.svg" alt="icon">
                    </div>
                </div>
            </div>
            <div class="featured-auction-bar">
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span class="bg-color"></span>
                <span class="bg-color"></span>
                <span class="bg-color"></span>
                <span class="bg-color"></span>
                <span class="bg-color"></span>
                <span class="bg-color"></span>
                <span class="bg-color"></span>
                <span class="bg-color"></span>
                <span class="bg-color"></span>
                <span class="bg-color"></span>
                <span class="bg-color"></span>
                <span class="bg-color"></span>
                <span class="bg-color"></span>
                <span class="bg-color"></span>
                <span class="bg-color"></span>
                <span class="bg-color"></span>
                <span class="bg-color"></span>
                <span class="bg-color"></span>
            </div>
            <ul class="total-raised d-flex justify-content-between">
                <li>Total raised: 42,300,000 BUSD (74%)</li>
                <li>Allocations: 1000 BUSD Max</li>
                <li>Targeted raise: 320,000,000 BUSD</li>
            </ul>
        </div>
        <div class="details-info-wrap">
            <div class="row">
                <div class="col-lg-6 col-md-6">
                    <div class="project-details-info">
                        <h3>Reign Of Terror</h3>
                        <ul>
                            <li>
                                Token Distribution
                                <span>Date BUSD</span>
                            </li>
                            <li>
                                Min. Allocation
                                <span>0.03 BUSD</span>
                            </li>
                            <li>
                                Max. Allocation
                                <span>2351.03 BUSD</span>
                            </li>
                            <li>
                                Token Price
                                <span>1 BUSD = 325.35 SIDUS</span>
                            </li>
                            <li>
                                Access Type
                                <span>Private</span>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="col-lg-6 col-md-6">
                    <div class="project-details-info">
                        <h3>Token Info</h3>
                        <ul>
                            <li>
                                Token Name
                                <span>Reign Of Terror</span>
                            </li>
                            <li>
                                Token Symbol
                                <span>ROT</span>
                            </li>
                            <li>
                                Decimals
                                <span>22</span>
                            </li>
                            <li>
                                Address
                                <span>22cf3459gf3d009</span>
                            </li>
                            <li>
                                Total Sypply
                                <span>235,342,889.00 ROT</span>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <div class="projects-wrap-tabs">
            <ul class="nav-tabset">
                <li class="nav-tab" [ngClass]="{'active': currentTab === 'tab1'}">
                    <span (click)="switchTab($event, 'tab1')">
                        Project Summary
                    </span>
                </li>
                <li class="nav-tab" [ngClass]="{'active': currentTab === 'tab2'}">
                    <span (click)="switchTab($event, 'tab2')">
                        Schedule
                    </span>
                </li>
                <li class="nav-tab" [ngClass]="{'active': currentTab === 'tab3'}">
                    <span (click)="switchTab($event, 'tab3')">
                        Comparison
                    </span>
                </li>
                <li class="nav-tab" [ngClass]="{'active': currentTab === 'tab4'}">
                    <span (click)="switchTab($event, 'tab4')">
                        Tokenomics
                    </span>
                </li>
                <li class="nav-tab" [ngClass]="{'active': currentTab === 'tab5'}">
                    <span (click)="switchTab($event, 'tab5')">
                        Roadmap
                    </span>
                </li>
                <li class="nav-tab" [ngClass]="{'active': currentTab === 'tab6'}">
                    <span (click)="switchTab($event, 'tab6')">
                        Team
                    </span>
                </li>
                <li class="nav-tab" [ngClass]="{'active': currentTab === 'tab7'}">
                    <span (click)="switchTab($event, 'tab7')">
                        Investors
                    </span>
                </li>
            </ul>
            <div class="tabs-container">
                <div class="pane" id="tab1" *ngIf="currentTab === 'tab1'">
                    <div class="project-summary step-1">
                        <h3>Project Summary</h3>
                        <p>A gamified marketplace dedicated to the metaverse and gaming assets. The Metaverse marketplace allows users to trade, auction, and rent virtual ownership assets from various metaverse and gaming projects.</p>
                        <p>A gamified marketplace dedicated to the metaverse and gaming assets. The Metaverse marketplace allows users to trade, auction, and rent virtual ownership.</p>
                        <div class="project-summary-img">
                            <img src="assets-app/images/projects/project-9.jpg" alt="projects-image">
                        </div>
                        <p>A gamified marketplace dedicated to the metaverse and gaming assets. The Metaverse marketplace allows users to trade, auction, and rent virtual ownership assets from various metaverse and gaming projects.</p>
                        <p>A gamified marketplace dedicated to the metaverse and gaming assets. The Metaverse marketplace allows users to trade, auction, and rent virtual ownership.</p>
                    </div>
                    <div class="project-summary step-2">
                        <h3>Schedule</h3>
                        <p>A gamified marketplace dedicated to the metaverse and gaming assets. The Metaverse marketplace allows users to trade, auction, and rent virtual ownership assets from various metaverse and gaming projects.</p>
                        <p>A gamified marketplace dedicated to the metaverse and gaming assets. The Metaverse marketplace allows users to trade, auction, and rent virtual ownership.</p>
                    </div>
                    <div class="project-summary step-3">
                        <h3>Comparison</h3>
                        <p>A gamified marketplace dedicated to the metaverse and gaming assets. The Metaverse marketplace allows users to trade, auction, and rent virtual ownership assets from various metaverse and gaming projectsA gamified marketplace dedicated to the meta verse and gaming assets. The Metaverse marketplace allows users to trade, and rent virtual ownership..</p>
                    </div>
                    <div class="project-summary step-4">
                        <h3>Tokenomics</h3>
                        <p>A gamified marketplace dedicated to the metaverse and gaming assets. The Metaverse marketplace allows users to trade, auction, and rent virtual ownership assets from various metaverse and gaming projects.</p>
                        <div class="single-token-list">
                            <ul class="d-flex justify-content-between">
                                <li>
                                    <h4>15% <sub>Team</sub></h4>
                                    <p>0% TGE 5% unlocked each month after</p>
                                </li>
                                <li class="text-end">
                                    <h4>15,000,000,000</h4>
                                    <p>Tokens</p>
                                </li>
                            </ul>
                        </div>
                        <div class="single-token-list">
                            <ul class="d-flex justify-content-between">
                                <li>
                                    <h4>12% <sub>Strategic Partners & Advisors</sub></h4>
                                    <p>0% TGE 5% unlocked each month after</p>
                                </li>
                                <li class="text-end">
                                    <h4>21,500,000,000</h4>
                                    <p>Tokens</p>
                                </li>
                            </ul>
                        </div>
                        <div class="single-token-list">
                            <ul class="d-flex justify-content-between">
                                <li>
                                    <h4>21.5% <sub>Exchange Listing & Liquidify</sub></h4>
                                    <p>20% TGE 10% unlocked each month after</p>
                                </li>
                                <li class="text-end">
                                    <h4>12,000,000,000</h4>
                                    <p>Tokens</p>
                                </li>
                            </ul>
                        </div>
                        <div class="single-token-list">
                            <ul class="d-flex justify-content-between">
                                <li>
                                    <h4>24.5% <sub>Staking, In-game Rewards & Development</sub></h4>
                                    <p>20% TGE 10% unlocked each month after</p>
                                </li>
                                <li class="text-end">
                                    <h4>24,500,000,000</h4>
                                    <p>Tokens</p>
                                </li>
                            </ul>
                        </div>
                        <div class="single-token-list">
                            <ul class="d-flex justify-content-between">
                                <li>
                                    <h4>16% <sub>Company Reserves</sub></h4>
                                    <p>10% TGE 5% unlocked each month after</p>
                                </li>
                                <li class="text-end">
                                    <h4>500,000,000</h4>
                                    <p>Tokens</p>
                                </li>
                            </ul>
                        </div>
                        <div class="single-token-list">
                            <ul class="d-flex justify-content-between">
                                <li>
                                    <h4>11% <sub>Marketing</sub></h4>
                                    <p>0% TGE 5% unlocked each month after</p>
                                </li>
                                <li class="text-end">
                                    <h4>3,000,000,000</h4>
                                    <p>Tokens</p>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="project-summary step-5">
                        <h3>Roadmap</h3>
                        <p>A gamified marketplace dedicated to the metaverse and gaming assets. The Metaverse marketplace allows users to trade, auction, and rent virtual ownership assets from various metaverse and gaming projects.</p>
                    </div>
                </div>
                <div class="pane" id="tab2" *ngIf="currentTab === 'tab2'">
                    <div class="row">
                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <div class="single-features style-two">
                                <div class="d-flex align-items-center">
                                    <img src="assets-app/images/icon/icon-1.svg" alt="icon">
                                    <h3 class="ms-3">
                                        <a routerLink="/project-details">Fueling The Metaverse</a>
                                    </h3>
                                </div>
                                <p>The Metaverse is the next generation of the internet. As one of its earliest explorers, you will.</p>
                                <a routerLink="/project-details" class="read-more">READ MORE</a>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <div class="single-features style-two">
                                <div class="d-flex align-items-center">
                                    <img src="assets-app/images/icon/icon-2.svg" alt="icon">
                                    <h3 class="ms-3">
                                        <a routerLink="/project-details">Interconnected Economies</a>
                                    </h3>
                                </div>
                                <p>The Metaverse is the next generation of the internet. As one of its earliest explorers, you will.</p>
                                <a routerLink="/project-details" class="read-more">READ MORE</a>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <div class="single-features style-two">
                                <div class="d-flex align-items-center">
                                    <img src="assets-app/images/icon/icon-3.svg" alt="icon">
                                    <h3 class="ms-3">
                                        <a routerLink="/project-details">Non-fungible assets</a>
                                    </h3>
                                </div>
                                <p>The Metaverse is the next generation of the internet. As one of its earliest explorers, you will.</p>
                                <a routerLink="/project-details" class="read-more">READ MORE</a>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <div class="single-features style-two">
                                <div class="d-flex align-items-center">
                                    <img src="assets-app/images/icon/icon-4.svg" alt="icon">
                                    <h3 class="ms-3">
                                        <a routerLink="/project-details">Incubation</a>
                                    </h3>
                                </div>
                                <p>The Metaverse is the next generation of the internet. As one of its earliest explorers, you will.</p>
                                <a routerLink="/project-details" class="read-more">READ MORE</a>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <div class="single-features style-two">
                                <div class="d-flex align-items-center">
                                    <img src="assets-app/images/icon/icon-5.svg" alt="icon">
                                    <h3 class="ms-3">
                                        <a routerLink="/project-details">Initial Metaverse Offering </a>
                                    </h3>
                                </div>
                                <p>The Metaverse is the next generation of the internet. As one of its earliest explorers, you will.</p>
                                <a routerLink="/project-details" class="read-more">READ MORE</a>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <div class="single-features style-two">
                                <div class="d-flex align-items-center">
                                    <img src="assets-app/images/icon/icon-6.svg" alt="icon">
                                    <h3 class="ms-3">
                                        <a routerLink="/project-details">Next Tier Metaverse Projects</a>
                                    </h3>
                                </div>
                                <p>The Metaverse is the next generation of the internet. As one of its earliest explorers, you will.</p>
                                <a routerLink="/project-details" class="read-more">READ MORE</a>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="pane" id="tab3" *ngIf="currentTab === 'tab3'">
                    <div class="statistics-bar">
                        <div class="bg-1 mb-20 d-flex justify-content-between align-items-center">
                            <span>Land NFT Minted</span>
                            <h3>486,822</h3>
                        </div>
                        <div class="bg-2 mb-20 d-flex justify-content-between align-items-center">
                            <span><i class="ri-information-line"></i> Commission Pool</span>
                            <span>486,822</span>
                        </div>
                        <div class="bg-2 mb-20 d-flex justify-content-between align-items-center">
                            <span><i class="ri-information-line"></i> Charity Pool</span>
                            <span>2,066,343 USDT</span>
                        </div>
                        <div class="all-flags bg-2 mb-20 d-flex justify-content-between align-items-center">
                            <div class="expensive">
                                <span>Most Expensive Countries</span>
                            </div>
                            <div class="flag">
                                <img src="assets-app/images/flag/flag-1.png" alt="flag">
                                <p>45.54 USDT/Tile</p>
                            </div>
                            <div class="flag">
                                <img src="assets-app/images/flag/flag-2.png" alt="flag">
                                <p>45.54 USDT/Tile</p>
                            </div>
                            <div class="flag">
                                <img src="assets-app/images/flag/flag-3.png" alt="flag">
                                <p>45.54 USDT/Tile</p>
                            </div>
                        </div>
                        <div class="all-flags bg-2 mb-20 d-flex justify-content-between align-items-center">
                            <div class="expensive">
                                <span>Most Popular Countries</span>
                            </div>
                            <div class="flag">
                                <img src="assets-app/images/flag/flag-4.png" alt="flag">
                                <p>45.54 USDT/Tile</p>
                            </div>
                            <div class="flag">
                                <img src="assets-app/images/flag/flag-5.png" alt="flag">
                                <p>45.54 USDT/Tile</p>
                            </div>
                            <div class="flag">
                                <img src="assets-app/images/flag/flag-6.png" alt="flag">
                                <p>45.54 USDT/Tile</p>
                            </div>
                        </div>
                        <p class="total">Total Land Sold</p>
                        <div class="reign-bar text-center">
                            <span class="bg-color"></span>
                            <span class="bg-color"></span>
                            <span class="bg-color"></span>
                            <span class="bg-color"></span>
                            <span class="bg-color"></span>
                            <span class="bg-color"></span>
                            <span class="bg-color"></span>
                            <span class="bg-color"></span>
                            <span class="bg-color"></span>
                            <span class="bg-color"></span>
                            <span class="bg-color"></span>
                            <span class="bg-color"></span>
                            <span class="bg-color"></span>
                            <span class="bg-color"></span>
                            <span class="bg-color"></span>
                            <span class="bg-color"></span>
                            <span class="bg-color"></span>
                            <span class="bg-color"></span>
                            <span class="bg-color"></span>
                            <span class="bg-color"></span>
                            <span class="bg-color"></span>
                            <span class="bg-color"></span>
                            <span class="bg-color"></span>
                            <span class="bg-color"></span>
                            <span class="bg-color"></span>
                            <span class="bg-color"></span>
                            <span class="bg-color"></span>
                            <span class="bg-color"></span>
                            <span class="bg-color"></span>
                        </div>
                        <ul class="milion-list d-flex justify-content-between">
                            <li>0M</li>
                            <li>3M</li>
                            <li>6M</li>
                            <li>9M</li>
                            <li>12M</li>
                            <li>15M</li>
                            <li>18M</li>
                            <li>21M</li>
                            <li>24M</li>
                            <li><span>Moon</span></li>
                        </ul>
                    </div>
                </div>
                <div class="pane" id="tab4" *ngIf="currentTab === 'tab4'">
                    <div class="row align-items-center">
                        <div class="col-lg-6 col-md-12">
                            <div class="token-image">
                                <img src="assets-app/images/token-img.png" alt="token-image">
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-12">
                            <div class="single-token-list">
                                <ul class="d-flex justify-content-between">
                                    <li>
                                        <h4>15% <sub>Team</sub></h4>
                                        <p>0% TGE 5% unlocked each month after</p>
                                    </li>
                                    <li class="text-end">
                                        <h4>15,000,000,000</h4>
                                        <p>Tokens</p>
                                    </li>
                                </ul>
                            </div>
                            <div class="single-token-list">
                                <ul class="d-flex justify-content-between">
                                    <li>
                                        <h4>12% <sub>Strategic Partners & Advisors</sub></h4>
                                        <p>0% TGE 5% unlocked each month after</p>
                                    </li>
                                    <li class="text-end">
                                        <h4>21,500,000,000</h4>
                                        <p>Tokens</p>
                                    </li>
                                </ul>
                            </div>
                            <div class="single-token-list">
                                <ul class="d-flex justify-content-between">
                                    <li>
                                        <h4>21.5% <sub>Exchange Listing & Liquidify</sub></h4>
                                        <p>20% TGE 10% unlocked each month after</p>
                                    </li>
                                    <li class="text-end">
                                        <h4>12,000,000,000</h4>
                                        <p>Tokens</p>
                                    </li>
                                </ul>
                            </div>
                            <div class="single-token-list">
                                <ul class="d-flex justify-content-between">
                                    <li>
                                        <h4>24.5% <sub>Staking, In-game Rewards & Development</sub></h4>
                                        <p>20% TGE 10% unlocked each month after</p>
                                    </li>
                                    <li class="text-end">
                                        <h4>24,500,000,000</h4>
                                        <p>Tokens</p>
                                    </li>
                                </ul>
                            </div>
                            <div class="single-token-list">
                                <ul class="d-flex justify-content-between">
                                    <li>
                                        <h4>16% <sub>Company Reserves</sub></h4>
                                        <p>10% TGE 5% unlocked each month after</p>
                                    </li>
                                    <li class="text-end">
                                        <h4>500,000,000</h4>
                                        <p>Tokens</p>
                                    </li>
                                </ul>
                            </div>
                            <div class="single-token-list">
                                <ul class="d-flex justify-content-between">
                                    <li>
                                        <h4>11% <sub>Marketing</sub></h4>
                                        <p>0% TGE 5% unlocked each month after</p>
                                    </li>
                                    <li class="text-end">
                                        <h4>3,000,000,000</h4>
                                        <p>Tokens</p>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="pane" id="tab5" *ngIf="currentTab === 'tab5'">
                    <div class="row">
                        <div class="col-lg-6 col-md-6">
                            <div class="single-roadmap">
                                <div class="date">
                                    <span>2024</span>
                                    <h2>Q1</h2>
                                </div>
                                <ul>
                                    <li>Launch Strategic Round</li>
                                    <li>Launch Public Sale round under IDO and be listed on exchanges right after IDO</li>
                                    <li>Launch program on staking BNU to earn NFT representing placements in 3D virtual reality space</li>
                                    <li>Launch the NFT exchange</li>
                                </ul>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6">
                            <div class="single-roadmap">
                                <div class="date">
                                    <span>2024</span>
                                    <h2>Q3</h2>
                                </div>
                                <ul>
                                    <li>Introduce the collection of NFT for rare artworks and conduct auctions</li>
                                    <li>Launch the Staking NFT system</li>
                                    <li>Introduce the system supporting artworks, establish standard storage and preservation centers for artworks</li>
                                    <li>Build Mave NFT Marketplace</li>
                                </ul>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6">
                            <div class="single-roadmap">
                                <div class="date">
                                    <span>2024</span>
                                    <h2>Q4</h2>
                                </div>
                                <ul>
                                    <li>Demo 3D virtual exhibition</li>
                                    <li>Announce the extended ecosystems ByteNext Labs Build</li>
                                    <li>Build Mave Launchpad</li>
                                    <li>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Dicta non ab sed neque exercitationem? Veritatis</li>
                                </ul>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6">
                            <div class="single-roadmap">
                                <div class="date date-active">
                                    <span>2024</span>
                                    <h2>Q1</h2>
                                </div>
                                <ul>
                                    <li>Develop Yield Guild</li>
                                    <li>Continuously develop and promote Mave Launchpad</li>
                                    <li>Widen AvatarArt’s network</li>
                                    <li>Introduce the system supporting artworks, establish standard storage and preservation centers for artworks</li>
                                </ul>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6">
                            <div class="single-roadmap">
                                <div class="date date-active">
                                    <span>2024</span>
                                    <h2>Q2</h2>
                                </div>
                                <ul>
                                    <li>Develop Yield Guild</li>
                                    <li>Complete Mave Labs Ecosystem</li>
                                    <li>Develop global strategy</li>
                                </ul>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6">
                            <div class="single-roadmap">
                                <div class="date">
                                    <span>2024</span>
                                    <h2>Q1</h2>
                                </div>
                                <ul>
                                    <li>Launch Seed and Private Sale(Completed) </li>
                                    <li>Research Binance Smart Chain, NFT and AvatarArt</li>
                                    <li>Seed and Private Sale(Completed) </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="pane" id="tab6" *ngIf="currentTab === 'tab6'">
                    <div class="row justify-content-center">
                        <div class="col-lg-3 col-6 col-md-4">
                            <div class="single-team">
                                <img src="assets-app/images/team/team-1.png" alt="team-image">
                                <h3>Jane Ronan</h3>
                                <span>CEO & Co-founder</span>
                                <ul>
                                    <li>
                                        <a href="https://www.facebook.com/" target="_blank">
                                            <i class="ri-facebook-fill"></i>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="https://www.twitter.com/" target="_blank">
                                            <i class="ri-twitter-fill"></i>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="https://www.instagram.com/" target="_blank">
                                            <i class="ri-instagram-fill"></i>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="https://www.linkedin.com/" target="_blank">
                                            <i class="ri-linkedin-fill"></i>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div class="col-lg-3 col-6 col-md-4">
                            <div class="single-team">
                                <img src="assets-app/images/team/team-2.png" alt="team-image">
                                <h3>Victor James</h3>
                                <span>Director & Co-founder</span>
                                <ul>
                                    <li>
                                        <a href="https://www.facebook.com/" target="_blank">
                                            <i class="ri-facebook-fill"></i>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="https://www.twitter.com/" target="_blank">
                                            <i class="ri-twitter-fill"></i>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="https://www.instagram.com/" target="_blank">
                                            <i class="ri-instagram-fill"></i>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="https://www.linkedin.com/" target="_blank">
                                            <i class="ri-linkedin-fill"></i>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div class="col-lg-3 col-6 col-md-4">
                            <div class="single-team">
                                <img src="assets-app/images/team/team-3.png" alt="team-image">
                                <h3>Angela Carter</h3>
                                <span>Cheif operation Officer</span>
                                <ul>
                                    <li>
                                        <a href="https://www.facebook.com/" target="_blank">
                                            <i class="ri-facebook-fill"></i>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="https://www.twitter.com/" target="_blank">
                                            <i class="ri-twitter-fill"></i>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="https://www.instagram.com/" target="_blank">
                                            <i class="ri-instagram-fill"></i>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="https://www.linkedin.com/" target="_blank">
                                            <i class="ri-linkedin-fill"></i>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div class="col-lg-3 col-6 col-md-4">
                            <div class="single-team">
                                <img src="assets-app/images/team/team-4.png" alt="team-image">
                                <h3>John Smith</h3>
                                <span>Chief Metaverse Officer</span>
                                <ul>
                                    <li>
                                        <a href="https://www.facebook.com/" target="_blank">
                                            <i class="ri-facebook-fill"></i>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="https://www.twitter.com/" target="_blank">
                                            <i class="ri-twitter-fill"></i>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="https://www.instagram.com/" target="_blank">
                                            <i class="ri-instagram-fill"></i>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="https://www.linkedin.com/" target="_blank">
                                            <i class="ri-linkedin-fill"></i>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div class="col-lg-3 col-6 col-md-4">
                            <div class="single-team">
                                <img src="assets-app/images/team/team-5.png" alt="team-image">
                                <h3>Bella siran</h3>
                                <span>CTO & Co-founder</span>
                                <ul>
                                    <li>
                                        <a href="https://www.facebook.com/" target="_blank">
                                            <i class="ri-facebook-fill"></i>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="https://www.twitter.com/" target="_blank">
                                            <i class="ri-twitter-fill"></i>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="https://www.instagram.com/" target="_blank">
                                            <i class="ri-instagram-fill"></i>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="https://www.linkedin.com/" target="_blank">
                                            <i class="ri-linkedin-fill"></i>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div class="col-lg-3 col-6 col-md-4">
                            <div class="single-team">
                                <img src="assets-app/images/team/team-6.png" alt="team-image">
                                <h3>Jennifer Loren</h3>
                                <span>Social Media Lead</span>
                                <ul>
                                    <li>
                                        <a href="https://www.facebook.com/" target="_blank">
                                            <i class="ri-facebook-fill"></i>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="https://www.twitter.com/" target="_blank">
                                            <i class="ri-twitter-fill"></i>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="https://www.instagram.com/" target="_blank">
                                            <i class="ri-instagram-fill"></i>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="https://www.linkedin.com/" target="_blank">
                                            <i class="ri-linkedin-fill"></i>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div class="col-lg-3 col-6 col-md-4">
                            <div class="single-team">
                                <img src="assets-app/images/team/team-7.png" alt="team-image">
                                <h3>Ellon Marker</h3>
                                <span>Director Engineer</span>
                                <ul>
                                    <li>
                                        <a href="https://www.facebook.com/" target="_blank">
                                            <i class="ri-facebook-fill"></i>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="https://www.twitter.com/" target="_blank">
                                            <i class="ri-twitter-fill"></i>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="https://www.instagram.com/" target="_blank">
                                            <i class="ri-instagram-fill"></i>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="https://www.linkedin.com/" target="_blank">
                                            <i class="ri-linkedin-fill"></i>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div class="col-lg-3 col-6 col-md-4">
                            <div class="single-team">
                                <img src="assets-app/images/team/team-8.png" alt="team-image">
                                <h3>Tom Carter</h3>
                                <span>Lead Engineer</span>
                                <ul>
                                    <li>
                                        <a href="https://www.facebook.com/" target="_blank">
                                            <i class="ri-facebook-fill"></i>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="https://www.twitter.com/" target="_blank">
                                            <i class="ri-twitter-fill"></i>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="https://www.instagram.com/" target="_blank">
                                            <i class="ri-instagram-fill"></i>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="https://www.linkedin.com/" target="_blank">
                                            <i class="ri-linkedin-fill"></i>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="pane" id="tab7" *ngIf="currentTab === 'tab7'">
                    <div class="project-summary step-1">
                        <h3>Project Investors</h3>
                        <p>A gamified marketplace dedicated to the metaverse and gaming assets. The Metaverse marketplace allows users to trade, auction, and rent virtual ownership assets from various metaverse and gaming projects.</p>
                        <p>A gamified marketplace dedicated to the metaverse and gaming assets. The Metaverse marketplace allows users to trade, auction, and rent virtual ownership.</p>
                        <div class="project-summary-img">
                            <img src="assets-app/images/projects/project-9.jpg" alt="projects-image">
                        </div>
                        <p>A gamified marketplace dedicated to the metaverse and gaming assets. The Metaverse marketplace allows users to trade, auction, and rent virtual ownership assets from various metaverse and gaming projects.</p>
                        <p>A gamified marketplace dedicated to the metaverse and gaming assets. The Metaverse marketplace allows users to trade, auction, and rent virtual ownership.</p>
                    </div>
                    <div class="project-summary step-2">
                        <h3>Schedule</h3>
                        <p>A gamified marketplace dedicated to the metaverse and gaming assets. The Metaverse marketplace allows users to trade, auction, and rent virtual ownership assets from various metaverse and gaming projects.</p>
                        <p>A gamified marketplace dedicated to the metaverse and gaming assets. The Metaverse marketplace allows users to trade, auction, and rent virtual ownership.</p>
                    </div>
                    <div class="project-summary step-3">
                        <h3>Comparison</h3>
                        <p>A gamified marketplace dedicated to the metaverse and gaming assets. The Metaverse marketplace allows users to trade, auction, and rent virtual ownership assets from various metaverse and gaming projectsA gamified marketplace dedicated to the meta verse and gaming assets. The Metaverse marketplace allows users to trade, and rent virtual ownership..</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>