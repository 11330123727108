// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  apiUrl: 'https://api-testnet.btfd.wtf/api',
  socketIoUrl : 'https://api-testnet.btfd.wtf',
  appUrl: 'https://zkcandy-testnet.btfd.wtf',
  wcProjectId: '793974c63c88dc36af450cb0ad0c3a82',
  wcProjectName: 'BTFD',
  checkinContractAddress: '0x47798565cF7ee259956724aa6f15229adE91b574',
  shopContractAddress: '0x586fed1F3d872de4a22bD329DB49519214A1b9Ce',
  actualRoute: true,
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
