import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-dex-offering',
    templateUrl: './dex-offering.component.html',
    styleUrls: ['./dex-offering.component.scss']
})
export class DexOfferingComponent implements OnInit {

    constructor() { }

    ngOnInit(): void {}

}