import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PageShopService {

  constructor(private http: HttpClient) { }

  getShopItems(): Observable<any> {
    return this.http.get(environment.apiUrl + `/shop-items`);
}

  shopItemPriceBySelectionNo(selectionNo: any): Observable<any> {
    return this.http.get(environment.apiUrl + `/shop-item-price/`+ selectionNo);
  }

  signAuthorizedSignerToPurchaseItem(msg: any): Observable<any> {
    return this.http.post(environment.apiUrl + `/sign-authorized-sender`, msg);
  }

  shopPurchaseSuccessful(msg: any): Observable<any> {
    return this.http.post(environment.apiUrl + `/shop-purchase-successful`, msg);
  }
}
