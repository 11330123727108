<!-- Navbar -->
<div
    class="navbar-area"
    [ngClass]="{'sticky': isSticky}"
>

    <!-- Default Navbar -->
    <div
        class="container-fluid"
        [ngClass]="{'d-none': router.url === '/dex-offering' || router.url === '/gaming-metaverse'}"
    >
        <nav class="navbar navbar-expand-lg navbar-light bg-light" [class.active]="classApplied">
            <a class="navbar-brand" routerLink="/metavarse-launchpad">
                <img src="assets-app/images/logo.png" alt="logo">
            </a>
            <button class="navbar-toggler" type="button" (click)="toggleClass()">
                <span class="burger-menu">
                    <span class="top-bar"></span>
                    <span class="middle-bar"></span>
                    <span class="bottom-bar"></span>
                </span>
            </button>
            <div class="collapse navbar-collapse">
                <ul class="navbar-nav">
                    <li class="nav-item">
                        <span class="nav-link" (click)="onClick('home')">Home</span>
                    </li>
                    <li class="nav-item">
                        <span class="nav-link" (click)="onClick('features')">Features</span>
                    </li>
                    <li class="nav-item">
                        <span class="nav-link" (click)="onClick('how-it-works')">How It Works</span>
                    </li>
                    <li class="nav-item">
                        <span class="nav-link" (click)="onClick('what-you-get')">What You get</span>
                    </li>
                    <li class="nav-item">
                        <span class="nav-link" (click)="onClick('about')">About Us</span>
                    </li>
                    <li class="nav-item">
                        <span class="nav-link" (click)="onClick('projects')">Projects</span>
                    </li>
                </ul>
                <div class="others-option d-flex align-items-center">
                    <div class="option-item">
                        <a routerLink="/contact-us" class="default-btn">Apply For IDO</a>
                    </div>
                    <div class="option-item">
                        <span class="burger-menu" (click)="switcherToggleClass()">
                            <i class="ri-menu-4-line"></i>
                        </span>
                    </div>
                </div>
            </div>
        </nav>
    </div>

    <!-- Navbar For Dex Offering -->
    <div
        class="container style-two d-none"
        [ngClass]="{'d-block': router.url === '/dex-offering'}"
    >
        <nav class="navbar navbar-expand-lg navbar-light bg-light" [class.active]="classApplied">
            <a class="navbar-brand" routerLink="/dex-offering">
                <img src="assets-app/images/logo.png" alt="logo">
            </a>
            <button class="navbar-toggler" type="button" (click)="toggleClass()">
                <span class="burger-menu">
                    <span class="top-bar"></span>
                    <span class="middle-bar"></span>
                    <span class="bottom-bar"></span>
                </span>
            </button>
            <div class="collapse navbar-collapse">
                <ul class="navbar-nav me-auto">
                    <li class="nav-item">
                        <span class="nav-link" (click)="onClick('home')">Home</span>
                    </li>
                    <li class="nav-item">
                        <span class="nav-link" (click)="onClick('about')">About Us</span>
                    </li>
                    <li class="nav-item">
                        <span class="nav-link" (click)="onClick('how-it-works')">How It Works</span>
                    </li>
                    <li class="nav-item">
                        <span class="nav-link" (click)="onClick('token-info')">Token Info</span>
                    </li>
                </ul>
                <a class="logo-center" routerLink="/dex-offering">
                    <img src="assets-app/images/logo-icon.png" alt="logo">
                </a>
                <ul class="navbar-nav ms-auto">
                    <li class="nav-item">
                        <span class="nav-link" (click)="onClick('roadmap')">Roadmap</span>
                    </li>
                    <li class="nav-item">
                        <span class="nav-link" (click)="onClick('ecosystem')">Ecosystem</span>
                    </li>
                    <li class="nav-item">
                        <span class="nav-link" (click)="onClick('projects')">Projects</span>
                    </li>
                </ul>
                <ul class="navbar-nav for-responsive">
                    <li class="nav-item">
                        <span class="nav-link" (click)="onClick('home')">Home</span>
                    </li>
                    <li class="nav-item">
                        <span class="nav-link" (click)="onClick('about')">About Us</span>
                    </li>
                    <li class="nav-item">
                        <span class="nav-link" (click)="onClick('how-it-works')">How It Works</span>
                    </li>
                    <li class="nav-item">
                        <span class="nav-link" (click)="onClick('token-info')">Token Info</span>
                    </li>
                    <li class="nav-item">
                        <span class="nav-link" (click)="onClick('roadmap')">Roadmap</span>
                    </li>
                    <li class="nav-item">
                        <span class="nav-link" (click)="onClick('ecosystem')">Ecosystem</span>
                    </li>
                    <li class="nav-item">
                        <span class="nav-link" (click)="onClick('projects')">Projects</span>
                    </li>
                </ul>
                <div class="others-option d-flex align-items-center">
                    <div class="option-item">
                        <a routerLink="/contact-us" class="default-btn">Apply For IDO</a>
                    </div>
                    <div class="option-item">
                        <span class="burger-menu" (click)="switcherToggleClass()">
                            <i class="ri-menu-4-line"></i>
                        </span>
                    </div>
                </div>
            </div>
        </nav>
    </div>
    
    <!-- Navbar For Gaming Metaverse -->
    <div
        class="container style-three d-none"
        [ngClass]="{'d-block': router.url === '/gaming-metaverse'}"
    >
        <nav class="navbar navbar-expand-lg navbar-light bg-light" [class.active]="classApplied">
            <a class="navbar-brand" routerLink="/gaming-metaverse">
                <img src="assets-app/images/logo-icon.png" alt="logo">
            </a>
            <button class="navbar-toggler" type="button" (click)="toggleClass()">
                <span class="burger-menu">
                    <span class="top-bar"></span>
                    <span class="middle-bar"></span>
                    <span class="bottom-bar"></span>
                </span>
            </button>
            <div class="collapse navbar-collapse">
                <ul class="navbar-nav">
                    <li class="nav-item">
                        <span class="nav-link" (click)="onClick('home')">Home</span>
                    </li>
                    <li class="nav-item">
                        <span class="nav-link" (click)="onClick('how-it-works')">How It Works</span>
                    </li>
                    <li class="nav-item">
                        <span class="nav-link" (click)="onClick('projects')">Projects</span>
                    </li>
                    <li class="nav-item">
                        <span class="nav-link" (click)="onClick('token-info')">Token Info</span>
                    </li>
                    <li class="nav-item">
                        <span class="nav-link" (click)="onClick('roadmap')">Roadmap</span>
                    </li>
                    <li class="nav-item">
                        <span class="nav-link" (click)="onClick('Team')">Team</span>
                    </li>
                </ul>
                <div class="others-option d-flex align-items-center">
                    <div class="option-item">
                        <a routerLink="/contact-us" class="default-btn">Apply For IDO</a>
                    </div>
                    <div class="option-item">
                        <span class="burger-menu" (click)="switcherToggleClass()">
                            <i class="ri-menu-4-line"></i>
                        </span>
                    </div>
                </div>
            </div>
        </nav>
    </div>

</div>

<!-- Sidebar Modal -->
<div class="sidebarModal" [class.active]="switcherClassApplied">
    <div class="modal-dialog">
        <div class="modal-content">
            <button type="button" class="close" (click)="switcherToggleClass()">
                <i class="ri-close-fill"></i>
            </button>
            <div class="modal-body">
                <div class="logo">
                    <a routerLink="/" class="d-inline-block">
                        <img src="assets-app/images/logo.png" alt="image">
                    </a>
                </div>
                <div class="instagram-list">
                    <div class="row">
                        <div class="col-lg-4 col-md-4 col-sm-4 col-6">
                            <div class="box">
                                <img src="assets-app/images/blog/blog-1.jpg" alt="image">
                                <i class="ri-instagram-line"></i>
                                <a href="#" target="_blank" class="link-btn"></a>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-4 col-sm-4 col-6">
                            <div class="box">
                                <img src="assets-app/images/blog/blog-2.jpg" alt="image">
                                <i class="ri-instagram-line"></i>
                                <a href="#" target="_blank" class="link-btn"></a>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-4 col-sm-4 col-6">
                            <div class="box">
                                <img src="assets-app/images/blog/blog-3.jpg" alt="image">
                                <i class="ri-instagram-line"></i>
                                <a href="#" target="_blank" class="link-btn"></a>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-4 col-sm-4 col-6">
                            <div class="box">
                                <img src="assets-app/images/blog/blog-4.jpg" alt="image">
                                <i class="ri-instagram-line"></i>
                                <a href="#" target="_blank" class="link-btn"></a>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-4 col-sm-4 col-6">
                            <div class="box">
                                <img src="assets-app/images/blog/blog-5.jpg" alt="image">
                                <i class="ri-instagram-line"></i>
                                <a href="#" target="_blank" class="link-btn"></a>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-4 col-sm-4 col-6">
                            <div class="box">
                                <img src="assets-app/images/blog/blog-6.jpg" alt="image">
                                <i class="ri-instagram-line"></i>
                                <a href="#" target="_blank" class="link-btn"></a>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="sidebar-contact-info">
                    <h2>
                        <a href="tel:+11234567890">+1 (123) 456 7890</a>
                        <span>OR</span>
                        <a href="mailto:hello&#64;mave.com">hello&#64;mave.com</a>
                    </h2>
                </div>
                <ul class="social-list">
                    <li><a href="#" target="_blank">
                        <i class="ri-facebook-fill"></i>
                    </a></li>
                    <li><a href="#" target="_blank">
                        <i class="ri-linkedin-fill"></i>
                    </a></li>
                    <li><a href="#" target="_blank">
                        <i class="ri-twitter-fill"></i>
                    </a></li>
                    <li><a href="#" target="_blank">
                        <i class="ri-instagram-line"></i>
                    </a></li>
                </ul>
            </div>
        </div>
    </div>
</div>