<div id="video" class="video-area" style="background-image: url(assets-app/images/video-bg.jpg);">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="video-content">
                    <span class="top-title">VIDEO PRESENTATION</span>
                    <h2>Introducing Mave Earth Token To You</h2>
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="play-video-btn">
                    <button class="video-btn" (click)="openPopup()">
                        <i class="ri-play-fill"></i>
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- Video Popup -->
<div class="video-popup" *ngIf="isOpen">
    <div class="popup-inner">
        <iframe src="https://www.youtube.com/embed/XNQk1qt__Vw" title="Setup and Local Installation - React Nextjs Online Yoga Coaching &amp; Training Courses System" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
        <button (click)="closePopup()" type="button" class="close-btn">
            <i class="ri-close-line"></i>
        </button>
    </div>
</div>