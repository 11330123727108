import { Injectable } from '@angular/core';
import { io, Socket, Manager, connect } from "socket.io-client"
import { GameService } from '../service/game.service';
import { Web3Service } from '../web3/web3.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SocketService {
  private socket = io(environment.socketIoUrl, {
    autoConnect: false,
    forceNew: true,
    transports: ['websocket', 'polling'],
    query: { t: localStorage.getItem('btfd-jwt') },
  })
  
  constructor(
    public gameService: GameService,
    private web3Service: Web3Service,
  ) {
    this.web3Service.isConnectedObs.subscribe(connected => {
      console.debug('wallet connected')
      console.debug(connected)

      if (connected) this.connect()
      else this.disconnect()
    })
  }

  connect() {
    this.socket.on('connect', () => {
      console.log('Connected to server');
      // Handle successful connection
      this.emitConnectChip()
    });

    this.socket.on('connect_error', (error) => {
      console.error(error);
      this.socket.io.opts.query = { t: localStorage.getItem('btfd-jwt') };
    });

    this.socket.on('chip', (response: any) => {
      console.log('EMIT WITH CHIPS:', response.chip);
      // Handle connection error
      this.gameService.setChip(response.chip.toFixed(2))
    });

    this.socket.connect()
  }

  emitConnectChip() {
    this.socket.emit('chip')
  }

  disconnect() {
    this.socket.disconnect()
  }

  isConnected(): boolean {
    if (!this.socket) return false
    return this.socket.connected
  }
}
