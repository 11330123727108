import { Injectable, inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class PageGameDiceRollService {
    protected httpClient = inject(HttpClient);
    playerId!: number;

    fetchPlayerId(): Observable<any> {
        return this.httpClient.get<any>(environment.apiUrl + `/player/id`);
    }

    placeBet(playerId: number, betData: any): Observable<any> {
        const params = {
            playerId: playerId,
        };
        return this.httpClient.post(
            environment.apiUrl + '/game/dice-roll/bet', betData,
            { params, observe: 'response' }
        );
    }
}
