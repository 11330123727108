import { Component } from '@angular/core';
import { Router, NavigationCancel, NavigationEnd, NavigationStart } from '@angular/router';
import { Location, LocationStrategy, PathLocationStrategy } from '@angular/common';
import { filter } from 'rxjs/operators';
import { SocketService } from './socket/socket.service';
import { NgxGoogleAnalyticsModule } from 'ngx-google-analytics'; 
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DailyCheckinDTO, PageCheckinComponent } from './page-checkin/page-checkin.component';
import { LocalStorageService, SessionStorageService } from 'ngx-webstorage';
import { lastValueFrom } from 'rxjs';
import { GameService } from './service/game.service';
import { Web3Service } from './web3/web3.service';

declare let $: any;

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    providers: [
        Location, {
            provide: LocationStrategy,
            useClass: PathLocationStrategy
        }
    ]
})
export class AppComponent {

    location: any;
    routerSubscription: any;

    previousPath = ''

    constructor(
        public router: Router,
        private _location: Location,
        private socketService: SocketService, // need to inject in order to run
        private modalService: NgbModal,
        private $sessionStorage: SessionStorageService,
        private $localStorage: LocalStorageService,
        private gameService: GameService,
        private web3Service: Web3Service,
    ) {
        // The below 2 events is to avoid game embed memory leak
        router.events.pipe(
            filter(event => event instanceof NavigationStart),
        ).subscribe(event => {
            console.debug('NavigationStart:', this._location.path())

            this.previousPath = this._location.path()
        })
        router.events.pipe(
            filter(event => event instanceof NavigationEnd),
        ).subscribe(event => {
            console.debug('NavigationEnd:', this._location.path())

            if (
                this.previousPath.includes('/btfd-game')
                && this.previousPath !== this._location.path()
            ) window.location.reload()
        })
    }

    ngOnInit(){
        this.recallJsFuntions();
    }

    ngAfterContentInit(): void {
        this.autoPopupCheckinReward()
      }

    recallJsFuntions() {
        this.routerSubscription = this.router.events
        .pipe(filter(event => event instanceof NavigationEnd || event instanceof NavigationCancel))
        .subscribe(event => {
            this.location = this.router.url;
            if (!(event instanceof NavigationEnd)) {
                return;
            }
            window.scrollTo(0, 0);
        });
    }

    async autoPopupCheckinReward() {
        this.web3Service.isLoggedInState.subscribe(async (connected) => {
            if (connected) {
                const lastViewDate = this.getCheckinViewDate();
    
                const dailyCheckinDTOArray: DailyCheckinDTO[] = await lastValueFrom(this.gameService.getCheckinData())
                const dailyCheckinDTO: DailyCheckinDTO | undefined = dailyCheckinDTOArray.find(data => data.isDateToday)
            
                if (!dailyCheckinDTO) {
                console.log("cant get reward day")
                return
                }
            
                const backendDateFormatted = new Date(dailyCheckinDTO.date).setHours(0, 0, 0, 0)
                const lastViewDateFormatted = new Date(lastViewDate).setHours(0, 0, 0, 0)
            
                this.setCheckinViewDate(backendDateFormatted)
            
                if (!lastViewDate || backendDateFormatted != lastViewDateFormatted) {
                this.setCheckinViewState(false);
                }
            
                const isOpenBefore = this.getCheckinViewState();
                if (!dailyCheckinDTO.isClaimed && !isOpenBefore) {
                this.setCheckinViewState(true);
                this.modalService.open(PageCheckinComponent);
                }
                    }
                })
            }

    setCheckinViewState(isTodayView: boolean) {
        this.$localStorage.store('checkinViewState', isTodayView);
    }

    getCheckinViewState() {
        return this.$localStorage.retrieve('checkinViewState');
    }

    clearCheckinViewState() {
        this.$localStorage.clear('checkinViewState');
    }

    setCheckinViewDate(timestamp: number) {
        this.$localStorage.store('checkinViewDate', timestamp);
    }

    getCheckinViewDate() {
        return this.$localStorage.retrieve('checkinViewDate');
    }

    clearCheckinViewDate() {
        this.$localStorage.clear('checkinViewDate');
    }
    
}