import { CommonModule } from '@angular/common';
import { Component, HostListener, OnInit, inject } from '@angular/core';
import { PageLeaderboardService } from './page-leaderboard.service';
import { firstValueFrom } from 'rxjs';

@Component({
    selector: 'app-page-leaderboard',
    standalone: true,
    imports: [CommonModule],
    templateUrl: './page-leaderboard.component.html',
    styleUrl: './page-leaderboard.component.scss',
})
export class PageLeaderboardComponent implements OnInit {
    protected pageLeaderboardService = inject(PageLeaderboardService);

    page = 0;
    maxPage = 0;
    selfPosition: any;
    positions: any[] = [];
    showLoadMore: boolean = false;

    ngOnInit(): void {
        this.loadData();
    }

    loadData() {
        this.pageLeaderboardService.fetchSelfPosition().subscribe({
            next: (response) => {
                this.selfPosition = response.body;
            },
            error: (error) => {
                //
            },
            complete: () => {
                //
            },
        });

        this.pageLeaderboardService.fetchLeaderboard(this.page).subscribe({
            next: (response) => {
                const maxSize = response.headers.get('X-Total-Count');
                if (maxSize) {
                    const _maxSize = parseInt(maxSize);
                    const size = 10;
                    if (_maxSize > 10) {
                        this.showLoadMore = true;
                    }

                    this.maxPage = Math.floor(_maxSize / size);
                }

                this.positions.push(...response.body);
            },
            error: (error) => {
                //
            },
            complete: () => {
                //
            },
        });
    }

    loadMore() {
        if (this.page < this.maxPage) {
            this.page++;
            this.loadData();
        }
    }

    hasMoreItems(): boolean {
        return this.showLoadMore;
    }
}
