import { AfterViewInit, OnInit, Component, TemplateRef, ViewChild } from '@angular/core';
import { PageShopService } from './page-shop.service';
import { CommonModule } from '@angular/common';
import { Web3Service } from '../web3/web3.service';
import { NgxLoadingModule } from 'ngx-loading';

export interface IShopSign {
  messageHash: string
  r: string
  s: string
  selectionNo: number
  userWalletAddress: string
  expiration: number
  v: number
}

@Component({
  selector: 'app-page-shop',
  standalone: true,
  imports: [CommonModule, NgxLoadingModule],
  templateUrl: './page-shop.component.html',
  styleUrl: './page-shop.component.scss'
})
export class PageShopComponent implements AfterViewInit {

  shopItemList: any
  
  loading: boolean = false
  showingTemplate: boolean = false

  @ViewChild('successLoadingTemplate', { static: false })
  successLoadingTemplate!: TemplateRef<any>

  @ViewChild('failLoadingTemplate', { static: false })
  failLoadingTemplate!: TemplateRef<any>

  @ViewChild('rejectLoadingTemplate', { static: false })
  rejectLoadingTemplate!: TemplateRef<any>

  @ViewChild('emptyLoadingTemplate', { static: false })
  emptyLoadingTemplate!: TemplateRef<any>

  loadingTemplate!: TemplateRef<any>;

  constructor(
    private shopService: PageShopService,
    private web3Service: Web3Service,
  ) { }

  ngAfterViewInit(): void {
    this.getShopItem();
  }

  getShopItem() {
    this.shopService.getShopItems().subscribe((response) => {
      this.shopItemList = response
    }, (error) => {
      console.log(error);
    });
  }

  async onClickPurchase(selectionNo: number, ethPrice: number) {
    const isValidNetwork = await this.web3Service.checkAndHandleNetworkSwitch()
    if (!isValidNetwork) {
      return
    }
    
    this.loading = true
    // alert(selectionNo)
    this.shopService.signAuthorizedSignerToPurchaseItem({selectionNo: selectionNo}).subscribe((response) => {
      const shopSign: IShopSign = response
      console.log(shopSign)
      this.handlePurchase(shopSign, ethPrice)
    }, (error) => {
      console.log(error);
      // this.loading = false
      this.loadingTemplate = this.failLoadingTemplate;

    });

  }

  async handlePurchase(shopSign: IShopSign, ethPrice: number) {
    try {
      const transactionHash = await this.web3Service.purchaseShopItemPackage(shopSign, ethPrice);
      this.loadingTemplate = this.successLoadingTemplate
    } catch (error: any) {
      // this.loading = false
      if (error.toString().includes('reject') || error.toString().includes('rejected')) {
        this.loadingTemplate = this.rejectLoadingTemplate
      } else {
        this.loadingTemplate = this.failLoadingTemplate
      }
    }
  }

  onClickOK() {
    this.loading = false
    this.loadingTemplate = this.emptyLoadingTemplate
    this.showingTemplate = !this.showingTemplate
  }
}
