<!-- Navbar -->
<div class="navbar-area" [ngClass]="{'sticky': isSticky}">

    <!-- Navbar For Gaming Metaverse -->
    <div class="container style-three d-none" [ngClass]="{'d-block': true}">
        <nav class="navbar navbar-expand-lg navbar-light bg-light" [class.active]="classApplied">
            <!-- <a class="navbar-brand" routerLink="/btfd-home"> -->
            <a class="my-lg-2" routerLink="/btfd-home">
                <!-- <img src="https://placehold.co/164x118" alt="logo"> -->
                <img class="logo" src="assets-app/btfd-images/logo-btfd.png" alt="logo">
            </a>
            <button class="navbar-toggler" type="button" (click)="toggleClass()">
                <span class="burger-menu">
                    <span class="top-bar"></span>
                    <span class="middle-bar"></span>
                    <span class="bottom-bar"></span>
                </span>
            </button>
            <div class="collapse navbar-collapse">
                <ul class="navbar-nav">
                    <li class="nav-item"><a href="javascript:void(0)" class="dropdown-toggle nav-link">Games</a>
                        <ul class="dropdown-menu">
                            <li class="nav-item">
                                <a routerLink="/btfd-game/dinosaur" class="nav-link" routerLinkActive="active"
                                    [routerLinkActiveOptions]="{exact: true}">Dinosaur Crash</a>
                            </li>
                            <li class="nav-item">
                                <a routerLink="/btfd-game/ct" class="nav-link" routerLinkActive="active"
                                    [routerLinkActiveOptions]="{exact: true}">CT Crash</a>
                            </li>
                        </ul>
                    </li>
                    <li class="nav-item">
                        <a routerLink="/btfd-leaderboard" class="nav-link" routerLinkActive="active"
                            [routerLinkActiveOptions]="{exact: true}">Leaderboard</a>
                    </li>
                    <li class="nav-item"><a href="javascript:void(0)" class="dropdown-toggle nav-link">More</a>
                        <ul class="dropdown-menu">
                            <li class="nav-item">
                                <a class="nav-link" routerLinkActive="active"
                                    (click)="openCheckinReward()">Daily Check-In</a>
                            </li>
                            <li class="nav-item">
                                <a routerLink="/btfd-shop" class="nav-link" routerLinkActive="active"
                                    [routerLinkActiveOptions]="{exact: true}">Shop</a>
                            </li>
                            <li class="nav-item">
                                <a routerLink="/btfd-bethistory" class="nav-link" routerLinkActive="active"
                                    [routerLinkActiveOptions]="{exact: true}">Wager History</a>
                            </li>
                            <li class="nav-item">
                                <a routerLink="/btfd-provablyfair" class="nav-link" routerLinkActive="active"
                                    [routerLinkActiveOptions]="{exact: true}">Provably Fair</a>
                            </li>
                            
                        </ul>
                    </li>
                    <li class="nav-item">
                        <a routerLink="/btfd-shop" class="nav-link" routerLinkActive="active"
                            [routerLinkActiveOptions]="{exact: true}">  @if (web3Service.isConnected()) {
                                {{ _gameService.availableChipValue }}$ 
                            }
                        </a>
                    </li>
                </ul>
                <div class="others-option d-flex align-items-center">
                    <div class="option-item">
                        <button class="default-btn" (click)="login()">
                        @if (web3Service.isLoggedIn()) {
                            Open Wallet
                        } @else {
                            Connect Wallet
                        }
                        </button>
                    </div>
                    <!-- <div class="option-item">
                        <span class="burger-menu" (click)="switcherToggleClass()">
                            <i class="ri-menu-4-line"></i>
                        </span>
                    </div> -->
                </div>
            </div>
        </nav>
    </div>

</div>