import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-metavarse-launchpad-banner',
    templateUrl: './metavarse-launchpad-banner.component.html',
    styleUrls: ['./metavarse-launchpad-banner.component.scss']
})
export class MetavarseLaunchpadBannerComponent implements OnInit {

    constructor() { }

    ngOnInit(): void {}

}