<!-- Page Title -->
<div class="page-banner-area" style="background-image: url(assets-app/images/page-banner-bg.jpg);">
    <div class="container">
        <div class="page-banner-content">
            <ul>
                <li>
                    <a routerLink="/">
                        MORE
                    </a>
                </li>
                <li>
                    <span>PROVABLY FAIR</span>
                </li>
            </ul>
            <h2>Provably Fair</h2>
            <img src="assets-app/images/page-star.png" class="star" alt="star">
        </div>
    </div>
    <img src="assets-app/images/banner/banner-shape-1.png" class="shape shape-1" alt="shape">
    <img src="assets-app/images/banner/banner-shape-2.png" class="shape shape-2" alt="shape">
</div>

<!-- Contact -->
<div class="contact-area ptb-120">
    <div class="container">
        <div class="section-title">
            <span class="top-title">PROVABLY FAIR</span>
            <h2>Verify Result</h2>
        </div>
        <div class="contact-form">
            <form>
                <div class="row">
                    <div class="col-lg-12 col-md-12">
                        <div class="form-group">
                            <label>Games</label>
                            <div ngbDropdown class="d-inline-block w-100">
                                <button type="button" class="btn btn-outline-primary" id="dropdownBasic1" ngbDropdownToggle [textContent]="betData?.gameCode || 'Select a game here'">
                                    Select a game here
                                </button>
                                <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
                                    <button ngbDropdownItem>Dinosaur Crash</button>
                                    <button ngbDropdownItem>CT Crash</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-12 col-md-12">
                        <div class="form-group">
                            <label>Server Seed</label>
                            <input type="text" name="server_seed" id="server_seed" class="form-control" [value]="betData?.serverSeed">
                        </div>
                    </div>
                    <div class="col-lg-12 col-md-12">
                        <div class="form-group">
                            <label>Server Seed (Hashed)</label>
                            <input type="text" name="server_seed_hashed" id="server_seed_hashed" required class="form-control" [value]="betData?.hashedServerSeed">
                        </div>
                    </div>
                    <div class="col-lg-12 col-md-12">
                        <div class="form-group">
                            <label>Client Seed</label>
                            <input type="text" name="client_seed" id="client_seed" class="form-control" required [value]="betData?.clientSeed">
                        </div>
                    </div>
                    <div class="col-lg-12 col-md-12">
                        <div class="form-group">
                            <label>Nonce</label>
                            <input type="number" name="nonce" id="nonce" class="form-control" required [value]="betData?.nonce" (input)="updateResult($event)">
                        </div>
                    </div>
                    <div class="col-lg-12 col-md-12">
                        <div class="form-group">
                            <label>Result</label>
                            <input type="text" name="result" id="result" class="form-control" disabled [value]="crashPoint || ''">
                        </div>
                    </div>
                    <div class="col-lg-12 col-md-12">
                        <button type="button" class="default-btn" [disabled]="false" (click)="handleClick(betData?.hashedServerSeed)">Reveal Result & Rotate Seeds</button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>