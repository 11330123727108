<div class="banner-area" style="background-image: url(assets-app/images/banner/banner-bg-3.jpg);">
    <div class="container">
        <div class="banner-content">
            <span class="top-title">THE NEXT-GENERATION LAUNCHPAD FOR</span>
            <h1>Blockchain Games And The Metaverse</h1>
            <p>The Metaverse Is The Next Generation Of The Internet. As One Of Its Earliest Explorers, You Will Help Fuel Its Expansion And Share In The Benefits Of This Growth.</p>
            <div class="banner-btn">
                <a routerLink="/projects" class="default-btn btn-active">Launchpad</a>
                <a routerLink="/projects" class="default-btn connect">Connect Wallet</a>
            </div>
        </div>
    </div>
    <img src="assets-app/images/banner/banner-shape-6.png" class="shape shape-3" alt="shape">
    <img src="assets-app/images/banner/banner-shape-7.png" class="shape shape-4" alt="shape">
</div>