<div id="what-you-get" class="what-you-get-area pt-120 pb-90">
    <div class="container">
        <div class="section-title">
            <span class="top-title">WHAT YOU WILL GET</span>
            <h2>Creating The Metaverse </h2>
        </div>
        <div class="row">
            <div class="col-lg-6 col-md-6">
                <div class="single-what-you-get">
                    <img src="assets-app/images/get/get-1.jpg" alt="get-image">
                    <div class="main">
                        <h3>Embrace The Mave Ecosystem</h3>
                    </div>
                    <div class="hover">
                        <div>
                            <h3>Embrace The Mave Ecosystem</h3>
                            <p>A Gamified Marketplace Dedicated To The Metaverse And Gaming Assets. The Metaverse Marketplace Allows Users To Trade, Auction, And Rent.</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-md-6">
                <div class="single-what-you-get color-style-two">
                    <img src="assets-app/images/get/get-2.jpg" alt="get-image">
                    <div class="main">
                        <h3>Empower Digital Ownership with NFTs</h3>
                    </div>
                    <div class="hover">
                        <div>
                            <h3>Empower Digital Ownership with NFTs</h3>
                            <p>A Gamified Marketplace Dedicated To The Metaverse And Gaming Assets. The Metaverse Marketplace Allows Users To Trade, Auction, And Rent.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <img
        src="assets-app/images/get/shape-1.png"
        class="shape"
        alt="shape"
        [ngClass]="{'d-none': router.url === '/index-2' || router.url === '/dex-offering' || router.url === '/about-us'}"
    >
    <img src="assets-app/images/page-star.png" class="page-star" alt="page-star">
</div>