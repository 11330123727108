<!-- Footer -->
<div
    class="footer-area"
    [ngClass]="{'style-two': router.url === '/index-3' || router.url === '/gaming-metaverse' || router.url === '/features' || router.url === '/blog'}"
>
    <div class="container">
        <div class="row align-items-center justify-content-center">
            <div class="col-lg-5 col-md-5">
                <div class="single-footer">
                    <ul>
                        <li>
                            <a routerLink="/about-us">About Us</a>
                        </li>
                        <li>
                            <a routerLink="/projects">Ecosystem</a>
                        </li>
                        <li>
                            <a routerLink="/project-details">Projects</a>
                        </li>
                        <li>
                            <a routerLink="/blog">Roadmap</a>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="col-lg-2 col-md-2">
                <div class="single-footer logo">
                    <a routerLink="/">
                        <img src="assets-app/images/white-logo.png" alt="logo">
                    </a>
                </div>
            </div>
            <div class="col-lg-5 col-md-5">
                <div class="single-footer">
                    <ul class="social-link">
                        <li>
                            <span>Follow Us On:</span>
                        </li>
                        <li>
                            <a href="https://www.facebook.com/" target="_blank">
                                <img src="assets-app/images/icon/icon-8.svg" alt="facebook">
                                Facebook
                            </a>
                        </li>
                        <li>
                            <a href="https://www.twitter.com/" target="_blank">
                                <img src="assets-app/images/icon/icon-9.svg" alt="twitter">
                                Twitter
                            </a>
                        </li>
                        <li>
                            <a href="https://www.instagram.com/" target="_blank">
                                <img src="assets-app/images/icon/icon-12.svg" alt="instagram">
                                Instagram
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
    <img src="assets-app/images/footer-shape.png" class="footer-shape" alt="footer-shape">
</div>

<!-- Copyright -->
<div class="copyright-area">
    <div class="container">
        <p><i class="ri-copyright-line"></i> Mave is Proudly Owned by <a href="https://hibootstrap.com/" target="_blank">HiBootstrap</a></p>
    </div>
</div>