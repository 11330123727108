import { AfterViewInit, Component, OnInit } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { GameService } from 'src/app/service/game.service';
import { Web3Service } from 'src/app/web3/web3.service';

export interface DailyCheckinDTO {
  date: Date
  day: number
  isDateToday: boolean
  isClaimed: boolean
  rewards: DailyCheckinRewardDTO[]
}

export interface DailyCheckinRewardDTO {
  itemId: number
  itemCode: string
  itemAmount: number
}
@Component({
  selector: 'app-check-in',
  templateUrl: './check-in.component.html',
  styleUrl: './check-in.component.scss'
})

export class CheckInComponent implements AfterViewInit {
  dailyCheckin: DailyCheckinDTO[] | undefined
  // dailyCheckin: DailyCheckinDTO[] = [
  //   {
  //     date: new Date('2024-01-01'),
  //     day: 1,
  //     isDateToday: true,
  //     isClaimed: false,
  //     rewards: {
  //       itemId: 1,
  //       itemCode: 'Chip',
  //       itemAmount: 100
  //     },
  //   },
  //   {
  //     date: new Date('2024-01-02'),
  //     day: 2,
  //     isDateToday: false,
  //     isClaimed: false,
  //     rewards: {
  //       itemId: 1,
  //       itemCode: 'Chip',
  //       itemAmount: 100
  //     }
  //   },
  //   {
  //     date: new Date('2024-01-03'),
  //     day: 3,
  //     isDateToday: false,
  //     isClaimed: false,
  //     rewards: {
  //       itemId: 1,
  //       itemCode: 'Chip',
  //       itemAmount: 100
  //     }
  //   },
  //   {
  //     date: new Date('2024-01-04'),
  //     day: 4,
  //     isDateToday: false,
  //     isClaimed: false,
  //     rewards: {
  //       itemId: 1,
  //       itemCode: 'Chip',
  //       itemAmount: 100
  //     }
  //   },
  //   {
  //     date: new Date('2024-01-05'),
  //     day: 5,
  //     isDateToday: false,
  //     isClaimed: false,
  //     rewards: {
  //       itemId: 1,
  //       itemCode: 'Chip',
  //       itemAmount: 100
  //     }
  //   },
  //   {
  //     date: new Date('2024-01-06'),
  //     day: 6,
  //     isDateToday: false,
  //     isClaimed: false,
  //     rewards: {
  //       itemId: 1,
  //       itemCode: 'Chip',
  //       itemAmount: 100
  //     }
  //   },
  //   {
  //     date: new Date('2024-01-07'),
  //     day: 7,
  //     isDateToday: false,
  //     isClaimed: false,
  //     rewards: {
  //       itemId: 1,
  //       itemCode: 'Chip',
  //       itemAmount: 500
  //     }
  //   }
  // ]

  constructor(
    private gameService: GameService,
    private web3Service: Web3Service,
  ) { }

  ngAfterViewInit(): void {
    this.retrieveCheckinData()
  }

  async retrieveCheckinData() {
    const dailyCheckinDTO: DailyCheckinDTO[] = await lastValueFrom(this.gameService.getCheckinData())
    this.dailyCheckin = dailyCheckinDTO
    console.log(dailyCheckinDTO)
  }

  async claimReward(reward: number) {
    // document.querySelector('body')?.classList.add('loading');
    let transactionHash;
    
    try {
      const txnHash = await this.web3Service.getCheckinSign()
      //TODO: increase chip amount
    } catch (e) {
      //TODO: Handle error/user cancel
    }
    
    // var cn: any = this.authService.getNetwork();
    // if(cn.chain.id != this.authService.chainId){
    //     //Player will be prompt switch network by wallet if connected diff network, user require press sign again
    //     await this.authService.switchNetwork();
    //     document.querySelector('body')?.classList.remove('loading');
    //     return;
    // }
    
    // try {
    //   transactionHash = await this.web3Service.handlleCheckin();

    // } catch (e) {
    //   document.querySelector('body')?.classList.remove('loading');
    //   this.errorMessage = e
    //   // this.modalErrorRef = this.modalService.open(this.modalError, { centered: true, size: 'lg'})
    //   this.modalStatus.open('error','Error!',this.errorMessage,'Try Again')

    //   return
    // }

    // if (!transactionHash) {
    //   document.querySelector('body')?.classList.remove('loading');
    //   return
    // }

    // try {
    //   const httpResponse = await lastValueFrom(this.gameService.postSignToday(transactionHash));

    //   document.querySelector('body')?.classList.remove('loading');
    //   this.rewardEarned = reward;
    //   this.modalService.dismissAll();
    //   this.authService.updateEnergy();
    //   this.modalService.open(this.modalCheckinSuccess, { centered: true, size: 'md', windowClass: 'modal-checkin-success' });

    // } catch (e: any) {
    //   document.querySelector('body')?.classList.remove('loading');
    //   this.errorMessage = e.error
    //   // this.modalErrorRef = this.modalService.open(this.modalError, { centered: true, size: 'lg'})
    //   this.modalStatus.open('error','Error!',this.errorMessage,'Try Again')
    // }
  }
}

