import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PageLeaderboardService {

  protected httpClient = inject(HttpClient)

  fetchLeaderboard(page = 0) {
    const params = {
      'seasonId.equals': 1,
      page,
    }

    return this.httpClient.get<any>(environment.apiUrl + `/season-rank`, { params, observe: 'response' })
  }

  fetchSelfPosition() {
    const params = {
      'seasonId.equals': 1,
    }

    return this.httpClient.get<any>(environment.apiUrl + `/season-rank/player`, { params, observe: 'response' })
  }

}
