<app-multipage-navbar></app-multipage-navbar>

<!-- Page Title -->
<div class="page-banner-area" style="background-image: url(assets-app/images/page-banner-bg.jpg);">
    <div class="container">
        <div class="page-banner-content">
            <ul>
                <li>
                    <a routerLink="/">
                        HOME
                    </a>
                </li>
                <li>
                    <span>BLOG</span>
                </li>
            </ul>
            <h2>Blog</h2>
            <img src="assets-app/images/page-star.png" class="star" alt="star">
        </div>
    </div>
    <img src="assets-app/images/banner/banner-shape-1.png" class="shape shape-1" alt="shape">
    <img src="assets-app/images/banner/banner-shape-2.png" class="shape shape-2" alt="shape">
</div>

<!-- Blog -->
<div class="blog-area ptb-120">
    <div class="container">
        <div class="section-title">
            <span class="top-title">ARTICLES</span>
            <h2>Updated News & Articles</h2>
        </div>
        <div class="row justify-content-center">
            <div class="col-lg-4 col-md-6">
                <div class="single-blog">
                    <a routerLink="/blog-details">
                        <img src="assets-app/images/blog/blog-1.gif" alt="blog-image">
                    </a>
                    <div class="blog-content">
                        <span>27TH MAR, 2024</span>
                        <h3>
                            <a routerLink="/blog-details">
                                Next Earth’s Agenda and Other Updates In A Whole
                            </a>
                        </h3>
                        <a routerLink="/blog-details" class="read-more">READ MORE</a>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-blog">
                    <a routerLink="/blog-details">
                        <img src="assets-app/images/blog/blog-2.jpg" alt="blog-image">
                    </a>
                    <div class="blog-content">
                        <span>27TH MAR, 2024</span>
                        <h3>
                            <a routerLink="/blog-details">
                                Next Earth Soon to Launch Staking and Other Updates 
                            </a>
                        </h3>
                        <a routerLink="/blog-details" class="read-more">READ MORE</a>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-blog">
                    <a routerLink="/blog-details">
                        <img src="assets-app/images/blog/blog-3.jpg" alt="blog-image">
                    </a>
                    <div class="blog-content">
                        <span>27TH MAR, 2024</span>
                        <h3>
                            <a routerLink="/blog-details">
                                Next Earth Token, NXTT, Set to Gain Value From Burn
                            </a>
                        </h3>
                        <a routerLink="/blog-details" class="read-more">READ MORE</a>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-blog">
                    <a routerLink="/blog-details">
                        <img src="assets-app/images/blog/blog-4.jpg" alt="blog-image">
                    </a>
                    <div class="blog-content">
                        <span>27TH MAR, 2024</span>
                        <h3>
                            <a routerLink="/blog-details">
                                Next Earth’s Agenda and Other Updates In A Whole
                            </a>
                        </h3>
                        <a routerLink="/blog-details" class="read-more">READ MORE</a>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-blog">
                    <a routerLink="/blog-details">
                        <img src="assets-app/images/blog/blog-5.jpg" alt="blog-image">
                    </a>
                    <div class="blog-content">
                        <span>27TH MAR, 2024</span>
                        <h3>
                            <a routerLink="/blog-details">
                                Next Earth Soon to Launch Staking and Other Updates 
                            </a>
                        </h3>
                        <a routerLink="/blog-details" class="read-more">READ MORE</a>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-blog">
                    <a routerLink="/blog-details">
                        <img src="assets-app/images/blog/blog-6.jpg" alt="blog-image">
                    </a>
                    <div class="blog-content">
                        <span>27TH MAR, 2024</span>
                        <h3>
                            <a routerLink="/blog-details">
                                Next Earth Token, NXTT, Set to Gain Value From Burn
                            </a>
                        </h3>
                        <a routerLink="/blog-details" class="read-more">READ MORE</a>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-blog">
                    <a routerLink="/blog-details">
                        <img src="assets-app/images/blog/blog-7.jpg" alt="blog-image">
                    </a>
                    <div class="blog-content">
                        <span>27TH MAR, 2024</span>
                        <h3>
                            <a routerLink="/blog-details">
                                Next Earth’s Agenda and Other Updates In A Whole
                            </a>
                        </h3>
                        <a routerLink="/blog-details" class="read-more">READ MORE</a>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-blog">
                    <a routerLink="/blog-details">
                        <img src="assets-app/images/blog/blog-8.jpg" alt="blog-image">
                    </a>
                    <div class="blog-content">
                        <span>27TH MAR, 2024</span>
                        <h3>
                            <a routerLink="/blog-details">
                                Next Earth Soon to Launch Staking and Other Updates 
                            </a>
                        </h3>
                        <a routerLink="/blog-details" class="read-more">READ MORE</a>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-blog">
                    <a routerLink="/blog-details">
                        <img src="assets-app/images/blog/blog-9.jpg" alt="blog-image">
                    </a>
                    <div class="blog-content">
                        <span>27TH MAR, 2024</span>
                        <h3>
                            <a routerLink="/blog-details">
                                Next Earth Token, NXTT, Set to Gain Value From Burn
                            </a>
                        </h3>
                        <a routerLink="/blog-details" class="read-more">READ MORE</a>
                    </div>
                </div>
            </div>
            <div class="col-lg-12 col-md-12">
                <div class="read-more-btn">
                    <a routerLink="/blog" class="read-more">Load More Features</a>
                </div>
            </div>
        </div>
    </div>
</div>

<app-earn-rewards></app-earn-rewards>