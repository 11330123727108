<div id="article" class="blog-area pt-120 pb-90">
    <div class="container">
        <div class="section-title">
            <span class="top-title">ARTICLES</span>
            <h2>Updated News & Articles</h2>
        </div>
        <div class="row justify-content-center">
            <div class="col-lg-4 col-sm-6">
                <div class="single-blog">
                    <a routerLink="/blog-details">
                        <img src="assets-app/images/blog/blog-1.gif" alt="blog-image">
                    </a>
                    <div class="blog-content">
                        <span>27TH MAR, 2024</span>
                        <h3>
                            <a routerLink="/blog-details">
                                Next Earth’s Agenda and Other Updates In A Whole
                            </a>
                        </h3>
                        <a routerLink="/blog-details" class="read-more">READ MORE</a>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-sm-6">
                <div class="single-blog">
                    <a routerLink="/blog-details">
                        <img src="assets-app/images/blog/blog-2.jpg" alt="blog-image">
                    </a>
                    <div class="blog-content">
                        <span>27TH MAR, 2024</span>
                        <h3>
                            <a routerLink="/blog-details">
                                Next Earth Soon to Launch Staking and Other Updates 
                            </a>
                        </h3>
                        <a routerLink="/blog-details" class="read-more">READ MORE</a>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-sm-6">
                <div class="single-blog">
                    <a routerLink="/blog-details">
                        <img src="assets-app/images/blog/blog-3.jpg" alt="blog-image">
                    </a>
                    <div class="blog-content">
                        <span>27TH MAR, 2024</span>
                        <h3>
                            <a routerLink="/blog-details">
                                Next Earth Token, NXTT, Set to Gain Value From Burn
                            </a>
                        </h3>
                        <a routerLink="/blog-details" class="read-more">READ MORE</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>