import { NgModule, inject } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { NotFoundComponent } from './components/common/not-found/not-found.component';
import { AboutPageComponent } from './components/pages/inner-pages/about-page/about-page.component';
import { BlogDetailsPageComponent } from './components/pages/inner-pages/blog-details-page/blog-details-page.component';
import { BlogPageComponent } from './components/pages/inner-pages/blog-page/blog-page.component';
import { ContactPageComponent } from './components/pages/inner-pages/contact-page/contact-page.component';
import { FeatureDetailsPageComponent } from './components/pages/inner-pages/feature-details-page/feature-details-page.component';
import { FeaturesPageComponent } from './components/pages/inner-pages/features-page/features-page.component';
import { PrivacyPolicyPageComponent } from './components/pages/inner-pages/privacy-policy-page/privacy-policy-page.component';
import { ProjectDetailsPageComponent } from './components/pages/inner-pages/project-details-page/project-details-page.component';
import { ProjectsPageComponent } from './components/pages/inner-pages/projects-page/projects-page.component';
import { TeamPageComponent } from './components/pages/inner-pages/team-page/team-page.component';
import { TermsConditionsPageComponent } from './components/pages/inner-pages/terms-conditions-page/terms-conditions-page.component';
import { DexOfferingComponent } from './components/pages/multi-page/dex-offering/dex-offering.component';
import { GamingMetaverseComponent } from './components/pages/multi-page/gaming-metaverse/gaming-metaverse.component';
import { LandSellingPlatformComponent } from './components/pages/multi-page/land-selling-platform/land-selling-platform.component';
import { MetavarseLaunchpadComponent } from './components/pages/multi-page/metavarse-launchpad/metavarse-launchpad.component';
import { NftCentricMetaverseComponent } from './components/pages/multi-page/nft-centric-metaverse/nft-centric-metaverse.component';
import { DexOfferingLandingComponent } from './components/pages/single-page/dex-offering-landing/dex-offering-landing.component';
import { GamingMetaverseLandingComponent } from './components/pages/single-page/gaming-metaverse-landing/gaming-metaverse-landing.component';
import { LandSellingPlatformLandingComponent } from './components/pages/single-page/land-selling-platform-landing/land-selling-platform-landing.component';
import { MetavarseLaunchpadLandingComponent } from './components/pages/single-page/metavarse-launchpad-landing/metavarse-launchpad-landing.component';
import { NftCentricMetaverseLandingComponent } from './components/pages/single-page/nft-centric-metaverse-landing/nft-centric-metaverse-landing.component';
import { CommonNavbarComponent } from './common-navbar/common-navbar.component';
import { PageHomeComponent } from './page-home/page-home.component';
import { CheckInComponent } from './components/pages/inner-pages/check-in/check-in.component';
import { HttpClientModule } from '@angular/common/http';
import { PageLeaderboardComponent } from './page-leaderboard/page-leaderboard.component';
import { PageGameComponent } from './page-game/page-game.component';
import { PageShopComponent } from './page-shop/page-shop.component';
import { PageCheckinComponent } from './page-checkin/page-checkin.component';
import { environment } from 'src/environments/environment';
import { PageProvablyFairComponent } from './page-provably-fair/page-provably-fair.component';
import { PageBetHistoryComponent } from './page-bet-history/page-bet-history.component';
import { authGuard } from './auth/auth.guard';
import { PageGameDiceRollComponent } from './page-game-dice-roll/page-game-dice-roll.component';

const actualRoutes: Routes = [
    {path: 'btfd-leaderboard', component: PageLeaderboardComponent},
    {path: 'btfd-game/:gameName', component: PageGameComponent, canActivate: [authGuard]},
    {path: 'btfd-shop', component: PageShopComponent, canActivate: [authGuard]},
    {path: 'btfd-checkin', component: PageCheckinComponent, canActivate: [authGuard]},
    {path: 'btfd-bethistory', component:PageBetHistoryComponent, canActivate: [authGuard]},
    {path: 'btfd-provablyfair', component: PageProvablyFairComponent},
    {path: 'btfd-home', component: PageHomeComponent},
    {path: 'btfd-dice-roll', component: PageGameDiceRollComponent}
]

const themeRoutes: Routes = [
    // Multi-page
    {path: '', component: MetavarseLaunchpadComponent},
    {path: 'index-2', component: DexOfferingComponent},
    {path: 'index-3', component: GamingMetaverseComponent},
    {path: 'index-4', component: NftCentricMetaverseComponent},
    {path: 'index-5', component: LandSellingPlatformComponent},

    // Single Page
    {path: 'metavarse-launchpad', component: MetavarseLaunchpadLandingComponent},
    {path: 'dex-offering', component: DexOfferingLandingComponent},
    {path: 'gaming-metaverse', component: GamingMetaverseLandingComponent},
    {path: 'nft-centric-metaverse', component: NftCentricMetaverseLandingComponent},
    {path: 'land-selling-platform', component: LandSellingPlatformLandingComponent},
    
    // Inner Pages
    {path: 'about-us', component: AboutPageComponent},
    {path: 'team', component: TeamPageComponent},
    {path: 'features', component: FeaturesPageComponent},
    {path: 'feature-details', component: FeatureDetailsPageComponent},
    {path: 'projects', component: ProjectsPageComponent},
    {path: 'project-details', component: ProjectDetailsPageComponent},
    {path: 'blog', component: BlogPageComponent},
    {path: 'blog-details', component: BlogDetailsPageComponent},
    {path: 'privacy-policy', component: PrivacyPolicyPageComponent},
    {path: 'terms-conditions', component: TermsConditionsPageComponent},
    {path: 'contact-us', component: ContactPageComponent},
]

const routes: Routes = environment.actualRoute ? [
    ...actualRoutes,

    {path: '', pathMatch: 'full', redirectTo: 'btfd-home'},
    {path: '**', component: NotFoundComponent},
] : [
    ...themeRoutes, 
    ...actualRoutes,

    {path: '**', component: NotFoundComponent},
]

@NgModule({
    imports: [RouterModule.forRoot(routes), HttpClientModule],
    exports: [RouterModule],
})
export class AppRoutingModule { }