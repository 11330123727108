import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, Renderer2, ViewChild, computed, inject, signal } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-page-game',
  standalone: true,
  imports: [],
  templateUrl: './page-game.component.html',
  styleUrl: './page-game.component.scss'
})
export class PageGameComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('loadingDiv') loadingDivElem: ElementRef<HTMLDivElement> | undefined
  @ViewChild('gameDiv') gameDivElem: ElementRef<HTMLDivElement> | undefined

  protected route = inject(ActivatedRoute)
  protected renderer = inject(Renderer2)
  protected el = inject(ElementRef)

  loading = signal(true)

  gameDivMargin = 0
  gameDivWidth = 1280
  gameDivHeight = 720

  ngOnInit(): void {
    localStorage.setItem('cocos-jwt', localStorage.getItem('btfd-jwt') || '')
  }

  ngOnDestroy(): void {
    localStorage.removeItem('cocos-jwt')
  }

  ngAfterViewInit(): void {
    // avoid angular change detection error
    setTimeout(() => {
      this.setupGameFrameSize()
      this.loadGameScripts().then(() => { this.loading.set(false) })
    }, 0)
  }

  setupGameFrameSize() {
    if (!this.loadingDivElem) {
      return
    }

    const topNavHeight = this.loadingDivElem.nativeElement.offsetTop

    // making sure follows game's aspect ratio of 16 by 9
    this.gameDivWidth = (window.innerHeight - topNavHeight) / 9 * 16
    this.gameDivHeight = window.innerHeight - topNavHeight

    // this assume screen is mobile/portrait
    if (this.gameDivWidth > this.loadingDivElem.nativeElement.offsetWidth) {
      this.gameDivWidth = this.loadingDivElem.nativeElement.offsetWidth
      this.gameDivHeight = window.innerHeight - topNavHeight
      // this.gameDivHeight = this.loadingDivElem.nativeElement.offsetWidth / 16 * 9
    }

    // to make it center
    if (this.gameDivWidth < this.loadingDivElem.nativeElement.offsetWidth) {
      this.gameDivMargin = (this.loadingDivElem.nativeElement.offsetWidth - this.gameDivWidth) / 2
    }
  }

  async loadGameScripts() {
    const gameName = this.route.snapshot.paramMap.get('gameName')

    if (!gameName) {
      return
    }

    const gameNameToPaths: { [key: string]: string[] | null } = {
      'ct': [
        `src/polyfills.bundle.5adbf.js`,
        `src/system.bundle.543e6.js`,
        `src/import-map.98b51.json`,
        `System.import('./index.400e1.js').catch(function(err) { console.error(err); })`,
      ],
      'dinosaur': [
        `src/polyfills.bundle.5adbf.js`,
        `src/system.bundle.543e6.js`,
        `src/import-map.98b51.json`,
        `System.import('./index.08a9f.js').catch(function(err) { console.error(err); })`,
      ],
    }
    const gamePaths = gameNameToPaths[gameName]

    if (!gamePaths) {
      return
    }

    for (const gamePath of gamePaths) {
      await this.appendScript(gamePath)
    }
  }

  appendScript(src: string) {
    return new Promise<void>((resolve, reject) => {
      const scriptEl: HTMLScriptElement = this.renderer.createElement('script')

      if (src.endsWith('.js') || src.endsWith('.json')) {
        scriptEl.src = src
      } else {
        scriptEl.text = src
      }

      if (src.endsWith('.json')) {
        scriptEl.type = 'systemjs-importmap'
      }

      if (!src.endsWith('.json')) {
        scriptEl.onload = () => {
          resolve()
        }
        scriptEl.onerror = () => {
          reject()
        }
      }

      this.renderer.appendChild(this.el.nativeElement, scriptEl)

      if (src.endsWith('.json') || src.startsWith('System.')) {
        resolve()
      }
    })
  }

}
