<div class="page-banner-area"
    style="background-image: url(assets-app/images/page-banner-bg.jpg);">
    <div class="container">
        <div class="page-banner-content">
            <div class="section-title">
                <span class="top-title">Home</span>
                <h2>Games</h2>
            </div>
            <img src="assets-app/images/page-star.png" class="star" alt="star">
        </div>
    </div>
    <img src="assets-app/images/banner/banner-shape-1.png" class="shape shape-1"
        alt="shape">
    <img src="assets-app/images/banner/banner-shape-2.png" class="shape shape-2"
        alt="shape">
</div>

<!-- Games -->
<div class="container">
    <div class="row">
        <div class="col-md-6 games">
            <div class="routeButton">
                <img (click)="navigateToCT()" src="assets-app/game-images/ct_crash.png" alt="btfd-crash">
                <div class="main">
                    <h3>CT Crash</h3>
                </div>
            </div>
        </div>
        <div class="col-md-6 games">
            <div class="routeButton">
                <img (click)="navigateToDinosaur()" src="assets-app/game-images/dino.png" alt="dino">
                <div class="main">
                    <h3>Dinosaur Crash</h3>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- About Us -->
<div class="container my-5 py-5">
    <div class="section-title mb-5">
        <h2>About Us</h2>
    </div>

    <div class="row">
        <div class="col-lg-6 col-md-12">
            <div class="about-image style-bottom mb-4">
                <img src="assets-app/btfd-images/about-us.jpg" alt="about-us">
            </div>
        </div>
        <div class="col-lg-6 col-md-12">
            <div class="about-content">
                <h2>BTFD WTF</h2>
                <p>Get ready for a wild ride as three unlikely amigos join forces across continents to build a no-loss capital for social games cuz losing capital is fkin haram! 
                    After years of frustration from getting rugged, these social impact superheroes, they're about to unleash a meme-worthy revolution that'll make the world go, "Wowzers, that's epic!"
                </p>
            </div>
        </div>
    </div>
</div>

<!-- Leaderboard -->
<div class="projects-details-area">
    <div class="container py-5 my-5">
        <div class="section-title mb-5">
            <h2>Leaderboard</h2>
        </div>

        <div class="projects-wrap-tabs">
            <ul class="nav-tabset">
                <li class="nav-tab" [ngClass]="{'active': true}">
                    <span>
                        Season 1
                    </span>
                </li>
            </ul>
            <div class="tabs-container">
                <div class="pane" id="tab4">
                    <div class="row align-items-center">
                        <div class="col-12">
                            <!-- Check if selfPosition is defined -->
                            <div *ngIf="selfPosition">
                                <div class="single-token-list">
                                    <ul class="d-flex justify-content-between">
                                        <li>
                                            <h4>{{selfPosition.position}}
                                                <sub>Position</sub></h4>
                                            <p>You</p>
                                        </li>
                                        <li class="text-end">
                                            <h4>{{selfPosition.totalPoint}}</h4>
                                            <p>Points</p>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <hr class="divider">
                            <div class="tabs-container">
                                <div class="pane" id="tab4">
                                    <div class="row align-items-center">
                                        <div class="col-12">
                                            @for (placement of positions; track
                                            $index) {
                                            <div class="single-token-list">
                                                <ul
                                                    class="d-flex justify-content-between">
                                                    <li>
                                                        <h4>{{placement.position}}
                                                            <sub>Position</sub></h4>
                                                        <p>{{placement.playerWallet}}</p>
                                                    </li>
                                                    <li class="text-end">
                                                        <h4>{{placement.totalPoint}}</h4>
                                                        <p>Points</p>
                                                    </li>
                                                </ul>
                                            </div>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-12 col-md-12">
                                <div class="read-more-btn"
                                    *ngIf="hasMoreItems()">
                                    <a href="javascript:void(0)"
                                        (click)="loadMore()"
                                        class="read-more">Load More</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>