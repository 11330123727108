<ng-template #modalCheckinReward let-c="close" let-d="dismiss">
    <div class="mt-3 mb-3" style="text-align: center; display: flex; justify-content: center;position: relative;">
        <h2 style="position: absolute; left: 50%; transform: translateX(-50%);">Daily Check-In</h2>
        <button class="navbar-toggler-checkin" type="button" (click)="d('Cross click')">
            <span class="burger-menu-checkin">
              <span class="top-bar"></span>
              <span class="middle-bar"></span>
              <span class="bottom-bar"></span>
            </span>
          </button>
    </div>
    <!-- Checkin -->
        <div class="team-area pt-80 pb-85 mb-4 mx-4">
            <div class="container">
                <div class="row justify-content-center">
                    <div *ngFor="let checkin of dailyCheckin; let i = index;" class="col-lg-3 col-6 col-md-4">
                        <div class="single-team">
                            <!-- <img src="assets-app/images/team/team-1.png" alt="team-image"> -->
                            <h2>DAY</h2>
                            <h1>{{ checkin.day }}</h1>
                            <span>{{ checkin.rewards[0].itemAmount }} Chips</span>
                            <br>
                            
                            <ng-container *ngIf="!checkin.isClaimed; else rewardClaimed;">
                                <ng-container *ngIf="checkin.isDateToday; else rewardBlockClick">
                                <a class="default-btn" style="width: 120px;" (click)="claimReward(i)">Claim</a>
                                </ng-container>
                            </ng-container>

                            <ng-template  #rewardClaimed>
                                <div class="reward-claimed">
                                    <a class="gradient-span" style="width: 120px;">Claimed</a>
                                </div>
                            </ng-template>

                            <ng-template  #rewardBlockClick>
                                <div class="reward-coming">
                                    <a class="coming" style="width: 120px;">Coming Soon</a>
                                </div>
                            </ng-template>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <ngx-loading
            [show]="loading"
            [config]="{ backdropBorderRadius: '3px' }"
        ></ngx-loading>
</ng-template>

