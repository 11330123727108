<ngx-loading
    [show]="loading"
    [config]="{ backdropBorderRadius: '3px' }"
    [template]="loadingTemplate"
></ngx-loading>

<ng-template #successLoadingTemplate>
    <div class="custom-class" style="text-align: center;">
        <h1>Successfully Purchased</h1>
        <br>
        <a class="default-btn" style="width: 120px;" (click)="onClickOK()">OK</a>
    </div>
</ng-template>


<ng-template #failLoadingTemplate>
    <div class="custom-class" style="text-align: center;">
        <h1>Something went wrong,</h1>
        <h1>please try again!</h1>
        <br>
        <a class="default-btn" style="width: 120px;" (click)="onClickOK()">OK</a>
    </div>
</ng-template>

<ng-template #rejectLoadingTemplate>
    <div class="custom-class" style="text-align: center;">
        <h1>You have rejected the transaction</h1>
        <br>
        <a class="default-btn" style="width: 120px;" (click)="onClickOK()">OK</a>
    </div>
</ng-template>

<!-- Page Title -->
<div class="page-banner-area" style="background-image: url(assets-app/images/page-banner-bg.jpg);">
    <div class="container">
        <div class="page-banner-content">
            <ul>
                <li>
                    <a routerLink="/">
                        HOME
                    </a>
                </li>
                <li>
                    <span>SHOP</span>
                </li>
            </ul>
            <h2>Shop</h2>
            <img src="assets-app/images/page-star.png" class="star" alt="star">
        </div>
    </div>
    <img src="assets-app/images/banner/banner-shape-1.png" class="shape shape-1" alt="shape">
    <img src="assets-app/images/banner/banner-shape-2.png" class="shape shape-2" alt="shape">
</div>

<!-- Shop -->
<div class="team-area pt-120 pb-90">
    <div class="container">
        <div class="row justify-content-center">

            <div *ngFor="let shopItem of shopItemList; let i = index;" class="col-lg-3 col-6 col-md-4">
                <div class="single-team" [style.cursor]="'pointer'">
                    <img src="assets-app/btfd-images/chip.jpeg" alt="chip-image">
                    <h3>{{ shopItem.amount }} Chips</h3>
                    <span>{{ shopItem.priceEth }} ETH</span>
                    <br>
                    <a class="default-btn" style="cursor: pointer;" (click)="onClickPurchase(shopItem.selectionNo, shopItem.priceEth)">Purchase</a>

                </div>
            </div>
        </div>
    </div>
</div>





