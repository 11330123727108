import { Component } from '@angular/core';

@Component({
  selector: 'app-common-footer',
  standalone: true,
  imports: [],
  templateUrl: './common-footer.component.html',
  styleUrl: './common-footer.component.scss'
})
export class CommonFooterComponent {

}
