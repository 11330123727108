<app-multipage-navbar></app-multipage-navbar>

<!-- Page Title -->
<div class="page-banner-area" style="background-image: url(assets-app/images/page-banner-bg.jpg);">
    <div class="container">
        <div class="page-banner-content">
            <!-- <ul>
                <li>
                    <a routerLink="/">
                        HOME
                    </a>
                </li>
                <li>
                    <span>TEAM</span>
                </li>
            </ul> -->
            <h2>Check-In Rewards</h2>
            <img src="assets-app/images/page-star.png" class="star" alt="star">
        </div>
    </div>
    <img src="assets-app/images/banner/banner-shape-1.png" class="shape shape-1" alt="shape">
    <img src="assets-app/images/banner/banner-shape-2.png" class="shape shape-2" alt="shape">
</div>

<!-- Checkin -->
<div class="team-area pt-120 pb-90">
    <div class="container">
        <div class="section-title">
            <span class="top-title">SMALL text</span>
            <h2>BIG text</h2>
        </div>
        <div class="row justify-content-center">

            <div *ngFor="let checkin of dailyCheckin; let i = index;" class="col-lg-3 col-6 col-md-4">
                <div class="single-team">
                    <img src="assets-app/images/team/team-1.png" alt="team-image">
                    <h3>Day {{ checkin.day }}</h3>
                    <span>{{ checkin.rewards[0].itemAmount }} Chips</span>
                    <!-- <a class="default-btn btn-active">Claim</a> -->
                    <!-- <ng-container *ngIf="!checkin.isClaimed;">
                        <a class="default-btn" style="cursor: pointer;">Claim</a>
                        <br>
                        <a>CLAIMED BOI</a>
                    </ng-container> -->

                    <ng-template  #rewardClaimed>
                        <div class="reward-claimed">
                            <div class="ellipsis"></div>
                            <span class="txt-line">Claimed</span>
                        </div>
                    </ng-template>

                    <ng-container *ngIf="!checkin.isClaimed; else rewardClaimed;">
                        <ng-container *ngIf="checkin.isDateToday; else rewardBlockClick">
                            <div class="energy"
                                [style.cursor]="'pointer'"
                               
                            >
                            <a class="default-btn" style="cursor: pointer;" (click)="claimReward(i)">Claim</a>
                            </div>
                        </ng-container>
                    </ng-container>

                    <ng-template  #rewardBlockClick>
                        <span class="txt-line">Coming Soon</span>
                    </ng-template>

                    <!-- <ng-template  #rewardClaimed>
                        <div class="reward-claimed">
                            <div class="ellipsis"></div>
                            <img [src]="'/assets-app/icon-success.svg'">
                        </div>
                    </ng-template> -->
                </div>
            </div>
        </div>
    </div>
</div>