<div class="banner-area" style="background-image: url(assets-app/images/banner/banner-bg-5.png);">
    <div class="container-fluid">
        <div class="row align-items-center">
            <div class="col-lg-7 col-md-12">
                <div class="banner-content">
                    <span class="top-title">Buy the best places now On Metaverse!</span>
                    <h1>We Are Blockchain Based Virtual 3d Land Selling Platform</h1>
                    <p>The Metaverse Is The Next Generation Of The Internet. As One Of Its Earliest Explorers, You Will Help Fuel Its Expansion And Share In The Benefits Of This Growth.</p>
                    <a routerLink="/project-details" class="default-btn btn-active letter-spacing">BUY LAND IMMEDIATELY</a>
                </div>
            </div>
            <div class="col-lg-5 col-md-12">
                <div class="banner-image">
                    <img src="assets-app/images/banner/banner-img-5.png" alt="banner-image">
                </div>
            </div>
        </div>
    </div>
    <img src="assets-app/images/banner/banner-shape-11.png" class="shape shape-1" alt="shape">
    <img src="assets-app/images/banner/banner-shape-12.png" class="shape shape-2" alt="shape">
    <img src="assets-app/images/banner/earth.gif" class="shape earth" alt="shape">
</div>