<app-multipage-navbar></app-multipage-navbar>

<app-land-selling-platform-banner></app-land-selling-platform-banner>

<app-statistics></app-statistics>

<app-video-presentation></app-video-presentation>

<app-key-features></app-key-features>

<hr>

<app-about-us></app-about-us>

<app-partner></app-partner>

<app-news-articles></app-news-articles>

<div class="pb-120">
    <app-earn-rewards></app-earn-rewards>
</div>