<div id="roadmap" class="roadmap-area pt-120">
    <div class="container-fluid">
        <div class="section-title">
            <span class="top-title">ROADMAP</span>
            <h2>See Whats Waiting For You</h2>
        </div>
        <div class="roadmap-slides">
            <owl-carousel-o [options]="roadmapSlides">
                <ng-template carouselSlide>
                    <div class="single-roadmap">
                        <div class="date">
                            <span>2024</span>
                            <h2>Q1</h2>
                        </div>
                        <ul>
                            <li>Introduce the collection of NFT for rare artworks and conduct auctions</li>
                            <li>Launch the Staking NFT system</li>
                            <li>Introduce the system supporting artworks, establish standard storage and preservation centers for artworks</li>
                            <li>Build Mave NFT Marketplace</li>
                        </ul>
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="single-roadmap">
                        <div class="date">
                            <span>2024</span>
                            <h2>Q1</h2>
                        </div>
                        <ul>
                            <li>Launch Seed and Private Sale(Completed) </li>
                            <li>Research Binance Smart Chain, NFT and AvatarArt</li>
                        </ul>
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="single-roadmap">
                        <div class="date">
                            <span>2024</span>
                            <h2>Q3</h2>
                        </div>
                        <ul>
                            <li>Launch Strategic Round</li>
                            <li>Launch Public Sale round under IDO and be listed on exchanges right after IDO</li>
                            <li>Launch program on staking BNU to earn NFT representing placements in 3D virtual reality space</li>
                            <li>Launch the NFT exchange</li>
                        </ul>
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="single-roadmap">
                        <div class="date">
                            <span>2024</span>
                            <h2>Q4</h2>
                        </div>
                        <ul>
                            <li>Demo 3D virtual exhibition</li>
                            <li>Announce the extended ecosystems ByteNext Labs Build ByteNext DEX</li>
                            <li>Build Mave Launchpad</li>
                        </ul>
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="single-roadmap">
                        <div class="date date-active">
                            <span>2024</span>
                            <h2>Q1</h2>
                        </div>
                        <ul>
                            <li>Develop Yield Guild</li>
                            <li>Continuously develop and promote Mave Launchpad</li>
                            <li>Widen AvatarArt’s network</li>
                            <li>Introduce the system supporting artworks, establish standard storage and preservation centers for artworks</li>
                        </ul>
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="single-roadmap">
                        <div class="date date-active">
                            <span>2024</span>
                            <h2>Q2</h2>
                        </div>
                        <ul>
                            <li>Develop Yield Guild</li>
                            <li>Complete Mave Labs Ecosystem</li>
                            <li>Develop global strategy</li>
                        </ul>
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="single-roadmap">
                        <div class="date">
                            <span>2024</span>
                            <h2>Q1</h2>
                        </div>
                        <ul>
                            <li>Launch Seed and Private Sale(Completed) </li>
                            <li>Research Binance Smart Chain, NFT and AvatarArt</li>
                        </ul>
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="single-roadmap">
                        <div class="date">
                            <span>2024</span>
                            <h2>Q1</h2>
                        </div>
                        <ul>
                            <li>Launch Strategic Round</li>
                            <li>Launch Public Sale round under IDO and be listed on exchanges right after IDO</li>
                            <li>Launch program on staking BNU to earn NFT representing placements in 3D virtual reality space</li>
                            <li>Launch the NFT exchange</li>
                        </ul>
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="single-roadmap">
                        <div class="date">
                            <span>2024</span>
                            <h2>Q3</h2>
                        </div>
                        <ul>
                            <li>Introduce the collection of NFT for rare artworks and conduct auctions</li>
                            <li>Launch the Staking NFT system</li>
                            <li>Introduce the system supporting artworks, establish standard storage and preservation centers for artworks</li>
                            <li>Build Mave NFT Marketplace</li>
                        </ul>
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="single-roadmap">
                        <div class="date">
                            <span>2024</span>
                            <h2>Q4</h2>
                        </div>
                        <ul>
                            <li>Demo 3D virtual exhibition</li>
                            <li>Announce the extended ecosystems ByteNext Labs Build ByteNext DEX</li>
                            <li>Build Mave Launchpad</li>
                        </ul>
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="single-roadmap">
                        <div class="date date-active">
                            <span>2024</span>
                            <h2>Q1</h2>
                        </div>
                        <ul>
                            <li>Develop Yield Guild</li>
                            <li>Continuously develop and promote Mave Launchpad</li>
                            <li>Widen AvatarArt’s network</li>
                            <li>Introduce the system supporting artworks, establish standard storage and preservation centers for artworks</li>
                        </ul>
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="single-roadmap">
                        <div class="date date-active">
                            <span>2024</span>
                            <h2>Q2</h2>
                        </div>
                        <ul>
                            <li>Develop Yield Guild</li>
                            <li>Complete Mave Labs Ecosystem</li>
                            <li>Develop global strategy</li>
                        </ul>
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="single-roadmap">
                        <div class="date">
                            <span>2024</span>
                            <h2>Q1</h2>
                        </div>
                        <ul>
                            <li>Launch Seed and Private Sale(Completed) </li>
                            <li>Research Binance Smart Chain, NFT and AvatarArt</li>
                        </ul>
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="single-roadmap">
                        <div class="date">
                            <span>2024</span>
                            <h2>Q1</h2>
                        </div>
                        <ul>
                            <li>Launch Strategic Round</li>
                            <li>Launch Public Sale round under IDO and be listed on exchanges right after IDO</li>
                            <li>Launch program on staking BNU to earn NFT representing placements in 3D virtual reality space</li>
                            <li>Launch the NFT exchange</li>
                        </ul>
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="single-roadmap">
                        <div class="date">
                            <span>2024</span>
                            <h2>Q3</h2>
                        </div>
                        <ul>
                            <li>Introduce the collection of NFT for rare artworks and conduct auctions</li>
                            <li>Launch the Staking NFT system</li>
                            <li>Introduce the system supporting artworks, establish standard storage and preservation centers for artworks</li>
                            <li>Build Mave NFT Marketplace</li>
                        </ul>
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="single-roadmap">
                        <div class="date">
                            <span>2024</span>
                            <h2>Q4</h2>
                        </div>
                        <ul>
                            <li>Demo 3D virtual exhibition</li>
                            <li>Announce the extended ecosystems ByteNext Labs Build ByteNext DEX</li>
                            <li>Build Mave Launchpad</li>
                        </ul>
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="single-roadmap">
                        <div class="date date-active">
                            <span>2024</span>
                            <h2>Q1</h2>
                        </div>
                        <ul>
                            <li>Develop Yield Guild</li>
                            <li>Continuously develop and promote Mave Launchpad</li>
                            <li>Widen AvatarArt’s network</li>
                            <li>Introduce the system supporting artworks, establish standard storage and preservation centers for artworks</li>
                        </ul>
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="single-roadmap">
                        <div class="date date-active">
                            <span>2024</span>
                            <h2>Q2</h2>
                        </div>
                        <ul>
                            <li>Develop Yield Guild</li>
                            <li>Complete Mave Labs Ecosystem</li>
                            <li>Develop global strategy</li>
                        </ul>
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="single-roadmap">
                        <div class="date">
                            <span>2024</span>
                            <h2>Q1</h2>
                        </div>
                        <ul>
                            <li>Launch Seed and Private Sale(Completed) </li>
                            <li>Research Binance Smart Chain, NFT and AvatarArt</li>
                        </ul>
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="single-roadmap">
                        <div class="date">
                            <span>2024</span>
                            <h2>Q1</h2>
                        </div>
                        <ul>
                            <li>Launch Strategic Round</li>
                            <li>Launch Public Sale round under IDO and be listed on exchanges right after IDO</li>
                            <li>Launch program on staking BNU to earn NFT representing placements in 3D virtual reality space</li>
                            <li>Launch the NFT exchange</li>
                        </ul>
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="single-roadmap">
                        <div class="date">
                            <span>2024</span>
                            <h2>Q3</h2>
                        </div>
                        <ul>
                            <li>Introduce the collection of NFT for rare artworks and conduct auctions</li>
                            <li>Launch the Staking NFT system</li>
                            <li>Introduce the system supporting artworks, establish standard storage and preservation centers for artworks</li>
                            <li>Build Mave NFT Marketplace</li>
                        </ul>
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="single-roadmap">
                        <div class="date">
                            <span>2024</span>
                            <h2>Q4</h2>
                        </div>
                        <ul>
                            <li>Demo 3D virtual exhibition</li>
                            <li>Announce the extended ecosystems ByteNext Labs Build ByteNext DEX</li>
                            <li>Build Mave Launchpad</li>
                        </ul>
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="single-roadmap">
                        <div class="date date-active">
                            <span>2024</span>
                            <h2>Q1</h2>
                        </div>
                        <ul>
                            <li>Develop Yield Guild</li>
                            <li>Continuously develop and promote Mave Launchpad</li>
                            <li>Widen AvatarArt’s network</li>
                            <li>Introduce the system supporting artworks, establish standard storage and preservation centers for artworks</li>
                        </ul>
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="single-roadmap">
                        <div class="date date-active">
                            <span>2024</span>
                            <h2>Q2</h2>
                        </div>
                        <ul>
                            <li>Develop Yield Guild</li>
                            <li>Complete Mave Labs Ecosystem</li>
                            <li>Develop global strategy</li>
                        </ul>
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="single-roadmap">
                        <div class="date">
                            <span>2024</span>
                            <h2>Q1</h2>
                        </div>
                        <ul>
                            <li>Launch Seed and Private Sale(Completed) </li>
                            <li>Research Binance Smart Chain, NFT and AvatarArt</li>
                        </ul>
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="single-roadmap">
                        <div class="date">
                            <span>2024</span>
                            <h2>Q1</h2>
                        </div>
                        <ul>
                            <li>Launch Strategic Round</li>
                            <li>Launch Public Sale round under IDO and be listed on exchanges right after IDO</li>
                            <li>Launch program on staking BNU to earn NFT representing placements in 3D virtual reality space</li>
                            <li>Launch the NFT exchange</li>
                        </ul>
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="single-roadmap">
                        <div class="date">
                            <span>2024</span>
                            <h2>Q3</h2>
                        </div>
                        <ul>
                            <li>Introduce the collection of NFT for rare artworks and conduct auctions</li>
                            <li>Launch the Staking NFT system</li>
                            <li>Introduce the system supporting artworks, establish standard storage and preservation centers for artworks</li>
                            <li>Build Mave NFT Marketplace</li>
                        </ul>
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="single-roadmap">
                        <div class="date">
                            <span>2024</span>
                            <h2>Q4</h2>
                        </div>
                        <ul>
                            <li>Demo 3D virtual exhibition</li>
                            <li>Announce the extended ecosystems ByteNext Labs Build ByteNext DEX</li>
                            <li>Build Mave Launchpad</li>
                        </ul>
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="single-roadmap">
                        <div class="date date-active">
                            <span>2024</span>
                            <h2>Q1</h2>
                        </div>
                        <ul>
                            <li>Develop Yield Guild</li>
                            <li>Continuously develop and promote Mave Launchpad</li>
                            <li>Widen AvatarArt’s network</li>
                            <li>Introduce the system supporting artworks, establish standard storage and preservation centers for artworks</li>
                        </ul>
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="single-roadmap">
                        <div class="date date-active">
                            <span>2024</span>
                            <h2>Q2</h2>
                        </div>
                        <ul>
                            <li>Develop Yield Guild</li>
                            <li>Complete Mave Labs Ecosystem</li>
                            <li>Develop global strategy</li>
                        </ul>
                    </div>
                </ng-template>
            </owl-carousel-o>
        </div>
    </div>
    <img src="assets-app/images/features-shape-1.png" class="shape shape-1" alt="shape">
    <img src="assets-app/images/features-shape-2.png" class="shape shape-2" alt="shape">
</div>