<app-onepage-navbar></app-onepage-navbar>

<app-dex-offering-banner></app-dex-offering-banner>

<app-partner></app-partner>

<app-about-us></app-about-us>

<app-key-features></app-key-features>

<app-how-it-works></app-how-it-works>

<app-token-info></app-token-info>

<app-roadmap></app-roadmap>

<app-what-will-get></app-what-will-get>

<app-earn-rewards></app-earn-rewards>

<app-projects></app-projects>