<app-multipage-navbar></app-multipage-navbar>

<app-metavarse-launchpad-banner></app-metavarse-launchpad-banner>

<app-key-features></app-key-features>

<app-how-it-works></app-how-it-works>

<app-partner></app-partner>

<app-what-will-get></app-what-will-get>

<app-about-us></app-about-us>

<app-earn-rewards></app-earn-rewards>

<app-projects></app-projects>