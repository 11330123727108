<app-multipage-navbar></app-multipage-navbar>

<!-- Page Title -->
<div class="page-banner-area" style="background-image: url(assets-app/images/page-banner-bg.jpg);">
    <div class="container">
        <div class="page-banner-content">
            <ul>
                <li>
                    <a routerLink="/">
                        HOME
                    </a>
                </li>
                <li>
                    <span>CONTACT US</span>
                </li>
            </ul>
            <h2>Contact Us</h2>
            <img src="assets-app/images/page-star.png" class="star" alt="star">
        </div>
    </div>
    <img src="assets-app/images/banner/banner-shape-1.png" class="shape shape-1" alt="shape">
    <img src="assets-app/images/banner/banner-shape-2.png" class="shape shape-2" alt="shape">
</div>

<!-- Contact Info -->
<div class="contact-info-area pt-120">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-3 col-md-12">
                <div class="info-content">
                    <h3>Contacts Info</h3>
                    <ul>
                        <li>
                            <span>Address:</span>
                            312 Clinton Circle Atlantic City, M2 080522
                        </li>
                        <li>
                            <span>Phone:</span>
                            <a href="tel:+1(135)-1984-2020">+1(135) 1984 2020</a>
                        </li>
                        <li>
                            <span>Email:</span>
                            <a href="mailto:hello&#64;mave.com">hello&#64;mave.com</a>
                        </li>
                        <li>
                            <span>Time:</span>
                            Every day 24 hours 
                        </li>
                    </ul>
                </div>
            </div>
            <div class="col-lg-9 col-md-12">
                <div class="maps">
                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d49345.57033904692!2d-74.52458123690823!3d39.37665521359071!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c0dd576e5cc721%3A0x4a6fcb43e9675262!2sAtlantic%20City%2C%20NJ%2C%20USA!5e0!3m2!1sen!2sbd!4v1647240741116!5m2!1sen!2sbd"></iframe>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- Contact -->
<div class="contact-area ptb-120">
    <div class="container">
        <div class="section-title">
            <span class="top-title">CONTACT US</span>
            <h2>Get in Touch</h2>
        </div>
        <div class="contact-form">
            <form>
                <div class="row">
                    <div class="col-lg-6 col-md-6">
                        <div class="form-group">
                            <label>Full Name</label>
                            <input type="text" name="name" id="name" class="form-control" required placeholder="Name">
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6">
                        <div class="form-group">
                            <label>Email</label>
                            <input type="email" name="email" id="email" class="form-control" required placeholder="mave@gmail.com">
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6">
                        <div class="form-group">
                            <label>Phone</label>
                            <input type="text" name="phone_number" id="phone_number" required class="form-control" placeholder="+1(135) 1984 2020">
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6">
                        <div class="form-group">
                            <label>Subject</label>
                            <input type="text" name="msg_subject" id="msg_subject" class="form-control" required placeholder="Subject ">
                        </div>
                    </div>
                    <div class="col-lg-12 col-md-12">
                        <div class="form-group">
                            <label>Your Message</label>
                            <textarea name="message" class="form-control" id="message" cols="30" rows="6" required placeholder="Write your message"></textarea>
                        </div>
                    </div>
                    <div class="col-lg-12 col-md-12">
                        <div class="form-check">
                            <div class="form-group">
                                <div class="form-check">
                                    <input
                                        name="gridCheck"
                                        value="I agree to the terms and privacy policy."
                                        class="form-check-input"
                                        type="checkbox"
                                        id="gridCheck"
                                        required
                                    >
                                    <label class="form-check-label" for="gridCheck">
                                        I agree to the <a routerLink="/terms-conditions">Terms & Conditions</a> and <a routerLink="/privacy-policy">Privacy Policy</a>
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-12 col-md-12">
                        <button type="submit" class="default-btn">Send Message</button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>